import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React from 'react';

export const ConditionIcon = () => (
  <Icon
    icon={ICONS.Condition_Arrow}
    size={12}
    color={COLORS.BLACK}
    style={{ marginLeft: '2px' }}
    title="Conditional Logic"
  />
);
