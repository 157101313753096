import React from 'react';
import Typography from '@mui/material/Typography';
import { ChecklistItemSubheader } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';

const StyledSubHeader = styled.div<{ $isMozart: boolean }>`
  padding: ${(props) =>
    props.$isMozart ? ' 12px 64px 12px 68px' : '12px 34px 12px 34px'};
  justify-content: flex-start;
  background-color: ${COLORS.GREY10};
  margin: ${(props) => (props.$isMozart ? '0 -28px' : '0')};
`;

export interface IChecklistItemSubHeaderProps {
  item: ChecklistItemSubheader;
  storageType: ChecklistStorageType;
}

const SubHeader = ({ item, storageType }: IChecklistItemSubHeaderProps) => {
  return (
    <StyledSubHeader
      $isMozart={
        storageType === ChecklistStorageType.MOZART ||
        storageType === ChecklistStorageType.VIEW
      }
    >
      <Typography variant="subtitle1">{item.preview}</Typography>
    </StyledSubHeader>
  );
};

export default SubHeader;
