import React from 'react';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { StatusBadge } from 'components/badge';

export const CarePlanLabelCell = (props: ILabelCellProps) => {
  const { column } = props;
  if (column.name === 'isActive') {
    return (
      <Table.Cell {...props} style={CellComponentStyle}>
        <StatusBadge active={props.row.isActive} />
      </Table.Cell>
    );
  }
  return <Table.Cell {...props} style={CellComponentStyle} />;
};
