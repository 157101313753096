import React, { useEffect } from 'react';
import { useLazyGeneratePdfQuery } from 'graphql/hooks/generatePdf';
import { openBase64File } from 'util/helpers/fileHelpers';
import Loader from 'components/loader';
import StyledLink from './StyledLink';
import { IChecklistLinkProps } from './types';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IChecklistPrintLinkProps extends IChecklistLinkProps {
  label?: string;
}

const ChecklistPrintLink = (props: IChecklistPrintLinkProps) => {
  const [
    printChecklist,
    {
      data: printChecklistResult,
      isSuccess: isSuccessPrintChecklist,
      isFetching: isPrintingChecklist,
      requestId: printChecklistReqId,
    },
  ] = useLazyGeneratePdfQuery();

  useEffect(() => {
    if (isSuccessPrintChecklist) {
      openBase64File(
        printChecklistResult?.generatePdf?.content ?? '',
        printChecklistResult?.generatePdf?.mimeType ?? '',
        printChecklistResult?.generatePdf?.fileName ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessPrintChecklist, printChecklistReqId]);

  return (
    <>
      <CustomTooltip
        title={props.title ?? 'Generates a PDF version of the document'}
      >
        <StyledLink
          onClick={() =>
            printChecklist({
              checklistId: Number(props.checklistId),
              schemaId: Number(0),
            })
          }
          target={'blank'}
          data-cy="Print"
        >
          Print
        </StyledLink>
      </CustomTooltip>
      <Loader active={isPrintingChecklist} />
    </>
  );
};

export default ChecklistPrintLink;
