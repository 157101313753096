import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetUserPreferencesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GetUserPreferencesQuery = {
  __typename?: 'EntitiesQuery';
  getUserPreferences?: {
    __typename?: 'User';
    login?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    createdOn?: any | null;
    prefix?: string | null;
    suffix?: string | null;
    credentials?: string | null;
    phone?: string | null;
    email?: string | null;
    defaultDashboard?: string | null;
    standardAuthorization?: string | null;
    expeditedAuthorization?: string | null;
  } | null;
};

export const GetUserPreferencesDocument = `
    query getUserPreferences {
  getUserPreferences {
    login
    firstName
    lastName
    createdOn
    prefix
    suffix
    credentials
    phone
    email
    defaultDashboard
    standardAuthorization
    expeditedAuthorization
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserPreferences: build.query<
      GetUserPreferencesQuery,
      GetUserPreferencesQueryVariables | void
    >({
      query: (variables) => ({
        document: GetUserPreferencesDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetUserPreferencesQuery, useLazyGetUserPreferencesQuery } =
  injectedRtkApi;
