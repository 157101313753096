import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import React, { ReactNode } from 'react';

const MozartActionWrapper = styled.div<{
  $isDoneStep: boolean;
  $borderColor?: COLORS;
}>`
  display: flex;
  align-items: flex-start;
  padding: 28px 32px;
  background-color: ${COLORS.WHITE};
  margin-bottom: 16px;
  border: 1px solid ${COLORS.GREY25};
  border-radius: 4px;
  flex-direction: column;
  border-top: ${(props) =>
    props.$isDoneStep
      ? ` 4px solid ${props.$borderColor ?? COLORS.GREEN100}`
      : `1px solid ${COLORS.GREY25}`};
`;

export interface IMozartActionContainerProps {
  children: ReactNode;
  isDoneStep?: boolean;
  borderColor?: COLORS;
}

export const MozartActionContainer = ({
  children,
  isDoneStep,
  borderColor,
}: IMozartActionContainerProps) => {
  return (
    <MozartActionWrapper
      $isDoneStep={Boolean(isDoneStep)}
      $borderColor={borderColor}
    >
      {children}
    </MozartActionWrapper>
  );
};
