import { IMedicationsTab } from 'store/patientdetails/types';
import React from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import Popper from 'components/Popper';
import moment from 'moment';
import { useGetMedicationReviewHistoryViewModelQuery } from 'graphql/hooks/getMedicationReviewHistoryViewModel';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export const MedicationReview = (props: {
  medicationsData: IMedicationsTab;
  updateMedicationsInfo: <T>(value: T) => void;
}) => {
  const { updateMedicationsInfo, medicationsData } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const currentUser = useSelector((state: IState) => state.user.currentUser);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const { data: medsReviewHistory } =
    useGetMedicationReviewHistoryViewModelQuery(
      { patientId },
      { skip: !patientId }
    );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <Grid item xs={8}>
      <Box pb="20px">
        <Typography variant="subtitle1">Medications</Typography>
      </Box>
      <Box pb="20px">
        <FormControlLabel
          control={
            <Checkbox
              checked={medicationsData.medicationsCheck}
              onChange={() =>
                updateMedicationsInfo({
                  medicationsCheck: !medicationsData.medicationsCheck,
                })
              }
              name="allergiesCheck"
              color="primary"
            />
          }
          label="Denies active medications"
        />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography style={{ paddingRight: '4px' }}>Last Reviewed:</Typography>
        {medicationsData.lastReviewed?.time ? (
          <Box display="flex" alignItems="center">
            <Typography>{`${medicationsData.lastReviewed.user}, on`}</Typography>
            <Typography variant="body2" style={{ paddingLeft: '2px' }}>
              {formatDate(medicationsData.lastReviewed.time)}
            </Typography>
            <CustomTooltip title="More info">
              <IconButton
                style={{
                  padding: '4px',
                  marginLeft: '4px',
                }}
                onClick={handleClick}
                size="large"
              >
                <Icon
                  icon={ICONS.Info}
                  size={16}
                  color={COLORS.GREY100}
                  hoverColor={COLORS.SYMPHONY_BLUE}
                />
              </IconButton>
            </CustomTooltip>

            <Popper anchor={anchorEl} handleClick={handleClick}>
              <Box padding="6px 50px 14px 20px">
                <Typography variant="body2" style={{ paddingBottom: '4px' }}>
                  Review History
                </Typography>
                {medsReviewHistory?.getMedicationReviewHistoryViewModel.map(
                  (item, index) => (
                    <Box display="flex" alignItems="center" key={index}>
                      <Typography variant="body2">
                        {formatDate(item?.reviewedOn)}
                      </Typography>
                      <Typography>
                        &nbsp;by {item?.reviewedByName}/
                        {item?.reviewedByRoleName}
                      </Typography>
                    </Box>
                  )
                )}
              </Box>
            </Popper>
          </Box>
        ) : (
          <Typography>Never</Typography>
        )}
      </Box>
      <Box pt="28px">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() =>
            updateMedicationsInfo({
              lastReviewed: {
                time: moment().toDate(),
                user: currentUser.name,
                isNew: true,
              },
            })
          }
          startIcon={
            <Icon
              icon={ICONS.Checkmark}
              size={14}
              color={COLORS.SYMPHONY_BLUE}
            />
          }
        >
          Complete Review
        </Button>
      </Box>
    </Grid>
  );
};
