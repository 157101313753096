import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PatientDetailsTabsBody from './DialogBody';
import { IState } from 'store';
import { ITab } from 'store/patientdetails/types';
import AntTab from 'components/tabs/AntTab';
import TabContext from '@mui/lab/TabContext';
import {
  refreshTimelineGridData,
  removeJustAddedReminder,
  setActiveEpisode,
  setActiveTab,
} from 'store/patientdetails/patientDetailsSlice';
import { useGetPatientDetailsTabsQuery } from 'graphql/hooks/getPatientDetailsTabs';
import { Tabs } from '@mui/material';
import styled from 'styled-components';
import { EntityListTabDefinition } from 'graphql/graphqlTypes';
import { getSplitTabs, ISortedTabs } from 'components/home/Tabs';
import DropdownTab from 'components/home/DropdownTab';
import { isNumber } from 'lodash';
import { useWidth } from 'hooks/useWidth';
import { IPatientForm } from 'components/home/patientdetails/tabscontent/backgroundTab/types';
import Loader from 'components/loader';

interface IPatientDetailsTabs {
  handleUpdatePatientTabValid: (valid: boolean) => void;
  patientDetails: IPatientForm | null;
}

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

const phase2Tabs = ({ name }: ITab) => !['Care Plan'].includes(name);

const renameTabs = (tab: ITab) =>
  tab.name === 'History' ? { ...tab, name: 'Cases' } : tab;

const PatientDetailsTabs = (props: IPatientDetailsTabs) => {
  const { handleUpdatePatientTabValid, patientDetails } = props;

  const {
    data: tabModelData,
    isFetching: isFetchingTabModel,
    isLoading: isLoadingTabModel,
    refetch: refetchTabsModel,
  } = useGetPatientDetailsTabsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const role = useSelector((state: IState) => state.user.currentUser.role);
  const dirtyTabs = useSelector(
    (state: IState) => state.patientDetails.dirtyTabs
  );
  const [tabs, setTabs] = useState<ITab[]>([]);
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const episodeId = useSelector(
    (state: IState) => state.patientDetails.episodeId
  );
  const editingChecklistId = useSelector(
    (state: IState) => state.patientDetails.editingChecklistId
  );
  const activeTabName = useSelector(
    (state: IState) => state.patientDetails.activeTabName ?? 'Background'
  );

  const tabsRef = useRef<HTMLDivElement>(null);

  const { width, screenSize } = useWidth(tabsRef.current);

  const maxTabCount = width && Math.round(width / 130);

  const dispatch = useDispatch();

  useEffect(() => {
    if (tabModelData) {
      const modelTabs = (tabModelData.getPatientDetailsTabs as ITab[])
        .filter(phase2Tabs)
        .map(renameTabs);
      setTabs(modelTabs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabModelData]);

  useEffect(() => {
    refetchTabsModel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const handleTabChange = (newValue: string | number) => {
    const updatedActiveTab = isNumber(newValue)
      ? tabs?.find((tab) => tab.index === newValue)?.name
      : newValue;
    dispatch(setActiveTab({ tab: updatedActiveTab ?? '' }));
    dispatch(setActiveEpisode(0));
    dispatch(removeJustAddedReminder());
    dispatch(refreshTimelineGridData(false));
  };

  const sortedTabs: ISortedTabs = getSplitTabs(
    tabs as EntityListTabDefinition[],
    maxTabCount ?? 0,
    screenSize
  );

  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  return (
    <StyledWrapper ref={tabsRef}>
      {sortedTabs.visibleTabs && sortedTabs.visibleTabs.length > 0 ? (
        <>
          <TabContext value={activeTabName}>
            <Tabs
              value={activeTabName}
              onChange={(_, value) => handleTabChange(value)}
              aria-label="main grid"
            >
              {sortedTabs.visibleTabs.map((item, index) => (
                <AntTab
                  key={item.index}
                  label={item.name ?? ''}
                  value={item.name ?? ''}
                  isDisabled={isInEditMode && activeTabName !== item.name}
                  isLast={
                    sortedTabs.dropdownTabs.length === 0 &&
                    sortedTabs.visibleTabs.length === index + 1
                  }
                  isFirst={index === 0}
                  isDirty={dirtyTabs.includes(item?.name ?? '')}
                />
              ))}
              {sortedTabs.dropdownTabs.length > 0 ? (
                <DropdownTab
                  tabs={sortedTabs.dropdownTabs}
                  activeTab={
                    tabs?.find((tab) => tab.name === activeTabName)?.index ?? -1
                  }
                  handleTabChange={handleTabChange}
                />
              ) : null}
            </Tabs>
            <PatientDetailsTabsBody
              patientId={patientId}
              episodeId={episodeId}
              editingChecklistId={editingChecklistId}
              handleUpdatePatientTabValid={handleUpdatePatientTabValid}
              tabs={tabs}
              patientDetails={patientDetails}
            />
          </TabContext>
          <Loader active={isFetchingTabModel || isLoadingTabModel} />
        </>
      ) : null}
    </StyledWrapper>
  );
};

PatientDetailsTabs.displayName = 'PatientDetailsTabs';

export default PatientDetailsTabs;
