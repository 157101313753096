import {
  IColumn,
  IDataResult,
  IFilterValue,
  IHomeModel,
  ITabModel,
} from './types';
import {
  Filter,
  Sorting,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { ISystemValue } from 'backend/types/systemValue';
import { ILookupValue } from 'backend/types/lookupValue';
import { ILookup } from 'backend/types/lookup';
import { User } from 'graphql/graphqlTypes';
import { IAdvancedSearchCriteria } from 'backend/types/advancedSearchCriteria';

export const HOME_MODEL_SUCCESS = 'HOME_MODEL_SUCCESS';
export type HOME_MODEL_SUCCESS = typeof HOME_MODEL_SUCCESS;

export const HOME_SET_ACTIVE_TAB = 'HOME_SET_ACTIVE_TAB';
export type HOME_SET_ACTIVE_TAB = typeof HOME_SET_ACTIVE_TAB;

export const CLEAR_HOME_MODEL = 'CLEAR_HOME_MODEL';
export type CLEAR_HOME_MODEL = typeof CLEAR_HOME_MODEL;

export const HOME_TAB_MODEL_SUCCESS = 'HOME_TAB_MODEL_SUCCESS';
export type HOME_TAB_MODEL_SUCCESS = typeof HOME_TAB_MODEL_SUCCESS;

export const HOME_UPDATE_SORTING = 'HOME_UPDATE_SORTING';
export type HOME_UPDATE_SORTING = typeof HOME_UPDATE_SORTING;

export const HOME_UPDATE_PAGE = 'HOME_UPDATE_PAGE';
export type HOME_UPDATE_PAGE = typeof HOME_UPDATE_PAGE;

export const HOME_UPDATE_PAGE_SIZE = 'HOME_UPDATE_PAGE_SIZE';
export type HOME_UPDATE_PAGE_SIZE = typeof HOME_UPDATE_PAGE_SIZE;

export const HOME_SET_SHOW_ACTIVE = 'HOME_SET_SHOW_ACTIVE';
export type HOME_SET_SHOW_ACTIVE = typeof HOME_SET_SHOW_ACTIVE;

export const HOME_UPDATE_FILTERS = 'HOME_UPDATE_FILTERS';
export type HOME_UPDATE_FILTERS = typeof HOME_UPDATE_FILTERS;

export const HOME_UPDATE_FULL_SEARCH = 'HOME_UPDATE_FULL_SEARCH';
export type HOME_UPDATE_FULL_SEARCH = typeof HOME_UPDATE_FULL_SEARCH;

export const HOME_UPDATE_EPISODE_STATE = 'HOME_UPDATE_EPISODE_STATE';
export type HOME_UPDATE_EPISODE_STATE = typeof HOME_UPDATE_EPISODE_STATE;

export const HOME_FETCH_ROWS_START = 'HOME_FETCH_ROWS_START';
export type HOME_FETCH_ROWS_START = typeof HOME_FETCH_ROWS_START;

export const HOME_FETCH_ROWS_SUCCESS = 'HOME_FETCH_ROWS_SUCCESS';
export type HOME_FETCH_ROWS_SUCCESS = typeof HOME_FETCH_ROWS_SUCCESS;

export const HOME_FETCH_ROWS_FAIL = 'HOME_FETCH_ROWS_FAIL';
export type HOME_FETCH_ROWS_FAIL = typeof HOME_FETCH_ROWS_FAIL;

export const HOME_UPDATE_COLUMN_WIDTH = 'HOME_UPDATE_COLUMN_WIDTH';
export type HOME_UPDATE_COLUMN_WIDTH = typeof HOME_UPDATE_COLUMN_WIDTH;

export const HOME_RESET_SELECTION = 'HOME_RESET_SELECTION';
export type HOME_RESET_SELECTION = typeof HOME_RESET_SELECTION;

export const HOME_UPDATE_SELECTION = 'HOME_UPDATE_SELECTION';
export type HOME_UPDATE_SELECTION = typeof HOME_UPDATE_SELECTION;

export const HOME_FORCE_UPDATE = 'HOME_FORCE_UPDATE';
export type HOME_FORCE_UPDATE = typeof HOME_FORCE_UPDATE;

export const HOME_GET_MAIN_MODEL_SUCCESS = 'HOME_GET_MAIN_MODEL_SUCCESS';
export type HOME_GET_MAIN_MODEL_SUCCESS = typeof HOME_GET_MAIN_MODEL_SUCCESS;

export const HOME_OPEN_SEARCH = 'HOME_OPEN_SEARCH';
export type HOME_OPEN_SEARCH = typeof HOME_OPEN_SEARCH;

export const HOME_OPEN_ADVANCED_SEARCH = 'HOME_OPEN_ADVANCED_SEARCH';
export type HOME_OPEN_ADVANCED_SEARCH = typeof HOME_OPEN_ADVANCED_SEARCH;

export interface IGetTabModelSuccessAction {
  type: HOME_TAB_MODEL_SUCCESS;
  columns: IColumn[];
  filterValues: IFilterValue[];
  showProgress: boolean;
  episodeState: number;
}

export const getTabModelSuccess = (
  model: ITabModel
): IGetTabModelSuccessAction => ({
  type: HOME_TAB_MODEL_SUCCESS,
  columns: model.columns ? model.columns : ([] as IColumn[]),
  filterValues: model.filterValues
    ? model.filterValues
    : ([] as IFilterValue[]),
  showProgress: model.showProgress ? model.showProgress : true,
  episodeState: model.episodeState,
});

export interface IClearHomeModelAction {
  type: CLEAR_HOME_MODEL;
}

export const clearHomeModel = () => ({
  type: CLEAR_HOME_MODEL,
});

export interface IGetHomeModelSuccessAction {
  type: HOME_MODEL_SUCCESS;
  model: IHomeModel;
}

export const getHomeModelSuccess = (
  model: IHomeModel
): IGetHomeModelSuccessAction => ({
  type: HOME_MODEL_SUCCESS,
  model: model,
});

export interface IUpdateActiveTabAction {
  type: HOME_SET_ACTIVE_TAB;
  index: number;
}

export const updateActiveTab = (ind: number): IUpdateActiveTabAction => ({
  type: HOME_SET_ACTIVE_TAB,
  index: ind || 0,
});

export interface IUpdateSortingAction {
  type: HOME_UPDATE_SORTING;
  sorting: Sorting[];
}

export const updateSorting = (sorting: Sorting[]): IUpdateSortingAction => ({
  type: HOME_UPDATE_SORTING,
  sorting: sorting,
});

export interface IUpdatePageAction {
  type: HOME_UPDATE_PAGE;
  page: number;
}

export const updatePage = (currentPage: number): IUpdatePageAction => ({
  type: HOME_UPDATE_PAGE,
  page: currentPage,
});

export interface IUpdatePageSizeAction {
  type: HOME_UPDATE_PAGE_SIZE;
  size: number;
}

export const updatePageSize = (currentSize: number): IUpdatePageSizeAction => ({
  type: HOME_UPDATE_PAGE_SIZE,
  size: currentSize,
});

export interface ISetShowActiveAction {
  type: HOME_SET_SHOW_ACTIVE;
  active: boolean | null;
}

export const setShowActive = (state: boolean | null): ISetShowActiveAction => ({
  type: HOME_SET_SHOW_ACTIVE,
  active: state,
});

export interface IUpdateFilteringAction {
  type: HOME_UPDATE_FILTERS;
  filters: Filter[];
}

export const updateFilters = (filters: Filter[]): IUpdateFilteringAction => ({
  type: HOME_UPDATE_FILTERS,
  filters: filters,
});

export interface IUpdateFullSearchAction {
  type: HOME_UPDATE_FULL_SEARCH;
  term: string;
}

export const updateFullSearch = (term: string): IUpdateFullSearchAction => ({
  type: HOME_UPDATE_FULL_SEARCH,
  term: term,
});

export interface IUpdateEpisodeStateAction {
  type: HOME_UPDATE_EPISODE_STATE;
  episodeState: number;
}

export const updateEpisodeState = (
  state: number
): IUpdateEpisodeStateAction => ({
  type: HOME_UPDATE_EPISODE_STATE,
  episodeState: state,
});

export interface IFetchGridRowsStartAction {
  type: HOME_FETCH_ROWS_START;
}

export const fetchGridRowsStart = (): IFetchGridRowsStartAction => ({
  type: HOME_FETCH_ROWS_START,
});

export interface IFetchGridRowsFailAction {
  type: HOME_FETCH_ROWS_FAIL;
  error: string;
}

export const fetchGridRowsFail = (error: string): IFetchGridRowsFailAction => ({
  type: HOME_FETCH_ROWS_FAIL,
  error: error,
});

export interface IFetchGridRowsSuccessAction {
  type: HOME_FETCH_ROWS_SUCCESS;
  data: IDataResult;
}

export const fetchGridRowsSuccess = (
  result: IDataResult
): IFetchGridRowsSuccessAction => ({
  type: HOME_FETCH_ROWS_SUCCESS,
  data: result,
});

export interface IUpdateColumnWidthsAction {
  type: HOME_UPDATE_COLUMN_WIDTH;
  width: TableColumnWidthInfo[];
}

export const updateColumnWidths = (
  width: TableColumnWidthInfo[]
): IUpdateColumnWidthsAction => ({
  type: HOME_UPDATE_COLUMN_WIDTH,
  width: width,
});

export interface IResetSelectionAction {
  type: HOME_RESET_SELECTION;
}

export const resetSelection = (): IResetSelectionAction => ({
  type: HOME_RESET_SELECTION,
});

export interface IUpdateSelectionAction {
  type: HOME_UPDATE_SELECTION;
  selection: (string | number)[];
}

export const updateSelection = (
  selection: (string | number)[]
): IUpdateSelectionAction => ({
  type: HOME_UPDATE_SELECTION,
  selection: selection,
});

export interface IForceUpdateAction {
  type: HOME_FORCE_UPDATE;
  refetchHomeData: boolean;
}

export const forceUpdate = (updateData: boolean): IForceUpdateAction => ({
  type: HOME_FORCE_UPDATE,
  refetchHomeData: updateData,
});

export interface IGetMainModelSuccessAction {
  type: HOME_GET_MAIN_MODEL_SUCCESS;
  users: User[];
  lookups: ILookup[];
  enabledPopulations: ILookupValue[];
  systemValues: ISystemValue[];
}

export const getMainModelSuccess = (
  users: User[],
  lookups: ILookup[],
  enabledPopulations: ILookupValue[],
  systemValues: ISystemValue[]
): IGetMainModelSuccessAction => ({
  type: HOME_GET_MAIN_MODEL_SUCCESS,
  users: users,
  lookups: lookups,
  enabledPopulations: enabledPopulations,
  systemValues: systemValues,
});

export interface IOpenSearchAction {
  type: HOME_OPEN_SEARCH;
  search: string;
}

export const openHomeSearch = (search: string): IOpenSearchAction => ({
  type: HOME_OPEN_SEARCH,
  search,
});

export interface IOpenAdvancedSearchAction {
  type: HOME_OPEN_ADVANCED_SEARCH;
  search: IAdvancedSearchCriteria | null;
}

export const openHomeAdvancedSearch = (
  search: IAdvancedSearchCriteria | null
): IOpenAdvancedSearchAction => ({
  type: HOME_OPEN_ADVANCED_SEARCH,
  search,
});

export type IHomeActions =
  | IClearHomeModelAction
  | IGetHomeModelSuccessAction
  | IGetTabModelSuccessAction
  | IUpdateActiveTabAction
  | IUpdateSortingAction
  | IUpdatePageAction
  | IUpdateFilteringAction
  | IUpdateFullSearchAction
  | IUpdateEpisodeStateAction
  | IFetchGridRowsStartAction
  | IFetchGridRowsFailAction
  | IFetchGridRowsSuccessAction
  | IUpdateColumnWidthsAction
  | IResetSelectionAction
  | IUpdateSelectionAction
  | IForceUpdateAction
  | IGetMainModelSuccessAction
  | ISetShowActiveAction
  | IUpdatePageSizeAction
  | IOpenSearchAction
  | IOpenAdvancedSearchAction;
