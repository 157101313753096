import { format } from 'date-fns';
import { DATE_FORMAT, DATETIME_FORMAT } from 'components/constants';
import store from 'store';
import { utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';
import moment from 'moment/moment';
import { Moment } from 'moment';

export const formatDate = (
  date: Date | string | undefined | null,
  dateFormat = DATE_FORMAT,
  shouldNotApplyOffset?: boolean
) => {
  if (date && shouldNotApplyOffset) {
    return format(new Date(date), dateFormat);
  }
  if (date && date.toString().length === 10) {
    if (typeof date === 'string' && date.match(/^\d\d\d\d-\d\d-\d\d$/)) {
      // see: https://tc39.es/ecma262/#sec-date-time-string-format
      // Most likely ISO-8601 so let's just use spec parser
      // Force local time by appending 'T00:00' with no timezone specifier
      return format(new Date(date + 'T00:00'), dateFormat);
    } else {
      // This is fishy workaround to force local date:
      // Replace is only required to perform _at least one_ replacement
      // And both Chrome and Firefox does exactly one in my machine
      // '2022-08-09' -> '2022/08-09'
      // '2022/08-09' is interpreted as local time by chrome (as opposed to UTC)
      const adjustedString =
        typeof date === 'string' ? new Date(date.replace('-', '/')) : date;
      return format(adjustedString, dateFormat);
    }
  }
  if (date) {
    const careSiteTime = getCareSiteTime(date);
    return format(careSiteTime, dateFormat);
  }

  return '';
};

export const removeTimeZoneFromIsoDateTime = (date: string): Date | null => {
  const dateRegexp =
    /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(.\d+)?)([+-][0-2]\d:[0-5]\d|Z)?$/g;
  if (dateRegexp.test(date)) {
    const dateTimeNoZone = date.split(dateRegexp)[1];
    return new Date(dateTimeNoZone);
  }
  return null;
};

export const getDateFromIsoDateTime = (date: string): Date | null => {
  const dateRegexp =
    /^(\d{4}-[01]\d-[0-3]\d)T[0-2]\d:[0-5]\d:[0-5]\d(.\d+)?([+-][0-2]\d:[0-5]\d|Z)?$/g;
  if (dateRegexp.test(date)) {
    const dateTimeNoZone = date.split(dateRegexp)[1];
    return new Date(dateTimeNoZone + 'T00:00:00.000');
  }
  return null;
};

export const formatDateTime = (
  dateTime: Date | string | undefined | null,
  dateTimeFormat = DATETIME_FORMAT,
  onlyDate = false,
  noTimeConvertion = false
) => {
  if (dateTime) {
    if (onlyDate) {
      const dateIsoFormat = getDateFromIsoDateTime(dateTime as string);
      return dateIsoFormat ? format(dateIsoFormat, DATE_FORMAT) : '';
    }
    if (noTimeConvertion) {
      const dateTimeIsoFormat = removeTimeZoneFromIsoDateTime(
        dateTime as string
      );
      return dateTimeIsoFormat ? format(dateTimeIsoFormat, dateTimeFormat) : '';
    }
    const careSiteTime = getCareSiteTime(dateTime);
    return format(careSiteTime, dateTimeFormat);
  }

  return '';
};

const getCareSiteTime = (date: Date | string) => {
  const timeZone = store.getState().user.currentUser.timeZone;
  return utcToZonedTime(date, timeZone);
};

export const getCareSiteNow = (): Moment => {
  const timeZone = store.getState().user.currentUser.timeZone;
  const timeZoneOffset = getTimezoneOffset(timeZone, new Date());
  return moment()
    .add(-moment().utcOffset(), 'm')
    .add(timeZoneOffset, 'millisecond');
};

export const getStartOfTodayAtCareSite = (): Moment =>
  getCareSiteNow().startOf('day');

export const getCareSiteTimeDebugInfo = (): string[] => {
  const currentUser = store.getState().user.currentUser;
  const timeZoneOffset =
    getTimezoneOffset(currentUser.timeZone, new Date()) / 1000 / 60 / 60;
  return [
    `debug info for ${currentUser.careSite}:`,
    `timezone: ${currentUser.timeZone}`,
    `caresite now: ${getCareSiteNow()}`,
    `getCareSiteTime now: ${getCareSiteTime(new Date())}`,
    `browser now new Date(): ${new Date()}`,
    `browser now new Date().toLocaleString: ${new Date().toLocaleString('sv', {
      timeZoneName: 'short',
    })}`,
    `browser now moment(): ${moment()}`,
    `browser now moment().format(): ${moment().format()}`,
    `timeZoneOffset: ${timeZoneOffset} hours`,
    `UTC now: ${new Date().toUTCString()}`,
  ];
};
