import { COLORS } from 'consts/styles';
import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import {
  getEpisodeRemindersTabName,
  getEpisodeRemindersSubTabName,
} from 'components/home/grid/formatters/EpisodeNumberFormatter';
import { useDispatch } from 'react-redux';
import { EPISODE_SOURCE_FAX } from '../consts';

export enum ReminderIconValue {
  Critical = 'Critical',
  High = 'High',
  Low = 'Low',
  OnSchedule = 'OnSchedule',
  New = 'New',
}

export const reminderIconValueColor = (reminderIcon: ReminderIconValue) => {
  switch (reminderIcon) {
    case ReminderIconValue.Critical:
      return COLORS.RED100;
    case ReminderIconValue.High:
      return COLORS.ORANGE100;
    case ReminderIconValue.Low:
      return COLORS.YELLOW100;
    case ReminderIconValue.OnSchedule:
      return COLORS.COMPLETED_GREEN;
    case ReminderIconValue.New:
      return COLORS.GREEN100;
    default:
      return '';
  }
};

export const useReminderClickHandle = (
  patientId: number | undefined,
  episodeId: number | undefined,
  episodeType: string | undefined,
  rowId: number | undefined,
  episodeSource: string | undefined
) => {
  const dispatch = useDispatch();
  return [
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      const isEpisodeRow = episodeId && episodeType;
      const activeTabName = isEpisodeRow
        ? getEpisodeRemindersTabName(episodeType)
        : 'Reminders';
      const activeSubTabName = isEpisodeRow
        ? getEpisodeRemindersSubTabName(episodeType)
        : '';

      dispatch(
        openPatientDetailsModal({
          patientId: Number(
            patientId ??
              (episodeSource !== EPISODE_SOURCE_FAX ? rowId : undefined)
          ),
          episodeId: episodeId ?? 0,
          activeTabName: activeTabName,
          activeSubTabName: activeSubTabName,
        })
      );
    },
  ];
};
