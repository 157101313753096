import React from 'react';

import { Patient } from 'graphql/graphqlTypes';
import { Box, Typography } from '@mui/material';
import { notMaybe } from 'util/helpers/arrayFunctions';
import { sortPatientProblems } from 'util/patientProblemsSorter';

interface IProps {
  patient?: Patient | undefined;
}

const ProblemList = (props: IProps) => {
  const items = sortPatientProblems(props.patient?.problems?.filter(notMaybe));

  if (!items || items.length === 0) {
    return null;
  }

  return (
    <Box>
      <Typography variant="body2">Active Problem List:</Typography>
      <ul style={{ columns: 2, paddingLeft: '18px', paddingRight: '18px' }}>
        {items.map((problem) => (
          <li key={problem.id}>
            <Typography>{problem?.problem?.name}</Typography>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default ProblemList;
