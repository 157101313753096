import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import store, { IState } from 'store';
import { MozartContent } from '../MozartContent/MozartContent';
import { ChecklistStorageType } from 'store/actions/types';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { PatientCardWrapper } from 'pages/workflow/PatientCardWrapper';
import { api } from 'graphql/hooks/getPatientCardInfo';

const StyledContent = styled.div`
  justify-content: space-between;
  background-color: ${COLORS.GREY4};
  padding-top: 30px;

  @media screen {
    display: none;
  }
`;

const StyledContainer = styled.div`
  padding: 0 80px;
  min-height: 90vh;
`;

interface IMozartPrintLayoutProps {
  isPrintOpen: boolean;
}

const MozartPrintLayout = forwardRef<HTMLDivElement, IMozartPrintLayoutProps>(
  (props, ref) => {
    const patientId = useSelector(
      (state: IState) =>
        state.checklist.documentsState[ChecklistStorageType.MOZART].patientId
    );
    const { data: patientInfo } = api.endpoints.getPatientCardInfo.select({
      patientId: patientId ?? 0,
    })(store.getState());

    return (
      <div ref={ref}>
        {props.isPrintOpen && (
          <StyledContent>
            <StyledContainer>
              <Grid container spacing={3} style={{ position: 'relative' }}>
                <Grid item xs={12} style={{ paddingBottom: '80px' }}>
                  <PatientCardWrapper
                    patientData={patientInfo}
                    storageType={ChecklistStorageType.MOZART}
                  />
                </Grid>
                <Grid item xs={12} style={{ paddingBottom: '80px' }}>
                  <MozartContent
                    isLoadingFinished={true}
                    isLoading={false}
                    isPrinting={true}
                  />
                </Grid>
              </Grid>
            </StyledContainer>
          </StyledContent>
        )}
      </div>
    );
  }
);
MozartPrintLayout.displayName = 'MozartPrintLayout';

export default MozartPrintLayout;
