import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import DialogActions from 'components/modal/DialogActions';
import { Button, Typography } from '@mui/material';
import Loader from 'components/loader';
import { Problem } from 'graphql/graphqlTypes';
import ProblemsGrid from './problemsGrid';
import { problemsColumnNames } from 'components/actions/sections/SectionBody/CarePlan/carePlanProblems';
import { StyledFlexDiv } from './styles';
import { useGetProblemsListQuery } from 'graphql/hooks/getProblemsList';
import { COLORS } from 'consts/styles';

interface ProblemsDialogProps {
  open: boolean;
  excludeIds: number[];
  onSelect: (problems: Problem[]) => void;
  onClose: () => void;
}

const ProblemsDialog = (props: ProblemsDialogProps) => {
  const { open, excludeIds, onSelect, onClose } = props;
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { data, isFetching } = useGetProblemsListQuery(
    { excludeIds },
    {
      refetchOnMountOrArgChange: true,
      skip: !open,
    }
  );

  if (isFetching) {
    return <Loader active={true} />;
  }

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <Typography
            variant="h4"
            style={{
              fontWeight: 500,
              color: COLORS.WHITE,
            }}
          >
            Add Problems
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            height: '566px',
            overflowX: 'auto',
            overflowY: 'scroll',
          }}
          dividers
        >
          <ProblemsGrid
            selectedIds={selectedIds}
            updateSelectedIds={setSelectedIds}
            rows={(data?.getProblemsList ?? []) as Problem[]}
            columns={[{ name: problemsColumnNames.name, title: 'Description' }]}
            getRowId={(row) => Number(row.id)}
          />
        </DialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: ' 20px 28px' }}
        >
          <StyledFlexDiv>
            {selectedIds.length > 0 ? (
              <Typography variant="body2">{`${selectedIds.length} selected`}</Typography>
            ) : null}
          </StyledFlexDiv>
          <StyledFlexDiv>
            <Button
              onClick={onClose}
              color="primary"
              size="large"
              style={{ minWidth: '150px' }}
              variant="outlined"
              data-cy="ok"
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                onSelect(
                  ((data?.getProblemsList ?? []) as Problem[]).filter((x) =>
                    selectedIds.includes(x.id)
                  )
                )
              }
              color="primary"
              size="large"
              style={{ minWidth: '150px' }}
              variant="contained"
              data-cy="ok"
            >
              Add
            </Button>
          </StyledFlexDiv>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProblemsDialog;
