import React, { useState } from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Button, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import Confirmation from 'components/modal/Confirmation';
import { ChecklistProblemViewModel } from 'graphql/graphqlTypes';

const DeleteByIdFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const hideDeleteButton =
    props.value.baseProblems?.find(
      (x: ChecklistProblemViewModel) => Number(x.id) === props.value.id
    )?.inCarePlan ?? false;
  return (
    <>
      <Confirmation
        open={showDeleteConfirmation}
        title={'Delete problem?'}
        okButtonText="Delete"
        okEvent={() => {
          setShowDeleteConfirmation(false);
          props.value.deleteProblem(props.value.id);
        }}
        cancelEvent={() => setShowDeleteConfirmation(false)}
      >
        <Typography>{`Are you sure you want to delete this problem?`}</Typography>
      </Confirmation>
      {hideDeleteButton || props.value.isReadOnly ? null : (
        <Button
          style={{ padding: '0px' }}
          variant="text"
          onClick={() => setShowDeleteConfirmation(true)}
        >
          <Typography variant="body2" style={{ color: COLORS.SYMPHONY_BLUE }}>
            Delete
          </Typography>
        </Button>
      )}
    </>
  );
};

const DeleteByIdProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={DeleteByIdFormatter} {...props} />
);

export default DeleteByIdProvider;
