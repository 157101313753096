import { IKeyValuePair } from 'backend/types/keyValuePair';
import { IPatientForm } from './tabscontent/backgroundTab/types';
import {
  CareTeamMemberDtoInput,
  ChecklistProblemViewModel,
  FamilyMemberDtoInput,
  MedicationViewModel,
  PatientCareGap,
  PatientCareGapInput,
  PatientCondition,
  PatientContactsViewModel,
  PatientEditTagsTabViewModel,
  PatientInfoInput,
  PatientMedicationDtoInInput,
  ProviderMemberDtoInput,
  UpdatePatientCareGapsModelInput,
} from 'graphql/graphqlTypes';
import { GetPatientByIdQuery } from 'graphql/hooks/getPatientById';
import { ILookupValue } from 'backend/types/lookupValue';

const fillPatientContacts = (
  contacts: PatientContactsViewModel | null,
  patientData: PatientInfoInput,
  dirtyBackgroundTab: boolean
) => {
  if (!contacts) {
    return;
  }
  if (dirtyBackgroundTab) {
    patientData.addresses = contacts.addresses;
    patientData.phones = contacts.phones;
    patientData.emails = contacts.emails;
  } else {
    patientData.addresses = [];
    patientData.phones = [];
    patientData.emails = [];
  }
};

const fillPatientConditions = (
  conditions: PatientCondition[] | null | undefined,
  patientData: PatientInfoInput
) => {
  const conditionsData = conditions
    ? conditions?.map((item) => ({
        id: item.id,
        description: item.code
          ? item.code.shortDescription ?? ''
          : item.description ?? '',
        note: item.note,
        status: item.status,
      }))
    : null;
  if (conditionsData) {
    patientData.conditions = conditionsData;
  }
};

const fillPatientTags = (
  tags: PatientEditTagsTabViewModel[] | null | undefined,
  patientData: PatientInfoInput,
  dirtyBackgroundTab: boolean
) => {
  if (dirtyBackgroundTab) {
    const patientTags = tags
      ? tags?.map((item) => ({
          id: item.id,
          isActive: item.isActive as boolean,
        }))
      : null;
    patientData.tags = patientTags;
  } else {
    patientData.tags = [];
  }
};

const fillPatientProblems = (
  problems: ChecklistProblemViewModel[],
  patientData: PatientInfoInput
) => {
  if (problems.length) {
    patientData.problems = problems.filter(Boolean).map((x) => ({
      id: Number(patientData.id),
      isActive: x.isActive,
      isDeleted: false,
      note: x.note,
      problemId: Number(x.id),
    }));
  }
};

const fillPatientMedications = (
  medications: MedicationViewModel[] | null | undefined,
  patientData: PatientInfoInput
) => {
  if (medications) {
    const medicationsCopy = Object.assign(
      [],
      medications.map((x) => ({
        ...x,
        provider: undefined,
        providerId: x.provider?.id,
      }))
    );
    /* eslint-disable @typescript-eslint/no-unused-vars */
    Object.fromEntries(
      Object.entries(medicationsCopy).filter(([_, v]) => v != null || v === '')
    );
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const formatted = medicationsCopy.map((item: any) => ({
      ...Object.fromEntries(
        Object.entries(item).filter(([_, v]) => v != null && v !== '')
      ),
      changed: true,
      deleted: item.deleted,
    }));

    patientData.medications = formatted as PatientMedicationDtoInInput[];
  }
};

const getPatientValues = (
  patientSaveObject: IPatientForm | null,
  contacts: PatientContactsViewModel | null
) => {
  const values: IKeyValuePair[] = [];
  if (patientSaveObject) {
    for (const [key, value] of Object.entries(patientSaveObject)) {
      if (key !== 'Id' && key !== 'EntityAttributes') {
        const res: IKeyValuePair = { key: key, value: '' };
        if (Array.isArray(value)) {
          res.value = value?.map((item) => item).join(',') ?? '';
        } else if (typeof value === 'object') {
          res.value = value?.id?.toString() ?? '';
        } else {
          res.value = value !== null && value !== '' ? value?.toString() : '';
        }
        values.push(res);
      }
    }
  }
  if (contacts?.language?.id) {
    values.push({ key: 'Language', value: contacts?.language?.id.toString() });
  }
  return values;
};

const fillReviewed = (
  lastReviewed: { isNew?: boolean } | undefined,
  patientData: PatientInfoInput
) => {
  patientData.reviewed = !!lastReviewed?.isNew;
};

export const getPatientInfoRequest = (
  patientId: number,
  patientSaveObject: IPatientForm | null,
  contacts: PatientContactsViewModel | null,
  areTagsUpdated: boolean,
  tags: PatientEditTagsTabViewModel[] | null | undefined,
  conditions: PatientCondition[] | null | undefined,
  medications: MedicationViewModel[] | null | undefined,
  problems: ChecklistProblemViewModel[],
  deniesActiveMedications: boolean,
  noKnownDrugAllergies: boolean,
  lastReviewed: { isNew?: boolean } | undefined,
  allergies: string,
  areTeamMembersUpdated: boolean,
  areMedsUpdated: boolean,
  teamMembers: CareTeamMemberDtoInput[] | null | undefined,
  areProvidersUpdated: boolean,
  providers: ProviderMemberDtoInput[] | null | undefined,
  isFamilyUpdated: boolean,
  family: FamilyMemberDtoInput[] | null | undefined,
  dirtyBackgroundTab: boolean
): PatientInfoInput => {
  const values = dirtyBackgroundTab
    ? getPatientValues(patientSaveObject, contacts)
    : [];

  const patientData: PatientInfoInput = {
    id: patientId.toString(),
    values: values,
    areTagsUpdated,
    noKnownDrugAllergies,
    deniesActiveMedications,
    allergies,
    areTeamMembersUpdated: areTeamMembersUpdated,
    teamMembers: teamMembers,
    areProvidersUpdated: areProvidersUpdated,
    providers: providers,
    isFamilyUpdated: isFamilyUpdated,
    family: family,
    areMedsUpdated: areMedsUpdated,
  };

  fillPatientTags(tags, patientData, dirtyBackgroundTab);
  fillPatientContacts(contacts, patientData, dirtyBackgroundTab);
  fillPatientConditions(conditions, patientData);
  fillPatientMedications(medications, patientData);
  fillPatientProblems(problems, patientData);
  fillReviewed(lastReviewed, patientData);

  return patientData;
};

export const getCareGapsRequest = (
  patientId: number,
  careGaps: PatientCareGap[] | null | undefined,
  dirtyBackgroundTab: boolean
): UpdatePatientCareGapsModelInput => ({
  patientId: patientId,
  careGaps: dirtyBackgroundTab
    ? careGaps?.map<PatientCareGapInput>((x) => ({
        id: x.id,
        patientId: Number(x.patientId),
        careGap: x.careGap ?? '',
        priority: x.priority ? { id: Number(x.priority.id) } : null,
        status: x.status ? { id: Number(x.status.id) } : null,
        createdOn: x.createdOn,
        createdBy: { id: Number(x.createdBy.id) },
        updatedOn: x.updatedOn ? x.updatedOn : null,
        updatedBy: x.updatedBy ? { id: Number(x.updatedBy?.id) } : null,
      }))
    : null,
});

export const toPatientForm = (
  patientData: GetPatientByIdQuery | null
): IPatientForm | null => {
  const getPatientById = patientData?.getPatientById;
  const toValueOrEmptyString = (property: string | undefined | null) =>
    property ?? '';

  return {
    FirstName: toValueOrEmptyString(getPatientById?.firstName),
    LastName: toValueOrEmptyString(getPatientById?.lastName),
    MiddleName: toValueOrEmptyString(getPatientById?.middleName),
    DOB: toValueOrEmptyString(getPatientById?.dOB),
    DOD: toValueOrEmptyString(getPatientById?.dOD),
    Gender: getPatientById?.gender?.id ?? null,
    Guardian: toValueOrEmptyString(getPatientById?.guardian),
    IsActive: getPatientById?.isActive ?? false,
    MRN: toValueOrEmptyString(getPatientById?.mRN),
    MRN2: toValueOrEmptyString(getPatientById?.mRN2),
    MRN3: toValueOrEmptyString(getPatientById?.mRN3),
    MRN4: toValueOrEmptyString(getPatientById?.mRN4),
    MRN5: toValueOrEmptyString(getPatientById?.mRN5),
    Populations: getPatientById?.populations
      ? getPatientById?.populations?.map((item) => Number(item?.id))
      : null,
    PrimaryCareProvider:
      (getPatientById?.primaryCareProvider as ILookupValue) ?? null,
    Note: toValueOrEmptyString(
      getPatientById?.entityAttributes?.find((item) => item?.name === 'Note')
        ?.value
    ),
  };
};
