import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchClaimsQueryVariables = Types.Exact<{
  request: Types.SearchClaimRequestInput;
}>;

export type SearchClaimsQuery = {
  __typename?: 'EntitiesQuery';
  searchClaims: Array<{
    __typename?: 'SearchClaimResult';
    id: number;
    claimId?: string | null;
    claimDate?: any | null;
    serviceFrom?: any | null;
    serviceTo?: any | null;
    paidAmount?: any | null;
    diagnosisCode?: {
      __typename?: 'MedicationCode';
      id: number;
      code?: string | null;
      shortDescription?: string | null;
    } | null;
    procedureCode?: {
      __typename?: 'MedicationCode';
      id: number;
      code?: string | null;
      shortDescription?: string | null;
    } | null;
    provider?: {
      __typename?: 'Provider';
      id: number;
      lastName?: string | null;
      firstName?: string | null;
      providerId?: string | null;
      nPI?: string | null;
    } | null;
  }>;
};

export const SearchClaimsDocument = `
    query searchClaims($request: SearchClaimRequestInput!) {
  searchClaims(request: $request) {
    id
    claimId
    claimDate
    serviceFrom
    serviceTo
    paidAmount
    diagnosisCode {
      id
      code
      shortDescription
    }
    procedureCode {
      id
      code
      shortDescription
    }
    provider {
      id
      lastName
      firstName
      providerId
      nPI
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchClaims: build.query<SearchClaimsQuery, SearchClaimsQueryVariables>({
      query: (variables) => ({ document: SearchClaimsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchClaimsQuery, useLazySearchClaimsQuery } =
  injectedRtkApi;
