import { ChecklistProblemViewModel } from 'graphql/graphqlTypes';

export const carePlanColumns = [
  { name: 'name', title: 'Problem Description' },
  {
    name: 'inCarePlan',
    title: 'Care Plan',
    getCellValue: (row: ChecklistProblemViewModel) =>
      row.inCarePlan ? 'Yes' : 'No',
  },
  {
    name: 'isActive',
    title: 'Status',
    getCellValue: (row: ChecklistProblemViewModel) =>
      row.isActive ? 'Active' : 'Inactive',
  },
  { name: 'note', title: 'Notes' },
];

export const carePlanColumnExtensions = [
  { columnName: 'name', sortingEnabled: true, width: '15%' },
  { columnName: 'inCarePlan', sortingEnabled: true, width: '15%' },
  { columnName: 'isActive', sortingEnabled: true, width: '15%' },
  { columnName: 'note', sortingEnabled: true },
];
