import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  MozartDoneAction,
  MozartPauseAction,
  MozartRetryAction,
  MozartStep,
  WorkflowRouting,
} from 'features/mozart/components';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import {
  MozartActionType,
  MozartDefinitionType,
  MozartProgressState,
} from 'graphql/graphqlTypes';
import {
  MozartShowMessage,
  MozartUserInput,
  StyledSectionWrapper,
} from './components';
import { getCompressedMessages, toBoolean } from './MozartContent.helpers';
import { MozartStepMessageProcessByUser } from './MozartContentUIHelpers';
import { MozartMessage } from 'features/mozart/components/MozartAction/MozartMessage';
import { MozartComplete } from 'features/mozart/components/MozartAction/MozartComplete';
import { useGetMozartChecklist } from 'features/mozart/MozartWorkflowLayout.hooks';
import { useParams } from 'react-router-dom';
import Loader from 'components/loader';
import { setMozartViewOnly } from 'store/actions/mozartSlice';

export const MozartContent = ({
  isLoadingFinished,
  isLoading,
  isPrinting,
}: {
  isLoadingFinished: boolean;
  isLoading: boolean;
  isPrinting: boolean;
}) => {
  const params = useParams();
  const url = useMemo(
    () => new URLSearchParams(location.search),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search]
  );
  const isDuplicateCheckLoading = useSelector(
    (state: IState) => state.mozart.duplicateCheckLoading
  );
  const [previousOffset, setPreviousOffset] = useState(0);
  const isMozartViewOnly = toBoolean(url.get('mozartViewOnly'));
  const {
    isLoading: isStartingMozartChecklist,
    isFinishedLoading: mozartFinishedLoading,
  } = useGetMozartChecklist(params.id, isPrinting, isMozartViewOnly);
  const dispatch = useDispatch();
  const isLoadingComplete =
    mozartFinishedLoading && isLoadingFinished && !isDuplicateCheckLoading;
  const isCurrentlyLoading =
    isStartingMozartChecklist || isLoading || isDuplicateCheckLoading;

  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );
  const compressedMessages = getCompressedMessages(mozartMessages);
  useEffect(() => {
    dispatch(setMozartViewOnly(isMozartViewOnly));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMozartViewOnly]);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && isCurrentlyLoading) {
      const offsetBottom =
        contentRef.current.offsetTop + contentRef.current.offsetHeight;
      setPreviousOffset(offsetBottom);
      if (previousOffset !== offsetBottom) {
        window.scrollTo({ top: offsetBottom });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compressedMessages]);
  return (
    <>
      <div ref={contentRef}>
        {compressedMessages.map((message) => {
          switch (message.actionType) {
            case MozartActionType.AutoBalancer:
            case MozartActionType.Escalate:
            case MozartActionType.GenerateReminder:
            case MozartActionType.OcrCheck:
            case MozartActionType.PauseOnAction:
            case MozartActionType.PriorAuth:
              return (
                <StyledSectionWrapper key={message.scopeId}>
                  <MozartMessage
                    message={`Action type ${message.actionType} is not implemented.`}
                  />
                </StyledSectionWrapper>
              );
            case MozartActionType.JumpTo:
            case MozartActionType.JumpToAction:
            case MozartActionType.JumpToStep:
            case MozartActionType.DuplicateCheck:
            case MozartActionType.JumpToPreviousStep:
              return (
                <WorkflowRouting
                  key={message.scopeId}
                  messages={message.groupedMessages}
                />
              );
            case MozartActionType.UserInput:
              if (message.state == MozartProgressState.Skipped) {
                return (
                  <WorkflowRouting
                    key={message.scopeId}
                    messages={message.groupedMessages}
                  />
                );
              }
              return (
                <div key={message.scopeId}>
                  <MozartUserInput
                    isLoadingFinished={isLoadingComplete}
                    message={message}
                  />
                </div>
              );
            case MozartActionType.ShowMessage:
            case MozartActionType.ShowModule:
              return (
                <MozartShowMessage key={message.scopeId} message={message} />
              );
            case MozartActionType.PauseWorkflow:
              return (
                <div key={message.scopeId} data-testid="mozart-pause-actions">
                  <MozartPauseAction
                    messages={message.groupedMessages}
                    isLoadingComplete={isLoadingComplete}
                  />
                </div>
              );
            case MozartActionType.WorkflowException:
              return (
                <StyledSectionWrapper key={message.scopeId}>
                  <MozartStep currentStep={'Exception'} message={message} />
                  <MozartMessage
                    message={'Exception occurred, Mozart is retrying...'}
                  />
                </StyledSectionWrapper>
              );
            case MozartActionType.SendLetter:
            case MozartActionType.CheckAttachments:
            case MozartActionType.SendEmail:
              return null;
          }
          if (
            message.type === MozartDefinitionType.Workflow &&
            message.state === MozartProgressState.Completed &&
            isLoadingComplete
          ) {
            return (
              <div key={message.scopeId} data-testid="mozart-finish-actions">
                <MozartComplete isLoadingComplete={isLoadingComplete} />
              </div>
            );
          }
          if (message.state === MozartProgressState.Skipped) {
            return (
              <WorkflowRouting
                key={message.scopeId}
                messages={message.groupedMessages}
              />
            );
          }
          if (
            message.state === MozartProgressState.Paused &&
            message.actionType === MozartActionType.None &&
            message.type === MozartDefinitionType.Action
          ) {
            return (
              <MozartStepMessageProcessByUser
                key={message.scopeId}
                message={message}
              />
            );
          }
          return null;
        })}
      </div>
      <MozartDoneAction isLoadingComplete={isLoadingComplete} />
      <MozartRetryAction isLoadingComplete={isLoadingComplete} />
      <Loader active={isCurrentlyLoading} />
    </>
  );
};
