import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientRemindersQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientRemindersQuery = {
  __typename?: 'EntitiesQuery';
  getPatientReminders: Array<{
    __typename?: 'BaseReminder';
    id: number;
    importanceId?: number | null;
    daysOverdue: number;
    date?: any | null;
    isCompleted?: boolean | null;
    isDeleted?: boolean | null;
    isExpired?: boolean | null;
    isNew?: boolean | null;
    createdBy: number;
    importance?: Types.ReminderImportance | null;
    note?: string | null;
    episode?: {
      __typename?: 'Episode';
      id: number;
      episodeNumber?: string | null;
      type?: {
        __typename?: 'EpisodeType';
        name?: string | null;
        id: number;
      } | null;
    } | null;
    reason?: {
      __typename?: 'LookupValue';
      id: number;
      image?: string | null;
      name?: string | null;
      sortOrder?: number | null;
    } | null;
    owner?: {
      __typename?: 'User';
      fullName?: string | null;
      id: number;
    } | null;
  }>;
};

export const GetPatientRemindersDocument = `
    query getPatientReminders($patientId: Int!) {
  getPatientReminders(patientId: $patientId) {
    id
    importanceId
    daysOverdue
    date
    isCompleted
    isDeleted
    isExpired
    isNew
    createdBy
    episode {
      id
      episodeNumber
      type {
        name
        id
      }
    }
    reason {
      id
      image
      name
      sortOrder
    }
    importance
    owner {
      fullName
      id
    }
    note
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientReminders: build.query<
      GetPatientRemindersQuery,
      GetPatientRemindersQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientRemindersDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientRemindersQuery, useLazyGetPatientRemindersQuery } =
  injectedRtkApi;
