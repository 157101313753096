import React from 'react';
import { FooterContainer } from 'components/footer/FooterContainer';
import Box from '@mui/material/Box';
import { Button, CircularProgress } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { useShowValidation } from 'hooks/useShowValidation';
import { setErrorBarVisible } from 'store/actions/checklistSlice';
import { clearFailedRequests } from 'store/failedRequests/failedRequestsSlice';
import { useNavigate } from 'react-router';
import { setShowLeavingChecklistConfirmation } from 'store/ui/modals/checklist';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

interface IChecklistFooterProps {
  storageType: ChecklistStorageType;
  handleFinishLater: () => void;
  isLoading: boolean;
  handleDiscardConfirmation: (discard: boolean) => void;
}

const ChecklistFooter = ({
  storageType,
  handleFinishLater,
  isLoading,
  handleDiscardConfirmation,
}: IChecklistFooterProps) => {
  const dispatch = useDispatch();
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );
  const patientId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].patientId
  );

  const isInputSaving = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].isSaving
  );
  const isAmendingChecklist = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType].isAmendingChecklist
  );

  const hasFailedRequests = useSelector(
    (state: IState) => (state.failedRequest.requests ?? []).length > 0
  );

  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const sectionName = 'Episode Summary';

  const { hasValidationRules } = useShowValidation(storageType);
  const navigate = useNavigate();

  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();

  const handleNextStep = () => {
    handleDirtyConfirm(isDirty, onConfirmNextStep);
  };

  const onConfirmNextStep = () => {
    clearDirtyState();
    if (hasValidationRules) {
      dispatch(
        setErrorBarVisible({
          storageType: storageType,
          visible: true,
        })
      );
    } else {
      navigate(`/Checklist/Review/${checklistId}`);
    }
  };

  const handleClickBackButton = () => {
    handleDirtyConfirm(isDirty, onConfirmLeavingChecklist);
  };

  const onConfirmLeavingChecklist = () => {
    clearDirtyState();
    dispatch(clearFailedRequests());

    dispatch(
      setShowLeavingChecklistConfirmation({
        exitModalOpen: true,
        action: 'Discard',
        route: `/Checklist/Problems/?patientId=${patientId}`,
      })
    );
  };

  return (
    <FooterContainer data-testid="ActionFooter" loading={isLoading}>
      <Box>
        <Button
          onClick={handleClickBackButton}
          color="primary"
          data-cy="back-to-scenario"
          size="large"
          disabled={isAmendingChecklist}
          startIcon={
            <Icon
              icon={ICONS.Back}
              size={22}
              color={isAmendingChecklist ? COLORS.GREY30 : COLORS.SYMPHONY_BLUE}
            />
          }
        >
          Scenario
        </Button>
      </Box>
      <Box>
        <Button
          onClick={() => handleDiscardConfirmation(true)}
          color="primary"
          data-cy="discard-scenario"
          size="large"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
        >
          Discard
        </Button>
        <Button
          color="primary"
          size="large"
          onClick={handleFinishLater}
          data-cy="finish-later"
          style={{
            marginLeft: '12px',
            marginRight: '12px',
          }}
          disabled={isInputSaving || hasFailedRequests}
        >
          Finish Later
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginLeft: '12px', minWidth: '98px' }}
          onClick={handleNextStep}
          data-cy="checklist-next"
          disabled={isInputSaving || hasFailedRequests}
        >
          {isInputSaving ? <CircularProgress size={20} /> : 'Next'}
        </Button>
        <DirtyConfirmationModal
          showModal={showModal}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          sectionName={sectionName}
        />
      </Box>
    </FooterContainer>
  );
};

export default ChecklistFooter;
