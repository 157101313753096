import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type SearchEpisodesQueryVariables = Types.Exact<{
  request: Types.SearchEpisodeRequestInput;
}>;

export type SearchEpisodesQuery = {
  __typename?: 'EntitiesQuery';
  searchEpisodes: Array<{
    __typename?: 'Episode';
    id: number;
    createdOn?: any | null;
    faxDateTime?: any | null;
    episodeNumber?: string | null;
    determinationDate?: any | null;
    owner?: {
      __typename?: 'User';
      fullName?: string | null;
      id: number;
    } | null;
    authorizationStatus?: {
      __typename?: 'LookupValue';
      id: number;
      name?: string | null;
    } | null;
    diagnosisCodesAll?: Array<{
      __typename?: 'MedicationCode';
      id: number;
      code?: string | null;
      shortDescription?: string | null;
    } | null> | null;
    procedureCodesAll?: Array<{
      __typename?: 'MedicationCode';
      id: number;
      code?: string | null;
      shortDescription?: string | null;
    } | null> | null;
    requestingClinician?: {
      __typename?: 'Provider';
      id: number;
      name?: string | null;
      nPI?: string | null;
    } | null;
    entityAttributes?: Array<{
      __typename?: 'EntityAttribute';
      id: number;
      value?: string | null;
      name?: string | null;
      lookup?: {
        __typename?: 'LookupValue';
        id: number;
        name?: string | null;
      } | null;
    } | null> | null;
  }>;
};

export const SearchEpisodesDocument = `
    query searchEpisodes($request: SearchEpisodeRequestInput!) {
  searchEpisodes(request: $request) {
    id
    createdOn
    faxDateTime
    episodeNumber
    determinationDate
    owner {
      fullName
      id
    }
    authorizationStatus {
      id
      name
    }
    diagnosisCodesAll {
      id
      code
      shortDescription
    }
    procedureCodesAll {
      id
      code
      shortDescription
    }
    requestingClinician {
      id
      name
      nPI
    }
    entityAttributes {
      id
      value
      name
      lookup {
        id
        name
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchEpisodes: build.query<
      SearchEpisodesQuery,
      SearchEpisodesQueryVariables
    >({
      query: (variables) => ({ document: SearchEpisodesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSearchEpisodesQuery, useLazySearchEpisodesQuery } =
  injectedRtkApi;
