import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Attachment, ChecklistItemLetterSummary } from 'graphql/graphqlTypes';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { gridColumns } from './columnSettings/LetterSummaryGridColumns';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { useGetLetterSummaryQuery } from 'graphql/hooks/getLetterSummary';
import Loader from 'components/loader';
import { COLORS } from 'consts/styles';
import { ChecklistStorageType } from 'store/actions/types';
import { INDIVIDUAL_COMPONENT_CODE } from 'components/constants';
import { LetterSummaryColumns } from './columnSettings/LetterSummaryColumns';

import { LetterSummaryActionsProvider } from './columnFormatters/LetterSummaryActionsProvider';
import { LetterSummaryCreatedOnProvider } from './columnFormatters/LetterSummaryCreatedOnProvider';
import { useUpdateActionValue } from '../UpdateActionValue.helpers';
import useComponentHasFailedRequest from 'components/failedRequests/FailedRequests.helper';

export interface IChecklistItemLetterSummaryProps {
  storageType: ChecklistStorageType;
  item: ChecklistItemLetterSummary;
  isReadOnly: boolean;
}

const LetterSummary = ({
  storageType,
  item,
  isReadOnly,
}: IChecklistItemLetterSummaryProps) => {
  const checklistId = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].checklist?.id
  );

  const isUpdateActionValueRequestFailed = useComponentHasFailedRequest(
    item.uid
  );

  const { updateActionValueExtended } = useUpdateActionValue();
  const {
    data: actualData,
    isFetching,
    isSuccess,
  } = useGetLetterSummaryQuery(
    {
      checklistId: Number(checklistId ?? 0),
    },
    {
      skip: !checklistId || isReadOnly,
    }
  );

  const letterSummaryData = isReadOnly
    ? item.lsValue ?? []
    : actualData?.getLetterSummary ?? [];

  useEffect(() => {
    if (isSuccess && !isReadOnly) {
      const data = {
        checklistId: Number(checklistId) ?? 0,
        componentId: INDIVIDUAL_COMPONENT_CODE,
        id: item.uid,
        type: 'string',
        value: JSON.stringify(letterSummaryData),
      };
      updateActionValueExtended(data, item.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Box id={`uuid-${item.uid}`}>
      <Loader active={isFetching} />
      <Box bgcolor={COLORS.WHITE} padding="24px 20px 24px 36px">
        <Typography variant="body2">
          {item.options?.codeLabel ? item.options.codeLabel : 'Letter summary'}
        </Typography>
      </Box>
      <Box bgcolor={COLORS.WHITE} padding="0px 20px 4px 36px">
        <Grid
          rows={letterSummaryData}
          columns={gridColumns}
          getRowId={(row: Attachment) => row.id}
        >
          <LetterSummaryCreatedOnProvider
            for={[LetterSummaryColumns.createdOn]}
          />
          <LetterSummaryActionsProvider for={[LetterSummaryColumns.actions]} />
          <SymphonyTable />
          <SymphonyTableHeaderRow />
        </Grid>
      </Box>
      {isUpdateActionValueRequestFailed && (
        <Typography style={{ paddingLeft: '36px', color: 'red' }}>
          Internal server error: Your changes could not be saved, because of
          technical issues.
        </Typography>
      )}
    </Box>
  );
};

export default LetterSummary;
