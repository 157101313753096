import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import throttle from 'lodash/throttle';
import { ILookupValue } from 'backend/types/lookupValue';
import { usePrevious } from 'hooks';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import { AutocompleteInputChangeReason } from '@mui/material/useAutocomplete';

interface IAutocompleteProps {
  id?: string;
  label?: string;
  labelId?: string;
  initValue?: ILookupValue | null;
  error?: boolean | null;
  disabled?: boolean;

  suggestItems(request: string, callback: (arg: ILookupValue[]) => void): void;

  onChange(event: ChangeEvent<unknown>, newValue: ILookupValue | null): void;
  color?: string;
}

type IAutocompleteRef = {
  current: HTMLDivElement | null;
};

const StyledTextField = styled(TextField)`
  font-size: 14px;
  color: ${COLORS.NAVY_BLUE};
  background-color: ${COLORS.WHITE};
  .MuiAutocomplete-inputRoot {
    padding: 3.5px 32px 3.5px 6px !important;
  }
`;

const ServerAutocomplete = forwardRef<IAutocompleteRef, IAutocompleteProps>(
  (props: IAutocompleteProps, ref) => {
    const {
      suggestItems,
      onChange,
      initValue,
      label,
      disabled,
      color,
      ...restProps
    } = props;
    const emptyInitialValue = {
      id: 0,
      name: '',
    } as ILookupValue;
    const [value, setValue] = useState<ILookupValue | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<ILookupValue[]>([]);
    const prevInputValue = usePrevious(inputValue);

    const fetchItemsFromServer = useMemo(
      () =>
        throttle(
          (request: string, callback: (arg: ILookupValue[]) => void) =>
            suggestItems(request, callback),
          200
        ),
      [suggestItems]
    );

    useEffect(() => {
      let active = true;

      if (inputValue === '') {
        setOptions(value ? [value] : []);
      }

      if (inputValue !== prevInputValue) {
        fetchItemsFromServer(inputValue, (results: ILookupValue[]) => {
          if (active) {
            let newOptions: ILookupValue[] = [];
            if (value) {
              newOptions = [value];
            }
            newOptions = [...newOptions, ...results];
            setOptions(newOptions);
          }
        });
      }

      return () => {
        active = false;
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, inputValue, fetchItemsFromServer]);

    useEffect(() => {
      setValue(initValue ?? null);
    }, [initValue]);

    const handleAutocompleteChange = (
      event: ChangeEvent<unknown>,
      newValue: ILookupValue | null
    ) => {
      const newNotNullValue =
        newValue === null || newValue === undefined
          ? emptyInitialValue
          : newValue;

      setOptions([newNotNullValue, ...options]);
      setValue(newNotNullValue);
      onChange(event, newNotNullValue);
    };

    const handleInputChange = (
      _event: ChangeEvent<unknown>,
      newInputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      if (reason === 'reset') {
        setValue(null);
        setInputValue('');
      }
      setInputValue(newInputValue);
    };

    return (
      <Autocomplete
        getOptionLabel={(option: ILookupValue) => option.name}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        disabled={disabled}
        style={{ width: '100%', color: color }}
        ref={ref}
        includeInputInList
        filterSelectedOptions
        isOptionEqualToValue={(option: ILookupValue, newValue: ILookupValue) =>
          option.id === newValue.id
        }
        value={value}
        onChange={(
          event: ChangeEvent<unknown>,
          newValue: ILookupValue | null
        ) => {
          handleAutocompleteChange(event, newValue);
        }}
        onInputChange={(
          _event: ChangeEvent<unknown>,
          newInputValue: string,
          reason
        ) => {
          handleInputChange(_event, newInputValue, reason);
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StyledTextField
            type="text"
            {...params}
            variant="outlined"
            size="small"
            disabled={disabled}
            fullWidth={true}
            label={label}
            error={props.error || false}
          />
        )}
        {...restProps}
      />
    );
  }
);

ServerAutocomplete.displayName = 'ServerAutocomplete';

export default ServerAutocomplete;
