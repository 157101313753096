import React, { useRef, useState, useEffect, useCallback } from 'react';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { useDispatch, useSelector } from 'react-redux';
import { openHomeSearch, updateSelection } from 'store/home/action';
import { setSearch } from 'store/ui/header';
import { IState } from 'store';
import { useSaveRecentSearchesMutation } from 'graphql/hooks/saveRecentSearches';
import { useGetRecentSearchesQuery } from 'graphql/hooks/getRecentSearches';
import { SearchSuggestions } from 'components/header/components/SearchSuggestions';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import AdvancedSearchPopover from './AdvancedSearchPopover';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { useSingleAndDoubleClick } from 'hooks/useSingleAndDoubleClick';
import { Chip } from '@mui/material';
import { MAXIMUM_FILTER_CHIP_LENGTH } from 'components/constants';
import { IAdvancedSearchCriteria } from 'backend/types/advancedSearchCriteria';

const StyledOutlinedInput = styled(OutlinedInput)`
  height: 32px;
  background-color: white;
  padding-right: 3px;
  width: 450px;
`;

const StyledSearchIconButton = styled(IconButton)`
  margin-top-right: -13px !important;
  border-radius: 0 4px 4px 0;
  height: 32px;
  width: 38px;
  background: ${COLORS.BLUE10};
  &:hover {
    background: ${COLORS.BLUE10};
  }
  position: relative;
`;

const StyledRemoveIconButton = styled(IconButton)`
  margin-right: 3px;
  background-color: transparent;
  position: relative;
`;

const StyledInputAdornment = styled(InputAdornment)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
`;

const StyledChip = styled(Chip)`
  margin-left: 5px;
  padding-right: 12px;
  height: 20px;
`;

export const filterChipInitialValues: IAdvancedSearchCriteria = {
  firstName: '',
  lastName: '',
  dob: '',
};

export const MemberSearchBar = () => {
  const dispatch = useDispatch();
  const [isRecentOpen, setRecentOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [resetAdvancedSearch, setResetAdvancedSearch] = useState(false);

  const value = useSelector((state: IState) => state.ui.header.search);
  const setValue = useCallback(
    (newValue: string) => dispatch(setSearch(newValue)),
    [dispatch]
  );

  const removeFilterChips = useSelector(
    (state: IState) => state.ui.header.removeFilterChips
  );

  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const { data, refetch } = useGetRecentSearchesQuery();
  const recent = data?.getRecentSearches;

  const [saveSearch] = useSaveRecentSearchesMutation();

  const handleDoubleClick = () => {
    if (ref.current) {
      const inputElement = ref.current.querySelector('input');
      if (inputElement?.value) {
        inputElement.select();
      }
    }
  };

  const getChippedValue = (value: string | null | undefined) =>
    value && value.length > MAXIMUM_FILTER_CHIP_LENGTH
      ? value.substring(0, MAXIMUM_FILTER_CHIP_LENGTH) + '...'
      : value;

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const [filterChip, setFilterChip] = useState(filterChipInitialValues);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const hasAdvancedSearchPrivilege = hasUserPrivilege(Privilege.AdvancedSearch);

  const handleSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement | MouseEvent | HTMLLIElement>,
    searchTerm?: string
  ) => {
    event.preventDefault();
    event.stopPropagation();
    const searchValue = searchTerm ?? value;
    setValue(searchValue);
    saveSearch({ value }).then(() => refetch());
    setRecentOpen(false);
    dispatch(updateSelection([]));
    setResetAdvancedSearch(true);
    dispatch(openHomeSearch(searchValue));
  };

  const handleSearchBarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setValue('');
    setShowPopover(true);
  };

  const handleCloseButtonClick = useCallback(() => {
    setValue('');
    setIsAdvancedSearch(false);
    setResetAdvancedSearch(true);
    setRecentOpen(false);
    setFilterChip(filterChipInitialValues);
  }, [
    setValue,
    setIsAdvancedSearch,
    setResetAdvancedSearch,
    setRecentOpen,
    setFilterChip,
  ]);

  useEffect(() => {
    if (removeFilterChips && handleCloseButtonClick) {
      handleCloseButtonClick();
    }
  }, [handleCloseButtonClick, removeFilterChips]);

  return (
    <div ref={ref}>
      <form onSubmit={handleSubmit} data-cy="search-bar">
        <FormControl size="small" variant="outlined">
          <StyledOutlinedInput
            onChange={(e) => {
              setRecentOpen(true);
              setValue(e.target.value);
            }}
            value={value}
            onClick={useSingleAndDoubleClick(
              () =>
                isAdvancedSearch ? setShowPopover(true) : setRecentOpen(true),
              () => handleDoubleClick
            )}
            placeholder={
              isAdvancedSearch ? '' : 'Search All Members and Episodes...'
            }
            inputProps={{ style: { borderRadius: '4px' } }}
            startAdornment={
              isAdvancedSearch && (
                <StyledInputAdornment position="start">
                  <StyledChip
                    label={`First:${getChippedValue(filterChip.firstName)}`}
                  />
                  <StyledChip
                    label={`Last:${getChippedValue(filterChip.lastName)}`}
                  />
                  <StyledChip label={`DOB:${filterChip.dob}`} />
                </StyledInputAdornment>
              )
            }
            endAdornment={
              <>
                <InputAdornment position="end">
                  {(value || isAdvancedSearch) && (
                    <StyledRemoveIconButton
                      edge="end"
                      size="small"
                      onClick={handleCloseButtonClick}
                    >
                      <Icon
                        icon={ICONS.Remove}
                        size={18}
                        color={COLORS.GREY100}
                      />
                    </StyledRemoveIconButton>
                  )}

                  <StyledSearchIconButton
                    edge="end"
                    size="small"
                    onClick={(e) => handleSubmit(e)}
                  >
                    <Icon
                      icon={ICONS.Search}
                      size={14}
                      color={COLORS.SYMPHONY_BLUE}
                    />
                  </StyledSearchIconButton>
                </InputAdornment>
              </>
            }
          />
        </FormControl>
      </form>
      <SearchSuggestions
        isRecentOpen={isRecentOpen}
        handleSubmit={handleSubmit}
        handleClose={setRecentOpen}
        recentSearches={recent ?? []}
        setShowPopover={handleSearchBarClick}
        setAnchorEl={setAnchorEl}
        ref={ref}
        inputValue={value}
        hasAdvancedSearchPrivilege={hasAdvancedSearchPrivilege}
        setIsAdvancedSearch={setIsAdvancedSearch}
      />
      <AdvancedSearchPopover
        open={open}
        id={id}
        handleClose={() => {
          setAnchorEl(null);
          setShowPopover(false);
        }}
        anchorEl={anchorEl}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        setRecentOpen={setRecentOpen}
        resetAdvancedSearch={resetAdvancedSearch}
        setResetAdvancedSearch={setResetAdvancedSearch}
        setFilterChip={setFilterChip}
        setIsAdvancedSearch={setIsAdvancedSearch}
      />
    </div>
  );
};
