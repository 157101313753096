import { Filter } from '@devexpress/dx-react-grid';
import { IColumn, IFilterValue } from 'store/home/types';
import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'store';

export const getFilterValue = (value?: string, isBooleanFilter?: boolean) => {
  if (isBooleanFilter) {
    return value === '1' ? 'Yes' : 'No';
  } else {
    return value ?? '';
  }
};

export const getColumnName = (filter: Filter, columnNames: IColumn[]) => {
  if (filter.columnName === 'Patient_Tags') {
    return 'Tag';
  }
  return (
    columnNames?.find((name) => name?.fullPropertyName === filter?.columnName)
      ?.displayName ?? ''
  );
};

export const getTagFilterImage = (
  filter: Filter,
  filterValues: IFilterValue[]
) => {
  if (filter.columnName === 'Patient_Tags') {
    return filterValues
      ? filterValues
          ?.filter((value) => value?.name === 'Patient_Tags')[0]
          ?.values.filter(
            (value) => Number(value.id) === Number(filter.value)
          )[0]?.image
      : '';
  }
};

export const getDisplayValue = (
  isDropdownValue: boolean,
  isBooleanFilter: boolean,
  filter: Filter,
  filterValues: IFilterValue[]
) =>
  isDropdownValue
    ? filterValues
        .map((value) =>
          value.values.find(
            (f) =>
              Number(f.id) === Number(filter.value) &&
              value.name === filter.columnName
          )
        )
        .filter(Boolean)[0]?.name ??
      getFilterValue(filter.value, isBooleanFilter)
    : getFilterValue(filter.value, isBooleanFilter);

export const formatMultiValue = (value?: string, filterValue?: string) =>
  value
    ?.replace(/[ ,]+/g, ',')
    .replace(filterValue ?? '', '')
    .split(/[ ,]+/)
    .join(',')
    .replace(/(^,)|(,$)/g, '');

export const appliedFiltersSelector = createSelector(
  [
    (state: IState) => state.home?.columns,
    (state: IState) => state.home.homeModel.filterBy,
    (state: IState) => state.home.filterValues,
  ],
  (columnNames, filters, filterValues) => {
    const isDropdownValue = filters?.filter((filter) =>
      filterValues.filter(
        (filterValue) => filterValue.name !== filter.columnName
      )
    );

    const transformedFilters = filters?.map((filter) => {
      const isMultiValue =
        columnNames?.find(
          (column) => column.fullPropertyName === filter.columnName
        )?.filter.multiValues || filter.columnName === 'Patient_Tags';

      const isBooleanFilter =
        columnNames?.find(
          (column) => column.fullPropertyName === filter.columnName
        )?.entityAttributeDef.attributeType.type === 'BoolAttributeType';

      return {
        name: getColumnName(filter, columnNames),
        value: filter.value,
        columnName: filter.columnName,
        originalValue: filter.value,
        displayValue: getDisplayValue(
          Boolean(isDropdownValue),
          isBooleanFilter,
          filter,
          filterValues
        ),
        image: getTagFilterImage(filter, filterValues),
        isMultiValue: isMultiValue,
        isBooleanFilter: isBooleanFilter,
      };
    });

    return transformedFilters
      ?.map((item) =>
        item.isMultiValue
          ? item.value?.split(',').map((multiValue: never) => ({
              name: item.name,
              originalValue: item.originalValue,
              displayValue: getDisplayValue(
                Boolean(isDropdownValue),
                item.isBooleanFilter,
                { ...item, value: multiValue },
                filterValues
              ),
              value: multiValue ?? '',
              columnName: item.columnName,
              isMultiValue: item.isMultiValue,
              image: getTagFilterImage(
                { value: multiValue, columnName: item.columnName },
                filterValues
              ),
            }))
          : item
      )
      .flat();
  }
);
