import {
  Column,
  CustomPaging,
  Grid as GridBase,
  IntegratedSelection,
  PagingState,
  SelectionState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Episode, EpisodeWithMozartInfoViewModel } from 'graphql/graphqlTypes';
import { IEpisodesTabState } from './types';
import {
  Grid,
  Grid as TableGrid,
  PagingPanel,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import EpisodesDateProvider from 'components/home/grid/providers/EpisodesDateProvider';
import EpisodeStatusProvider from 'components/home/grid/providers/EpisodeStatusProvider';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import EpisodesInProgressProvider from 'components/home/grid/providers/EpisodesInProgressProvider';
import EpisodesClockProvider from 'components/home/grid/providers/EpisodesClockProvider';
import EpisodesStateProvider from 'components/home/grid/providers/EpisodesStateProvider';
import EpisodesPathwayProvider from 'components/home/grid/providers/EpisodesPathwayProvider';
import {
  IdProvider,
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import PagingContainer from 'components/home/grid/pager';
import React from 'react';
import DuplicateEpisodeNumberProvider from 'components/home/grid/providers/DuplicateEpisodeNumberProvider';
import styled from 'styled-components';
import NextStepProvider from 'components/home/grid/providers/NextStepProvider';
import MozartActionProvider from 'components/home/grid/providers/MozartActionProvider';

interface IEpisodeGridProps {
  tabColumns: Column[];
  tabRows: EpisodeWithMozartInfoViewModel[];
  totalCount: number;
  selectedEpisodeId: number;
  onSelectionChange?: (values: Array<number | string>) => void;
  episodeTabModel?: IEpisodesTabState;
  onSetCurrentPage?: (currentPage: number) => void;
  onSetSorting?: (sorting: Sorting[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columnExtensions: any;
  isFromDuplicateEpisode?: boolean;
  limitGridHeight?: boolean;
}

const StyledGridRoot = styled(Grid.Root)`
  max-height: 240px;
  overflow: auto;
`;

const GridRoot = (props: GridBase.RootProps) => {
  return <StyledGridRoot {...props} />;
};

export const EpisodeGrid = ({
  tabColumns,
  tabRows,
  totalCount,
  selectedEpisodeId,
  onSelectionChange,
  episodeTabModel,
  onSetCurrentPage,
  onSetSorting,
  columnExtensions,
  isFromDuplicateEpisode,
  limitGridHeight,
}: IEpisodeGridProps) => {
  return (
    <TableGrid
      columns={tabColumns}
      rows={tabRows}
      getRowId={(row: Episode) => row.id}
      {...(limitGridHeight ? { rootComponent: GridRoot } : {})}
    >
      <SelectionState
        selection={[selectedEpisodeId]}
        defaultSelection={[0]}
        onSelectionChange={onSelectionChange}
      />
      <IntegratedSelection />
      <EpisodesDateProvider
        for={[
          'createdOn',
          'authorizationStartDate',
          'referralDate',
          'engagedDate',
        ]}
      />
      <EpisodeStatusProvider for={['authStatus']} />
      {!isFromDuplicateEpisode && <IdProvider for={['episodeNumber']} />}
      {isFromDuplicateEpisode && (
        <DuplicateEpisodeNumberProvider for={['episodeNumber']} />
      )}
      <SimpleTextProvider for={['updatedBy', 'type']} />
      <EpisodesPathwayProvider for={['episodePathway']} />
      <EpisodesInProgressProvider for={['anyProgress']} />
      <EpisodesClockProvider for={['tat']} />
      <EpisodesClockProvider for={['counter']} />
      <EpisodesStateProvider for={['state']} />
      <NextStepProvider for={['nextStep']} />
      <MozartActionProvider for={['workFlowInstanceId']} />
      <PagingState
        currentPage={Number(episodeTabModel?.page ?? 0)}
        onCurrentPageChange={onSetCurrentPage}
        pageSize={Number(episodeTabModel?.pageSize ?? 15)}
      />
      <CustomPaging totalCount={totalCount} />
      <SortingState
        sorting={[
          {
            columnName: episodeTabModel?.orderBy ?? 'createdOn',
            direction:
              episodeTabModel?.ascDesc?.toLowerCase() === 'asc'
                ? 'asc'
                : 'desc',
          },
        ]}
        onSortingChange={onSetSorting}
        columnExtensions={columnExtensions}
      />
      {totalCount > 0 && <PagingPanel containerComponent={PagingContainer} />}
      <SymphonyTable columnExtensions={columnExtensions} />
      <SymphonyTableHeaderRow showSortingControls />
      <TableSelection
        selectByRowClick
        highlightRow
        rowComponent={SelectedRow}
        showSelectionColumn={false}
      />
    </TableGrid>
  );
};
