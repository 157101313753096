import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetPatientContactsQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetPatientContactsQuery = {
  __typename?: 'EntitiesQuery';
  getPatientContacts: {
    __typename?: 'PatientContactsViewModel';
    addresses: Array<{
      __typename?: 'AddressDto';
      address1?: string | null;
      address2?: string | null;
      city?: string | null;
      isPrimary: boolean;
      state?: string | null;
      type: Types.AddressType;
      zip?: string | null;
    }>;
    emails: Array<{
      __typename?: 'EmailDto';
      address?: string | null;
      isPrimary: boolean;
      type: string;
    }>;
    language?: {
      __typename?: 'LookupValue';
      id: number;
      image?: string | null;
      isDefault?: boolean | null;
      isDeleted?: boolean | null;
      name?: string | null;
      sortOrder?: number | null;
    } | null;
    phones: Array<{
      __typename?: 'PhoneDto';
      isOk: boolean;
      isPrimary: boolean;
      number?: string | null;
      type: string;
    }>;
    languageBarriers: Array<{
      __typename?: 'LanguageBarrierDto';
      language?: string | null;
      isEnabled?: boolean | null;
    }>;
  };
};

export const GetPatientContactsDocument = `
    query getPatientContacts($patientId: Int!) {
  getPatientContacts(patientId: $patientId) {
    addresses {
      address1
      address2
      city
      isPrimary
      state
      type
      zip
    }
    emails {
      address
      isPrimary
      type
    }
    language {
      id
      image
      isDefault
      isDeleted
      name
      sortOrder
    }
    phones {
      isOk
      isPrimary
      number
      type
    }
    languageBarriers {
      language
      isEnabled
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPatientContacts: build.query<
      GetPatientContactsQuery,
      GetPatientContactsQueryVariables
    >({
      query: (variables) => ({
        document: GetPatientContactsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPatientContactsQuery, useLazyGetPatientContactsQuery } =
  injectedRtkApi;
