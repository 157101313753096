import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { Typography, styled } from '@mui/material';

const StyledBoldTypography = styled(Typography)`
  font-weight: bold;
`;
const totalRowHeading = 'Totals:';
const TotalValueFormatter = (props: DataTypeProvider.ValueFormatterProps) => (
  <>
    <Typography>
      {props.row?.carSiteName == totalRowHeading ? (
        <StyledBoldTypography>{props.value}</StyledBoldTypography>
      ) : (
        props.value
      )}
    </Typography>
  </>
);

const TotalValueProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={TotalValueFormatter} {...props} />
);

export default TotalValueProvider;
