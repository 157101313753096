import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeSet } from '@devexpress/dx-react-grid';
import { PatientCarePlanViewModel } from 'graphql/graphqlTypes';
import { getChangedRows } from 'components/gridFormatters/getChangedRows';

export interface IPatientDetailsCarePlanState {
  episodeTab: { [episodeId: number]: PatientCarePlanViewModel };
}

export const initialPatientDetailsCarePlanState: IPatientDetailsCarePlanState =
  {
    episodeTab: {},
  };

const patientDetailsCarePlanSlice = createSlice({
  name: 'PatientDetailsCarePlanState',
  initialState: initialPatientDetailsCarePlanState,
  reducers: {
    closePatientDetailsModal: () => {
      return initialPatientDetailsCarePlanState;
    },
    storeCarePlan(
      state,
      action: PayloadAction<{
        episodeId: number;
        data: PatientCarePlanViewModel;
      }>
    ) {
      state.episodeTab[action.payload.episodeId] = action.payload.data;
    },
    commitProblems: (
      state,
      action: PayloadAction<{
        patientId: number;
        episodeId: number;
        changeSet: ChangeSet;
      }>
    ) => {
      const { episodeId, changeSet } = action.payload;
      const newRows = getChangedRows(
        changeSet,
        state.episodeTab[episodeId].problems
      );
      state.episodeTab[episodeId].problems = newRows;
    },
  },
});
export const { storeCarePlan, commitProblems, closePatientDetailsModal } =
  patientDetailsCarePlanSlice.actions;

export default patientDetailsCarePlanSlice.reducer;
