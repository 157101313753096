import {
  Filter,
  Sorting,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { IAdvancedSearchCriteria } from 'backend/types/advancedSearchCriteria';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { ISystemValue } from 'backend/types/systemValue';
import { EnumSortDirection, PaRequirement, User } from 'graphql/graphqlTypes';
import { ITab } from 'store/patientdetails/types';

export interface IDataResult {
  rows: Array<unknown>;
  count: number;
}

export interface IRow {
  patientId?: number;
  id: number;
  episodeId?: number;
  CanUpdate: boolean;
  IsMozart: boolean;
  PatientIsActive: boolean;
}

export interface IPARow {
  priorAuthRequirement?: PaRequirement;
  byCodeFound: boolean;
  code: string;
  description?: string;
  id: number;
  isDeleted?: boolean;
  isPARequired: boolean;
  externalURL?: string;
  primaryMessage?: string;
  secondaryMessage?: string;
  notificationMessage?: string;
}

export interface IHomeState {
  homeModel: IHomeStateModel;
  error: string | null;
  cnt: number;
  loading: boolean;
  columns: IColumn[];
  data: IDataResult;
  filterValues: IFilterValue[];
  showProgress: boolean;
  mainModel: IMainModel;
  forceUpdate: boolean | null;
}

export interface IRowRequestSorting {
  columnName: string;
  direction: number;
}

export interface IRowRequest {
  tabId: number;
  isActive?: boolean;
  first: number;
  pageSize: number;
  fullSearch?: string;
  advancedSearchOption?: IAdvancedSearchCriteria | null;
  orderBy: IRowRequestSorting[];
  filterBy: Filter[];
  myListCaseState?: number;
  episodeState: number;
}

export interface IFilterValue {
  name: string;
  values: ILookupValue[];
}

export interface IAttributeType {
  type: string;
}

export interface IEntityAttributeDef {
  attributeType: IAttributeType;
}

export interface IFilterType {
  multiValues: boolean;
}

export interface IColumn {
  fullPropertyName: string;
  displayName: string;
  hasFilter: boolean;
  canSort: boolean;
  filter: IFilterType;
  width: number | undefined;
  sortOrder: number;
  sortDirection: EnumSortDirection;
  entityAttributeDef: IEntityAttributeDef;
}

export interface ITabModel {
  columns: IColumn[];
  filterValues: IFilterValue[];
  episodeState: number;
  showProgress: boolean;
}

export interface ISettings {
  pageSize: number | null;
  showActive: boolean | null;
}

export interface IHomeModel {
  tabs: ITab[];
  activeTab: number;
  settings: ISettings;
}

export const SearchTab = -1;

export const MyListTab = 0;

export interface IHomeStateModel extends IHomeModel {
  fullSearch: string | null;
  advancedSearchOption: IAdvancedSearchCriteria | null;
  selection: (string | number)[] | null;
  columnWidth: TableColumnWidthInfo[] | null;
  episodeState: number | null;
  orderBy: Sorting[] | null;
  page: number | null;
  filterBy: Filter[] | null;
}

export interface IMainModel {
  users: User[];
  lookups: ILookup[];
  enabledPopulations: ILookupValue[];
  glossaryItems: ISystemValue[];
  systemValues: ISystemValue[];
}
