import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type AddPatientProblemsMutationVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
  problemIds:
    | Array<Types.InputMaybe<Types.Scalars['Int']>>
    | Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type AddPatientProblemsMutation = {
  __typename?: 'Mutation';
  addPatientProblems?: boolean | null;
};

export const AddPatientProblemsDocument = `
    mutation addPatientProblems($patientId: Int!, $problemIds: [Int]!) {
  addPatientProblems(patientId: $patientId, problemIds: $problemIds)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    addPatientProblems: build.mutation<
      AddPatientProblemsMutation,
      AddPatientProblemsMutationVariables
    >({
      query: (variables) => ({
        document: AddPatientProblemsDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useAddPatientProblemsMutation } = injectedRtkApi;
