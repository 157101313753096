import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const StyledReviewTypography = styled(Typography)<{
  $color?: string;
}>(({ $color }) => ({
  color: $color ?? COLORS.BLACK,
  fontWeight: 700,
  whiteSpace: 'break-spaces',
}));
