import React, { useEffect, useRef, useState } from 'react';
import { IComponentProps } from '../types';
import { EntityAttributeComponent } from 'graphql/graphqlTypes';
import { ILookup } from 'backend/types/lookup';
import { ILookupValue } from 'backend/types/lookupValue';
import { Radio } from '@mui/material';
import { IRadioSelection } from './LookupAttribute';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import {
  IValidationResult,
  validateOptionIsSelected,
} from 'util/validationUtils';
import { useChecklistValidation } from '../../Items/Orderable';

const StyledVerticalBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid transparent;
  padding: 4px;
  border-radius: 4px;
`;

const StyledFormControlLabel = styled(FormControlLabel)({
  alignSelf: 'flex-start',
  marginRight: 0,
});

export interface ILookupRadiobuttonListProps
  extends IComponentProps<EntityAttributeComponent> {
  lookup?: ILookup;
  selectedValues: ILookupValue[];
  isReadOnly: boolean;
  autoSave: boolean;
  isActionSelected: boolean;
}

export const LookupRadiobuttonList = (props: ILookupRadiobuttonListProps) => {
  const {
    component,
    lookup,
    selectedValues,
    handleChecklistInputChange,
    isReadOnly,
    autoSave,
    isActionSelected,
    categoryId,
    storageType,
  } = props;

  const verticalBoxRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] =
    React.useState<IRadioSelection | null>(() => {
      if (selectedValues.length === 0) {
        return null;
      } else {
        return {
          radioValue: selectedValues[0].id,
          radioId: component.id,
        } as IRadioSelection;
      }
    });

  const [validation, setValidation] = useState<IValidationResult>(
    validateOptionIsSelected(selectedValue, component.required)
  );

  const radioName = `lookup_${component.id}_${lookup?.id}`;
  const handleRadioInputChange = (value: number) => {
    setSelectedValue({
      radioId: component.id,
      radioValue: value,
    });
    handleChecklistInputChange(
      value.toString(),
      {
        ...props.component,
        groupId: null,
        type: 'string',
      },
      component.id
    );
  };

  const handleValidation = () => {
    setValidation(validateOptionIsSelected(selectedValue, component.required));
  };

  useEffect(() => {
    if (isActionSelected) {
      handleValidation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, isActionSelected]);

  useEffect(() => {
    if (validation.hasError && isActionSelected && verticalBoxRef.current) {
      verticalBoxRef.current.style.border = '1px solid red';
    } else if (verticalBoxRef.current) {
      verticalBoxRef.current.style.border = '1px solid transparent';
    }
  }, [validation, isActionSelected]);

  useChecklistValidation(
    validation.hasError && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID,
    categoryId,
    component.userDefinedId || component.componentType,
    validation.message ?? 'Please select an option.',
    storageType
  );

  return (
    <StyledVerticalBox
      data-testid="LookupRadiobuttonListTestId"
      ref={verticalBoxRef}
    >
      {lookup?.values.map((lv: ILookupValue, lvIndex: number) => (
        <StyledFormControlLabel
          key={lvIndex}
          value={lv.id}
          control={
            <Radio
              color="primary"
              disabled={isReadOnly}
              checked={selectedValue?.radioValue === lv.id}
              name={radioName}
              value={lv.id}
              onChange={() => handleRadioInputChange(lv.id)}
            />
          }
          label={lv.name}
          labelPlacement="end"
          color={component.color ? component.color : undefined}
        />
      ))}
    </StyledVerticalBox>
  );
};
