import { openPatientDetailsModal } from 'store/patientdetails/patientDetailsSlice';
import { ICONS } from 'components/icon';
import PatientDetailsDialog from 'components/home/patientdetails';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { QuickNoteType } from 'graphql/graphqlTypes';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import { openQuickNote } from 'store/ui/modals/quickNote';
import Loader from 'components/loader';
import { useNewEpisodeCheck } from 'components/scenario/episode/hooks';
import { launchCoachEducation } from 'features/education/coach';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { useLazyGetEpisodeTypeQuery } from 'graphql/hooks/getEpisodeType';
import { getEpisodeTabName } from 'components/home/grid/formatters/EpisodeNumberFormatter';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import ActionButton from '../ActionButton';

export interface IPatientActionButtonsParams {
  patientId: number;
  editingChecklistId?: string;
  hideQuickNote?: boolean;
  episodeId?: number;
  variant: string;
}

const StyledButtonsWrapper = styled.div<{
  $variant?: string;
}>`
  padding: 8px 24px 20px;
  margin-top: ${(props) => (props.$variant === 'large' ? 'auto' : '0')};
  border-top: ${(props) =>
    props.$variant === 'large' ? `1px solid ${COLORS.GREY25}` : 'none'};
`;

const StyledButtonsInnerWrapper = styled.div`
  display: flex;
  margin-top: 2px;
`;
const StyledButtonWrapper = styled.div`
  padding: 0 7px;
`;

const StyledPatientButtonWrapper = styled.div`
  padding-right: 7px;
`;

const PatientActionButtons = ({
  hideQuickNote,
  patientId,
  editingChecklistId,
  episodeId,
  variant,
}: IPatientActionButtonsParams) => {
  const dispatch = useDispatch();

  const isPatientDetailsOpen = useSelector(
    (state: IState) => state.patientDetails.isOpened
  );

  const hideEducate = !hasUserPrivilege(Privilege.Education);
  const canAddMemberEpisodeNotes = hasUserPrivilege(
    Privilege.AddMemberEpisodeNotes
  );
  const handleClickEducation = async () => {
    await launchCoachEducation(
      patientId,
      () => void 0,
      (err) => {
        if (err) {
          dispatch(showErrorPopup({ message: err }));
        }
      }
    );
  };

  const {
    checkNewEpisode,
    checkNewEpisodeIsFetching,
    confirmation: newEpisodeConfirmation,
  } = useNewEpisodeCheck();

  const [
    getEpisodeType,
    {
      data: episodeTypeData,
      isFetching: episodeTypeIsFetching,
      isSuccess: episodeTypeIsSuccess,
      requestId: episodeTypeRequestId,
    },
  ] = useLazyGetEpisodeTypeQuery();

  useEffect(() => {
    if (episodeTypeIsSuccess) {
      dispatch(
        openPatientDetailsModal({
          patientId: Number(patientId),
          episodeId: episodeId ?? 0,
          editingChecklistId: editingChecklistId,
          activeTabName: getEpisodeTabName(
            episodeTypeData?.getEpisode?.type?.name ?? ''
          ),
          activeSubTabName: 'Attachments',
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodeTypeIsSuccess, episodeTypeRequestId]);

  const onNewScenario = (newPatientId: number, newEpisodeId: number) => {
    checkNewEpisode(newPatientId, Number(newEpisodeId));
  };

  const handleOpenQuickNoteDialog = () => {
    if (!canAddMemberEpisodeNotes) {
      return;
    }
    dispatch(
      openQuickNote({
        patientId: patientId,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: null,
        type: QuickNoteType.Episode,
      })
    );
  };

  const handleOpenAttachments = () => {
    if (!episodeId) {
      dispatch(
        openPatientDetailsModal({
          patientId: Number(patientId),
          episodeId: 0,
          editingChecklistId: editingChecklistId,
          activeTabName: 'Attachments',
          activeSubTabName: '',
        })
      );
    } else {
      getEpisodeType({ episodeId });
    }
  };

  return (
    <StyledButtonsWrapper $variant={variant}>
      <StyledButtonsInnerWrapper>
        <StyledPatientButtonWrapper>
          <ActionButton
            handleClick={() =>
              dispatch(
                openPatientDetailsModal({
                  patientId: Number(patientId),
                  episodeId: 0,
                  editingChecklistId: editingChecklistId,
                  activeTabName: 'Background',
                  activeSubTabName: '',
                })
              )
            }
            icon={ICONS.Patient_Details}
            testId="patient-icon"
            text="Details"
            tooltipText="Details"
          />
        </StyledPatientButtonWrapper>
        <StyledButtonWrapper>
          <ActionButton
            handleClick={handleOpenAttachments}
            icon={ICONS.AttachmentClip}
            testId="attachment-icon"
            text="Attachments"
            tooltipText="Attachments"
          />
        </StyledButtonWrapper>
        {!hideQuickNote && canAddMemberEpisodeNotes ? (
          <StyledButtonWrapper>
            <ActionButton
              handleClick={handleOpenQuickNoteDialog}
              icon={ICONS.Episode_Note}
              testId="quick-icon"
              text="Note"
              disabled={!canAddMemberEpisodeNotes}
              tooltipText="Note"
            />
          </StyledButtonWrapper>
        ) : null}
        {!hideEducate ? (
          <StyledButtonWrapper>
            <ActionButton
              handleClick={handleClickEducation}
              icon={ICONS.Educate}
              testId="educate"
              text="Education"
              tooltipText="Education/Healthwise"
            />
          </StyledButtonWrapper>
        ) : null}
      </StyledButtonsInnerWrapper>
      {isPatientDetailsOpen ? (
        <PatientDetailsDialog
          open={isPatientDetailsOpen}
          onClose={() => closePatientDetailsDialog(dispatch)}
          onNew={onNewScenario}
        />
      ) : null}
      {newEpisodeConfirmation}
      <Loader active={checkNewEpisodeIsFetching || episodeTypeIsFetching} />
    </StyledButtonsWrapper>
  );
};

export default PatientActionButtons;
