import React from 'react';
import {
  Box,
  Button,
  IconButton,
  Link,
  styled,
  Typography,
} from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const grayBorder = `1px solid ${COLORS.GREY25}`;
const padding = { padding: '24px' };

const ListBox = styled(Box)({
  minWidth: '600px',
  maxWidth: '600px',
  display: 'flex',
  flexDirection: 'column',
});

const ListBoxHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: grayBorder,
  ...padding,
});

const ListBoxFooter = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderTop: grayBorder,
  ...padding,
});

const DoneButton = styled(Button)({
  width: '150px',
  height: '40px',
  alignSelf: 'center',
});

const LinkContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
});

const CloseButton = (props: { onClick: () => void }) => (
  <IconButton style={{ padding: 0 }} {...props} size="large">
    <Icon icon={ICONS.Close} size={23} />
  </IconButton>
);

export interface IRightPanelProps {
  title: string;
  complete: string;
  canComplete: boolean;
  onComplete: () => void;
  onClose: () => void;
  children: JSX.Element[] | JSX.Element;
  back?: string;
  onBack?: () => void;
}

export const RightPanel = ({
  title,
  complete,
  canComplete,
  onComplete,
  onClose,
  children,
  back,
  onBack,
}: IRightPanelProps) => (
  <ListBox>
    <ListBoxHeader>
      <Typography variant="subtitle1">{title}</Typography>
      <CloseButton onClick={onClose} />
    </ListBoxHeader>
    {children}
    <ListBoxFooter>
      <LinkContainer onClick={onBack}>
        {back && (
          <>
            <Icon
              icon={ICONS.Back}
              color={COLORS.SYMPHONY_BLUE}
              size={14}
              style={{ marginTop: '-1px', marginRight: '6px' }}
            />
            <Link>{back}</Link>
          </>
        )}
      </LinkContainer>
      <DoneButton
        data-testid="letters-done-button"
        disabled={!canComplete}
        onClick={onComplete ?? onClose}
        color="primary"
        size="large"
        variant="contained"
        data-cy={`letter-dialog-${complete}`}
      >
        <Typography variant="body1" color="secondary">
          {complete}
        </Typography>
      </DoneButton>
    </ListBoxFooter>
  </ListBox>
);
