import React, { useEffect, useState } from 'react';
import { ChecklistStorageType } from 'store/actions/types';
import { IState } from 'store';
import {
  ChecklistActionDataInput,
  ChecklistProblemViewModel,
} from 'graphql/graphqlTypes';
import { useDispatch, useSelector } from 'react-redux';
import SectionPanel from 'components/panel/sectionPanel';
import { usePrevious } from 'hooks';
import CarePlanSection from 'components/actions/sections/CarePlanSection';
import SectionsBody from 'components/actions/sections/SectionsBody';
import { setActiveSectionIndex } from 'store/actions/checklistSlice';
import styled from 'styled-components';
import { Divider } from '@mui/material';

export interface ISectionsProps {
  storageType: ChecklistStorageType;
  autoSave: boolean;
  testId?: string;
  inputData?: ChecklistActionDataInput[];
  setInputData?: (data: ChecklistActionDataInput[]) => void;
  hideNumbers?: boolean;
  disableSelection?: boolean;
  isMozartFlow?: boolean;
}
const StyledAnchor = styled.div`
  position: absolute;
  top: -120px;
  left: 0;
`;

const StyledDivider = styled(Divider)`
  margin-top: 24px;
`;

const Sections = (props: ISectionsProps) => {
  const {
    storageType,
    testId,
    inputData,
    setInputData,
    hideNumbers,
    disableSelection,
  } = props;

  const checklist = useSelector(
    (state: IState) => state.checklist.documentsState[storageType]?.checklist
  );
  const dispatch = useDispatch();
  const carePlanIndex = checklist?.categories.length ?? 0;

  const problems = useSelector(
    (state: IState) =>
      state.checklist.documentsState[ChecklistStorageType.CAREPLAN]?.problems
  );

  const [carePlanProblems, updateCarePlanProblems] = useState<
    ChecklistProblemViewModel[]
  >(problems ?? []);

  const previousCarePlanProblems = usePrevious(carePlanProblems);

  useEffect(() => {
    if (!problems && !previousCarePlanProblems) {
      return;
    }

    if (problems !== previousCarePlanProblems) {
      updateCarePlanProblems(problems ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [problems]);

  useEffect(() => {
    if (checklist) {
      dispatch(
        setActiveSectionIndex({
          storageType,
          activeSection: {
            id: 1,
            categoryId: checklist?.categories[0]?.id,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let categoryIndex = 0;

  return (
    <div data-testid={testId} style={{ position: 'relative' }}>
      <StyledAnchor id={`section-${checklist?.id}`} />
      {storageType === ChecklistStorageType.MOZART ? (
        <SectionPanel
          padding={'0 0 20px 0'}
          storageType={storageType}
          sectionIndex={1}
        >
          {checklist?.categories.map((category, index) => {
            if (category.isVisible) {
              categoryIndex++;
            }
            return (
              category.isVisible && (
                <>
                  <SectionsBody
                    key={categoryIndex}
                    storageType={storageType}
                    autoSave={props.autoSave}
                    category={category}
                    setInputData={setInputData}
                    inputData={inputData}
                    carePlanProblems={carePlanProblems}
                    updateCarePlanProblems={updateCarePlanProblems}
                    categoryIndex={categoryIndex}
                    hideNumbers={hideNumbers}
                    patientId={checklist.patientId}
                  />
                  {checklist.categories.filter((item) => item.isVisible)
                    .length !==
                  index + 1 ? (
                    <StyledDivider />
                  ) : null}
                </>
              )
            );
          })}
        </SectionPanel>
      ) : (
        checklist?.categories.map((category) => {
          if (category.isVisible) {
            categoryIndex++;
          }
          return (
            category.isVisible && (
              <>
                <SectionPanel
                  padding={
                    storageType !== ChecklistStorageType.CAREPLAN
                      ? '0px 0px 24px 0px'
                      : '0'
                  }
                  key={categoryIndex}
                  storageType={storageType}
                  sectionIndex={!disableSelection ? categoryIndex : -1}
                  hideBorder={storageType === ChecklistStorageType.CAREPLAN}
                >
                  <SectionsBody
                    storageType={storageType}
                    autoSave={props.autoSave}
                    category={category}
                    patientId={checklist.patientId}
                    setInputData={setInputData}
                    inputData={inputData}
                    carePlanProblems={carePlanProblems}
                    updateCarePlanProblems={updateCarePlanProblems}
                    categoryIndex={categoryIndex}
                    hideNumbers={hideNumbers}
                  />
                </SectionPanel>
              </>
            )
          );
        })
      )}
      {checklist?.includeCarePlan ? (
        <CarePlanSection
          carePlanProblems={carePlanProblems}
          updateCarePlanProblems={updateCarePlanProblems}
          hideNumbers={hideNumbers}
          categoryIndex={categoryIndex}
          carePlanIndex={carePlanIndex}
          storageType={storageType}
        />
      ) : null}
    </div>
  );
};

export default Sections;
