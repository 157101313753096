import { ISelectItem } from 'components/select';
import {
  AssociatedEpisodeActionValue,
  Episode,
  Provider,
} from 'graphql/graphqlTypes';
import { formatDate } from 'util/helpers/dateTimeHelpers';

export enum AssociatedEpisodeAttributeNames {
  episodeNumber = 'episodeNumber',
  episodeId = 'episodeId',
  determinationDateTime = 'determinationDateTime',
  receivedDateTime = 'receivedDateTime',
  episodeDateTime = 'episodeDateTime',
  serviceCode = 'serviceCode',
  diagnosisCode = 'diagnosisCode',
  provider = 'provider',
  pathway = 'Pathway',
  authorizationStatus = 'authorizationStatus',
  nPI = 'nPI',
  patientId = 'patientId',
  parStatus = 'parStatus',
  decisionBy = 'DecisionBy',
  owner = 'owner',
  requestingClinicianPar = 'RequestingClinicianPar',
  authLookup = 'AuthorizedStatus',
}

export const associatedEpisodeAttributeHelper = [
  {
    name: AssociatedEpisodeAttributeNames.episodeNumber,
    value: AssociatedEpisodeAttributeNames.episodeNumber,
  },
  {
    name: AssociatedEpisodeAttributeNames.receivedDateTime,
    value: AssociatedEpisodeAttributeNames.receivedDateTime,
  },
  {
    name: AssociatedEpisodeAttributeNames.pathway,
    value: AssociatedEpisodeAttributeNames.pathway,
  },
  {
    name: AssociatedEpisodeAttributeNames.serviceCode,
    value: AssociatedEpisodeAttributeNames.serviceCode,
  },
  {
    name: AssociatedEpisodeAttributeNames.diagnosisCode,
    value: AssociatedEpisodeAttributeNames.diagnosisCode,
  },
  {
    name: AssociatedEpisodeAttributeNames.determinationDateTime,
    value: AssociatedEpisodeAttributeNames.determinationDateTime,
  },
  {
    name: AssociatedEpisodeAttributeNames.authorizationStatus,
    value: AssociatedEpisodeAttributeNames.authorizationStatus,
  },
  {
    name: AssociatedEpisodeAttributeNames.provider,
    value: AssociatedEpisodeAttributeNames.provider,
  },
  {
    name: AssociatedEpisodeAttributeNames.nPI,
    value: AssociatedEpisodeAttributeNames.nPI,
  },
  {
    name: AssociatedEpisodeAttributeNames.parStatus,
    value: AssociatedEpisodeAttributeNames.parStatus,
  },
  {
    name: AssociatedEpisodeAttributeNames.decisionBy,
    value: AssociatedEpisodeAttributeNames.decisionBy,
  },
  {
    name: AssociatedEpisodeAttributeNames.owner,
    value: AssociatedEpisodeAttributeNames.owner,
  },
  {
    name: AssociatedEpisodeAttributeNames.episodeDateTime,
    value: AssociatedEpisodeAttributeNames.episodeDateTime,
  },
];

export const getProviderDetails = (provider?: Provider | null) => {
  return provider
    ? `${provider.firstName ?? ''} ${provider.lastName ?? ''} ${
        provider.providerId ?? ''
      }`
    : '';
};

const getReadOnlyEpisodeValue = (
  actionValue: unknown,
  episode: unknown,
  isReadOnly: boolean
) => {
  return isReadOnly ? actionValue : episode;
};
export const getEpisodeValue = (
  actionValue: AssociatedEpisodeActionValue,
  attributeName: string,
  episode: Episode,
  isReadOnly: boolean,
  userLookup: ISelectItem[],
  authLookup: ISelectItem[],
  pathWayLookup: ISelectItem[]
) => {
  const owner = isReadOnly
    ? userLookup.find((x) => x.id == actionValue.ownerId?.toString())?.name
    : episode?.owner?.fullName;

  const decisionBy = isReadOnly
    ? userLookup.find((x) => x.id == actionValue.decisionById?.toString())?.name
    : episode?.entityAttributes
        ?.filter((x) => x?.name == AssociatedEpisodeAttributeNames.decisionBy)
        ?.map((x) => x?.value);

  const reqParValue = actionValue.requestingClinicianPar ? 'True' : 'False';
  const parStatus = isReadOnly
    ? reqParValue
    : episode?.entityAttributes
        ?.filter(
          (x) =>
            x?.name == AssociatedEpisodeAttributeNames.requestingClinicianPar
        )
        ?.map((x) => x?.value);

  const provider = actionValue.provider;
  const authStatus = isReadOnly
    ? authLookup.find(
        (x) => x.id == actionValue.authorizationStatus?.toString()
      )?.name ?? episode?.authorizationStatus?.name
    : episode?.authorizationStatus?.name;

  const pathWay = isReadOnly
    ? pathWayLookup.find((x) => x.id == actionValue.episodePathway?.toString())
        ?.name
    : episode?.entityAttributes
        ?.filter((x) => x?.name == AssociatedEpisodeAttributeNames.pathway)
        ?.map((x) => x?.value);

  switch (attributeName) {
    case AssociatedEpisodeAttributeNames.episodeId:
      return actionValue.id;
    case AssociatedEpisodeAttributeNames.episodeDateTime:
      return formatDate(actionValue.episodeDateTime);
    case AssociatedEpisodeAttributeNames.determinationDateTime:
      return formatDate(
        getReadOnlyEpisodeValue(
          actionValue.determinationDateTime,
          episode?.determinationDate,
          isReadOnly
        ) as Date
      );
    case AssociatedEpisodeAttributeNames.receivedDateTime:
      return formatDate(
        getReadOnlyEpisodeValue(
          actionValue.receivedDateTime,
          episode?.faxDateTime,
          isReadOnly
        ) as Date
      );
    case AssociatedEpisodeAttributeNames.owner:
      return owner;
    case AssociatedEpisodeAttributeNames.parStatus:
      return parStatus;
    case AssociatedEpisodeAttributeNames.pathway:
      return pathWay;
    case AssociatedEpisodeAttributeNames.decisionBy:
      return decisionBy;
    case AssociatedEpisodeAttributeNames.episodeNumber:
      return actionValue.episodeId;
    case AssociatedEpisodeAttributeNames.provider:
      return isReadOnly
        ? getProviderDetails(provider)
        : episode?.requestingClinician?.name;
    case AssociatedEpisodeAttributeNames.serviceCode:
      return actionValue.procedureCode;
    case AssociatedEpisodeAttributeNames.diagnosisCode:
      return actionValue.diagnosisCode;
    case AssociatedEpisodeAttributeNames.authorizationStatus:
      return authStatus;
    case AssociatedEpisodeAttributeNames.nPI:
      return isReadOnly ? provider?.nPI : episode?.requestingClinician?.nPI;
    default:
      return '';
  }
};
