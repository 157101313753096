import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Grid as TableGrid,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import { Episode } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import SearchBar from 'components/searchbar/SearchBar';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import EpisodesDateProvider from 'components/home/grid/providers/EpisodesDateProvider';

export interface IEpisodeSearchDialogModel {
  isFetching: boolean;
  episodes: Episode[];
  onSelect: (id: number) => void;
  setSearchTerm: (term: string) => void;
}

const EpisodeSearchDialogBody = (props: IEpisodeSearchDialogModel) => {
  const { episodes, isFetching, onSelect, setSearchTerm } = props;
  const [selectedId, setSelectedId] = useState<number>(0);
  const columns = [
    { name: 'episodeNumber', title: 'Episode Id' },
    { name: 'createdOn', title: 'Episode Date' },
    {
      name: 'diagnosisCode',
      title: 'Diagnosis',
      getCellValue: (row: Episode) =>
        row?.diagnosisCodesAll?.map((x) => x?.code)?.join(', '),
    },
    {
      name: 'procedureCode',
      title: 'Procedure',
      getCellValue: (row: Episode) =>
        row?.procedureCodesAll?.map((x) => x?.code)?.join(', '),
    },
    {
      name: 'provider',
      title: 'Provider Name',
      getCellValue: (row: Episode) => row?.requestingClinician?.name,
    },
  ];
  const columnsExtension = [
    { columnName: 'episodeNumber' },
    { columnName: 'createdOn' },
  ];

  const onSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const selectedEpisodeId = Number(values[1]);
      setSelectedId(selectedEpisodeId);
      onSelect(selectedEpisodeId);
    }
  };

  return (
    <div data-cy="Episode-search-dialog-body">
      <Box style={{ backgroundColor: COLORS.PALE_GREY, height: '100%' }}>
        <div
          style={{
            height: '100%',
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            justifyContent: 'space-between',
          }}
        >
          <Paper
            elevation={0}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={5}>
                <SearchBar
                  placeholder="Search by Episode ID (at least 3 chars)"
                  onSubmit={setSearchTerm}
                  style={{ width: '300px' }}
                  containerStyle={{ marginBottom: '20px' }}
                />
              </Grid>
            </Grid>
            <Box>
              <TableGrid
                rows={episodes}
                columns={columns}
                getRowId={(row: Episode) => row.id}
              >
                <SelectionState
                  selection={[selectedId]}
                  defaultSelection={[]}
                  onSelectionChange={onSelectionChange}
                />
                <IntegratedSelection />
                <EpisodesDateProvider for={['EpisodeDate']} />
                <SimpleTextProvider for={['EpisodeId']} />
                <SymphonyTable columnExtensions={columnsExtension} />
                <SymphonyTableHeaderRow />
                <TableSelection
                  selectByRowClick
                  highlightRow
                  rowComponent={SelectedRow}
                  showSelectionColumn={false}
                />
              </TableGrid>
            </Box>
          </Paper>
        </div>
      </Box>
      <Loader active={isFetching} />
    </div>
  );
};

export default EpisodeSearchDialogBody;
