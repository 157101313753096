import { base64ToByteArray } from './base64Helper';

export const openBase64File = (content: string, type: string, name: string) => {
  const file = new Blob([base64ToByteArray(content)], { type });
  const url = URL.createObjectURL(file);

  const fileWindow = window.open(url);
  if (fileWindow) {
    fileWindow.document.title = name;
    fileWindow.location.href = url;
  }
};
