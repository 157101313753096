import {
  Column,
  IntegratedSelection,
  SelectionState,
} from '@devexpress/dx-react-grid';
import React from 'react';
import { Grid, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { SelectedRow, SymphonyTable } from 'components/gridFormatters';
import { Problem } from 'graphql/graphqlTypes';
import { addProblemsColumnsExtensionStyles } from 'components/actions/sections/SectionBody/CarePlan/problems';
import SelectCell from 'components/home/grid/selectors/SelectCell';
import HeaderSelectCell from 'components/home/grid/selectors/HeaderSelectCell';

interface IGridProps<T> {
  getRowId: (item: T) => number;
  rows: T[];
  columns: Column[];
  selectedIds: number[];
  updateSelectedIds: (items: number[]) => void;
}

const ProblemsGrid = <T extends Problem>(props: IGridProps<T>) => {
  const { getRowId, rows, columns, selectedIds, updateSelectedIds } = props;

  const handleSetSelection = (selection: (string | number)[]) => {
    const indices = selection.map((i: string | number) => Number(i));
    updateSelectedIds(indices);
  };

  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <SelectionState
        selection={selectedIds}
        onSelectionChange={handleSetSelection}
      />
      <IntegratedSelection />
      <SymphonyTable columnExtensions={addProblemsColumnsExtensionStyles} />
      <TableSelection
        cellComponent={SelectCell}
        headerCellComponent={HeaderSelectCell}
        rowComponent={SelectedRow}
        highlightRow
      />
    </Grid>
  );
};

export default ProblemsGrid;
