import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { QuickNoteType } from 'graphql/graphqlTypes';
import { openLetters } from 'store/ui/modals/letters';
import { openQuickNote } from 'store/ui/modals/quickNote';
import { launchCoachEducation } from 'features/education/coach';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { Privilege } from 'store/roles/types';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import ExportToggleButton from 'components/home/grid/ExportToggleButton';
import IGridHeaderModel from './GridHeaderModel';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

const HeaderPanelActionButtons = (props: IGridHeaderModel) => {
  const {
    onMultiEdit,
    patientSelected,
    isEditDisabled,
    patientId,
    episodeId,
    isFaxRow,
    handleTriggerExportGridData,
  } = props;
  const dispatch = useDispatch();
  const canEditSelected = hasUserPrivilege(Privilege.EditSelected);
  const canAddLetters = hasUserPrivilege(Privilege.AddLetters);
  const canAddNote = hasUserPrivilege(Privilege.AddMemberEpisodeNotes);
  const canEducate = hasUserPrivilege(Privilege.Education);
  const canExportGridData = hasUserPrivilege(Privilege.ExportHomeScreen);

  const showDivider =
    canEditSelected || canAddLetters || canAddNote || canEducate;

  const actionsDisabled = !patientSelected || isFaxRow;
  const lettersDisabled = actionsDisabled || episodeId <= 0;

  const handleClickOpenQuickDialog = (type: QuickNoteType) => {
    dispatch(
      openQuickNote({
        patientId: patientId,
        type: type,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: null,
      })
    );
  };

  const handleCoachClick = async () => {
    await launchCoachEducation(
      patientId,
      () => void 0,
      (err) => {
        if (err) {
          dispatch(showErrorPopup({ message: err }));
        }
      }
    );
  };

  const getIconColor = (status: boolean) =>
    status ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE;

  return (
    <>
      {showDivider && (
        <StyledDivider orientation="vertical" data-testid="panel-divider" />
      )}

      <div
        style={{ display: 'flex', marginRight: '-10px', marginLeft: '-10px' }}
      >
        {canAddNote && (
          <CustomTooltip title="Episode note">
            <span>
              <IconButton
                aria-label="episode note"
                data-testid="episode-note"
                disabled={actionsDisabled}
                onClick={() =>
                  handleClickOpenQuickDialog(QuickNoteType.Episode)
                }
                size="large"
              >
                <Icon
                  icon={ICONS.Episode_Note}
                  size={24}
                  color={getIconColor(actionsDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canAddNote && (
          <CustomTooltip title="Member note">
            <span>
              <IconButton
                aria-label="member note"
                data-testid="member-note"
                disabled={actionsDisabled}
                onClick={() =>
                  handleClickOpenQuickDialog(QuickNoteType.Patient)
                }
                size="large"
              >
                <Icon
                  icon={ICONS.Member_Note}
                  size="24"
                  color={getIconColor(actionsDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canAddLetters ? (
          <CustomTooltip title="Letters">
            <span>
              <IconButton
                aria-label="letters"
                data-testid="letter-button"
                disabled={lettersDisabled}
                onClick={() => dispatch(openLetters({ episodeId, patientId }))}
                size="large"
              >
                <Icon
                  icon={ICONS.Letters}
                  size="24"
                  color={getIconColor(lettersDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        ) : null}
        {canEditSelected ? (
          <CustomTooltip title="Edit selection">
            <span>
              <IconButton
                aria-label="edit selected"
                onClick={() => onMultiEdit()}
                data-cy="edit-selected"
                disabled={isEditDisabled}
                data-testid="multi-edit-button"
                size="large"
              >
                <Icon
                  icon={ICONS.Edit}
                  size="24"
                  color={getIconColor(isEditDisabled)}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        ) : null}
        {canEducate && (
          <CustomTooltip title="Education/Healthwise">
            <span>
              <IconButton
                aria-label="educate"
                onClick={handleCoachClick}
                size="large"
              >
                <Icon
                  icon={ICONS.Educate}
                  size="24"
                  color={COLORS.SYMPHONY_BLUE}
                />
              </IconButton>
            </span>
          </CustomTooltip>
        )}
        {canExportGridData ? (
          <ExportToggleButton
            handleTriggerExportGridData={handleTriggerExportGridData}
            buttonMessage="Export Data"
          />
        ) : null}
      </div>
    </>
  );
};

export default HeaderPanelActionButtons;
