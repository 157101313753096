import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { ActionButton } from 'features/letters/list';
import { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { useDispatch } from 'react-redux';
import { openChecklistFax } from 'store/ui/modals/letters';
import { openChecklist } from 'store/ui/modals/checklist';
import { openBase64File } from 'util/helpers/fileHelpers';
import { useLazyGeneratePdfQuery } from 'graphql/hooks/generatePdf';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';

export interface ICarePlanHeaderActionsProps {
  lastUpdateBy?: string | null;
  updatedOn?: string | null;
  checklistId: number;
  patientId: number;
  episodeId: number;
  disableActions: boolean;
  setLoading: (loading: boolean) => void;
}

export const CarePlanHeaderActions = ({
  lastUpdateBy,
  updatedOn,
  checklistId,
  patientId,
  episodeId,
  setLoading,
  disableActions,
}: ICarePlanHeaderActionsProps) => {
  const dispatch = useDispatch();
  const [generatePdf, { data, isFetching, isSuccess }] =
    useLazyGeneratePdfQuery();

  useEffect(() => {
    if (!isFetching) {
      setLoading(false);
    }
    if (isSuccess && data) {
      openBase64File(
        data.generatePdf?.content ?? '',
        data.generatePdf?.mimeType ?? '',
        data.generatePdf?.fileName ?? ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, isSuccess, data]);

  const handlePrint = () => {
    setLoading(true);
    generatePdf({ checklistId, schemaId: 0 });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Typography variant="subtitle1">Care Plan</Typography>
        {!!checklistId && (
          <div style={{ display: 'flex' }}>
            <Typography
              variant="h6"
              style={{ color: COLORS.GREY100, marginBottom: '4px' }}
            >
              Last update by {lastUpdateBy} on&nbsp;
            </Typography>
            <Typography variant="h5" style={{ color: COLORS.GREY100 }}>
              {updatedOn ? formatDateTime(updatedOn) : null}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <ActionButton
          icon={ICONS.Eye}
          text="View"
          active={!disableActions}
          onClick={() => dispatch(openChecklist({ checklistId }))}
        />
        <ActionButton
          icon={ICONS.Print}
          text="Print"
          active={!disableActions}
          onClick={handlePrint}
        />
        <ActionButton
          icon={ICONS.FaxOutline}
          text="Fax"
          active={!disableActions}
          onClick={() =>
            dispatch(openChecklistFax({ checklistId, patientId, episodeId }))
          }
        />
      </div>
    </div>
  );
};
