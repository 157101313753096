import React from 'react';
import { Typography, Grid } from '@mui/material';
import { IChecklistQualityMeasure } from 'backend/types/checklist';
import { Line } from './Line';
import { COLORS } from 'consts/styles';

import {
  AttributeType,
  ChecklistItemOrderable,
  ComponentsLine,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import { formatDateTime } from 'util/helpers/dateTimeHelpers';

const StyledGridLineRow = styled(Grid)<{ $isMozartOrView: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: ${(props) => (props.$isMozartOrView ? ' 0 42px' : '0 35px')};
  line-height: 24px;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export interface IChecklistItemOrderableProps {
  item: ChecklistItemOrderable;
  sectionItemId: string;
  qualityMeasure?: IChecklistQualityMeasure;
  categoryId?: string;
  bigTableValues: IEntityLookup[];
  storageType: ChecklistStorageType;
  checklistId?: string;
  entityLookups: IEntityLookup[];
  attributeTypes: AttributeType[];
  lookups: ILookup[];
}

const Orderable = (props: IChecklistItemOrderableProps) => {
  const { item, storageType } = props;

  const lines = !(item.repeaterOptions?.canRepeat && !item.hasRepeater)
    ? item.lines?.filter((line: ComponentsLine) => line.components.length > 0)
    : [];

  if (lines.length === 0) {
    return null;
  }

  const isMozartOrView =
    storageType === ChecklistStorageType.MOZART ||
    storageType === ChecklistStorageType.VIEW;

  const renderLines = () => {
    return lines.map((line: ComponentsLine, lineIndex: number) => (
      <Line
        key={lineIndex + 1}
        data={line}
        isItemSelected={item.isSelected || item.isVisible}
        index={lineIndex}
        {...props}
      />
    ));
  };
  formatDateTime(item.createdOn);
  const formattedDateTime = `${
    item.createdOn ? formatDateTime(item.createdOn) : ''
  }, ${item.createdBy}`;

  const renderRepeaterDescription = () => {
    return item.repeaterOptions?.showDescription ? (
      <Grid xs={12} container justifyContent="flex-end">
        <Typography variant="body1" style={{ color: COLORS.GREY100 }}>
          {formattedDateTime}
        </Typography>
      </Grid>
    ) : null;
  };

  return (
    <div>
      <Grid container>
        <StyledGridLineRow item xs={12} $isMozartOrView={isMozartOrView}>
          <StyledWrapper>
            {item.repeaterOptions?.canRepeat && item.hasRepeater ? (
              <Grid container xs={12}>
                <Grid item justifyContent="flex-start" xs={12}>
                  <Grid xs={12} container justifyContent="flex-start">
                    {renderLines()}
                  </Grid>
                  {renderRepeaterDescription()}
                </Grid>
              </Grid>
            ) : (
              <>{renderLines()}</>
            )}
          </StyledWrapper>
        </StyledGridLineRow>
      </Grid>
    </div>
  );
};

export default Orderable;
