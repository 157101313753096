import { COLORS } from 'consts/styles';

export enum dashBoardType {
  umRnTatDashboard = 'UM RN TAT Dashboard',
  uMMdDashboard = 'UM MD Dashboard',
}

export enum dashBoardGridPriority {
  expedited = 'Expedited',
  standard = 'Standard',
}

export enum dashBoardAuthorization {
  all = 'All',
  myOwned = 'My Owned',
}

export enum dashBoardDataColumnNames {
  lessThanZero = 'lessThanZero',
  lessThanTwo = 'lessThanTwo',
  lessThanThree = 'lessThanThree',
  greaterThanThree = 'greaterThanThree',
  mDReviewCount = 'mDReviewCount',
  mDP2PReviewCount = 'mDP2PReviewCount',
  cSRMDCount = 'cSRMDCount',
  mDAppealReviewCount = 'mDAppealReviewCount',
  careSiteName = 'careSiteName',
}

export const mdDashBoardGridColumns = [
  {
    name: dashBoardDataColumnNames.careSiteName,
    title: 'Care Site',
  },
  {
    name: dashBoardDataColumnNames.mDReviewCount,
    title: 'MD Review',
  },
  {
    name: dashBoardDataColumnNames.mDP2PReviewCount,
    title: 'MD P2P Review',
  },
  {
    name: dashBoardDataColumnNames.mDAppealReviewCount,
    title: 'MD Appeal Review',
  },
  {
    name: dashBoardDataColumnNames.cSRMDCount,
    title: 'CSR MD',
  },
];

export const dashBoardGridColumns = [
  {
    name: dashBoardDataColumnNames.careSiteName,
    title: 'Care Site',
  },
  {
    name: dashBoardDataColumnNames.lessThanZero,
    title: '< Zero',
  },
  {
    name: dashBoardDataColumnNames.lessThanTwo,
    title: '1 Day',
  },
  {
    name: dashBoardDataColumnNames.lessThanThree,
    title: '2 Days',
  },
  {
    name: dashBoardDataColumnNames.greaterThanThree,
    title: '3+ Days',
  },
];

export const dashBoardDataColumnsExtensionStyles = [
  {
    columnName: dashBoardDataColumnNames.careSiteName,
    width: '30%',
  },
  {
    columnName: dashBoardDataColumnNames.lessThanZero,
    width: '15%',
    COLOR: COLORS.RED100,
  },
  {
    columnName: dashBoardDataColumnNames.lessThanTwo,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.lessThanThree,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.greaterThanThree,
    width: '15%',
  },
];

export const mdDataColumnsExtensionStyles = [
  {
    columnName: dashBoardDataColumnNames.careSiteName,
    width: '30%',
  },
  {
    columnName: dashBoardDataColumnNames.mDReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.mDP2PReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.mDAppealReviewCount,
    width: '15%',
  },
  {
    columnName: dashBoardDataColumnNames.cSRMDCount,
    width: '15%',
  },
];
