import { notMaybe } from './helpers/arrayFunctions';
import { PatientProblem } from 'graphql/graphqlTypes';

type Comparator = (a: PatientProblem, b: PatientProblem) => number;

const compareActive: Comparator = (a, b) =>
  (!a.isActive ? 1 : 0) - (!b.isActive ? 1 : 0);
const sortIndex: Comparator = (a, b) =>
  Math.sign((a?.sortIndex ?? 0) - (b?.sortIndex ?? 0));
const compareName: Comparator = (a, b) =>
  (a.problem?.name || '') < (b.problem?.name || '')
    ? -1
    : (b.problem?.name || '') < (a.problem?.name || '')
    ? 1
    : 0;

export const sortPatientProblems = (
  problems: Array<PatientProblem> | undefined | null
): Array<PatientProblem> => {
  if (!problems || problems.length === 0) {
    return new Array<PatientProblem>();
  }
  return problems?.filter(notMaybe).sort((a, b) => {
    return compareActive(a, b) || sortIndex(a, b) || compareName(a, b);
  });
};
