import React, { useRef, useState } from 'react';
import { Button } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTE } from 'consts/ROUTE';
import { styled as muiStyled } from '@mui/material/styles';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { openLetters } from 'store/ui/modals/letters';
import Divider from '@mui/material/Divider';
import { IState } from 'store';
import { useReactToPrint } from 'react-to-print';
import MozartPrintLayout from 'features/mozart/components/MozartPrintLayout/MozartPrintLayout';
import { useNavigate } from 'react-router';
import { useDirtyConfirmation } from 'hooks/useDirtyConfirmationModal';
import { DirtyConfirmationModal } from 'components/modal/DirtyConfirmationModal';

const StyledButton = muiStyled(Button)({
  marginLeft: '12px',
  marginRight: '12px',
});

const StyledDivider = styled(Divider)`
  margin: 2px 24px;
  height: 36px;
`;

export const MozartFinishFooter = ({
  handleResetState,
}: {
  handleResetState: () => void;
}) => {
  const [isPrintOpen, setIsPrintOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const completedChecklists = useSelector(
    (state: IState) => state.mozart.completedChecklists
  );
  const patientIdForReOpeningPatientDetails = useSelector(
    (state: IState) => state.patientDetails.patientId
  );
  const sectionName = 'Episode Summary';
  const isDirty = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary.isDirty
  );
  const {
    handleDirtyConfirm,
    clearDirtyState,
    handleConfirm,
    handleCancel,
    showModal,
  } = useDirtyConfirmation();
  const checklists = Object.values(completedChecklists);
  const checklist = checklists.filter((x) => x?.patientId && x?.episodeId)[0];
  const isLettersAvailable = checklists.some(
    (x) => x?.lettersIsAvailableForUser
  );

  const handleGoToHome = () => {
    handleDirtyConfirm(isDirty, onConfirmGoToHome);
  };

  const onConfirmGoToHome = () => {
    clearDirtyState();
    handleResetState();
    navigate(ROUTE.Home);
  };

  const handleNewScenario = () => {
    handleDirtyConfirm(isDirty, onConfirmNewScenario);
  };

  const onConfirmNewScenario = () => {
    clearDirtyState();
    handleResetState();
    navigate(
      `/Checklist/Problems?patientId=${checklist?.patientId}&newEpisode=True`
    );
  };
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => {
      setIsPrintOpen(true);
      return Promise.resolve(); // Promise for react to update state before react-to-print renders content
    },
    content: () => printRef.current,
    onAfterPrint: () => setIsPrintOpen(false),
  });

  return (
    <>
      <DirtyConfirmationModal
        showModal={showModal}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        sectionName={sectionName}
      />
      <Button
        color="primary"
        data-cy="print-mozart"
        size="large"
        onClick={handlePrint}
        style={{
          marginRight: '12px',
        }}
        startIcon={
          <Icon icon={ICONS.Print} size={22} color={COLORS.SYMPHONY_BLUE} />
        }
        variant="outlined"
      >
        Print
      </Button>
      <MozartPrintLayout isPrintOpen={isPrintOpen} ref={printRef} />

      {isLettersAvailable && (
        <>
          <Button
            color="primary"
            data-cy="open-letters"
            size="large"
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            startIcon={
              <Icon
                icon={ICONS.Letter}
                size={22}
                color={COLORS.SYMPHONY_BLUE}
              />
            }
            variant="outlined"
            onClick={() =>
              dispatch(
                openLetters({
                  episodeId: checklist?.episodeId ?? 0,
                  patientId: checklist?.patientId ?? 0,
                })
              )
            }
          >
            Letters
          </Button>
          <StyledDivider orientation="vertical" variant="middle" flexItem />
        </>
      )}

      <StyledButton
        color="primary"
        size="large"
        onClick={handleNewScenario}
        data-cy="new-scenario"
        variant="outlined"
      >
        New Scenario
      </StyledButton>

      <Button
        variant="contained"
        color="primary"
        size="large"
        style={{ marginLeft: '12px' }}
        onClick={handleGoToHome}
        data-cy="return-home"
        data-testid="mozart-go-home"
      >
        {patientIdForReOpeningPatientDetails
          ? 'Return to Member Details'
          : 'Return Home'}
      </Button>
    </>
  );
};
