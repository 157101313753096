import React, { useEffect } from 'react';
import { IEditorProps } from 'backend/types/grid/IEditorProps';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { formatDate } from 'util/helpers/dateTimeHelpers';
import { DATE_FORMAT } from 'components/constants';
import DateComponent from 'components/DateComponent';
import styled from 'styled-components';
import dayjs from 'dayjs';

const StyledWrapper = styled.div`
  padding-right: 8px;
  display: flex;
  width: 100%;
`;

const DateFilter = (props: IEditorProps) => {
  const [dateFilterValue, setDateFilterValue] = React.useState<Date | null>(
    null
  );
  const reduxValue = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  )?.find((filter) => filter.columnName === props.column.name)?.value;

  useEffect(() => {
    setDateFilterValue(reduxValue ? new Date(reduxValue) : null);
  }, [reduxValue]);

  const handleOnChange = (date: Date | null, inputValue?: string) => {
    setDateFilterValue(inputValue === undefined ? null : (date as Date));
    if (dayjs(date).isValid()) {
      props.onChange(formatDate(date as Date, DATE_FORMAT, true));
    }
  };

  return (
    <StyledWrapper>
      <DateComponent
        id={props.column?.name}
        name={props.column?.name}
        value={dateFilterValue}
        placeholder={props.column?.title}
        testId="date-filter"
        onChange={handleOnChange}
        onBlur={() => {
          if (dateFilterValue === null) {
            props.onChange('');
          }
        }}
      />
    </StyledWrapper>
  );
};

export default DateFilter;
