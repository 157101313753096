import React from 'react';
import { OutlinedInput, Theme } from '@mui/material';
import AutocompleteInput from './autocompleteInput';
import { Maybe } from 'graphql/graphqlTypes';
import { SxProps } from '@mui/system';

interface ChecklistTextInputProps {
  disabled: boolean;
  userDefinedId?: Maybe<string>;
  dataTestid: string;
  value: string;
  multiline: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  error: boolean;
  inputProps: { [k: string]: any };
  key: string;
  useAutocomplete?: boolean;
  sx?: SxProps<Theme>;
}

const ChecklistTextInput = (props: ChecklistTextInputProps) => {
  const { dataTestid, userDefinedId, useAutocomplete } = props;

  if (useAutocomplete) {
    return <AutocompleteInput {...props} />;
  }
  return (
    <OutlinedInput
      {...props}
      {...(userDefinedId && {
        'data-userdefinedid': userDefinedId,
      })}
      data-testid={dataTestid}
      type="text"
      fullWidth={true}
    />
  );
};

export default ChecklistTextInput;
