import { Column } from '@devexpress/dx-react-grid';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { ChecklistProblemViewModel } from 'graphql/graphqlTypes';
import React from 'react';
import DeleteByIdProvider from './Formatters/DeleteByIdProvider';
import InCarePlanProvider from './Formatters/InCarePlanProvider';
import IsActiveProvider from './Formatters/IsActiveProvider';
import { problemsColumnsExtensionStyles } from 'components/actions/sections/SectionBody/CarePlan/carePlanProblems';

interface IGridProps<T> {
  getRowId: (item: T) => number;
  rows: T[];
  columns: Column[];
}

const CarePlanProblemsGrid = <T extends ChecklistProblemViewModel>(
  props: IGridProps<T>
) => {
  const { getRowId, rows, columns } = props;
  return (
    <Grid rows={rows} columns={columns} getRowId={getRowId}>
      <InCarePlanProvider for={['inCarePlan']} />
      <IsActiveProvider for={['isActive']} />
      <DeleteByIdProvider for={['id']} />
      <SymphonyTable columnExtensions={problemsColumnsExtensionStyles} />
      <SymphonyTableHeaderRow />
    </Grid>
  );
};

export default CarePlanProblemsGrid;
