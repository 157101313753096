import { ChecklistStorageType, IAllDocumentsState } from 'store/actions/types';
import { BaseComponent, ReminderComponent } from 'graphql/graphqlTypes';
import { union } from 'lodash';
import { getReminderDateDue } from 'store/actions/actionsSlice.helpers';
import moment from 'moment';

const getLinkedComponentIds = (
  state: IAllDocumentsState,
  storageType: ChecklistStorageType,
  field: BaseComponent,
  remindersOnly = false
) => {
  const linkedComponents = state.documentsState[storageType].linkedComponents;
  let linkedComponentsIds: string[] = [];
  if ('mirrorGroup' in field && field.mirrorGroup && !remindersOnly) {
    linkedComponentsIds = union(
      linkedComponentsIds,
      linkedComponents[`mirror_${field.mirrorGroup}`] || []
    );
  }
  if (field.userDefinedId) {
    if (!remindersOnly) {
      linkedComponentsIds = union(
        linkedComponentsIds,
        union(linkedComponents[`oneway_${field.userDefinedId}`] || [])
      );
    }
    linkedComponentsIds = union(
      linkedComponentsIds,
      linkedComponents[`reminder_source_${field.userDefinedId}`] || []
    );
  }
  return linkedComponentsIds;
};

export const updateLinkedComponents = (
  state: IAllDocumentsState,
  storageType: ChecklistStorageType,
  field: BaseComponent,
  value: string,
  remindersOnly = false
) => {
  const checklistComponents =
    state.documentsState[storageType].checklistComponents;
  const linkedComponentsIds = getLinkedComponentIds(
    state,
    storageType,
    field,
    remindersOnly
  );
  linkedComponentsIds
    .filter((id) => field.uniqueID !== id)
    .forEach((id) => {
      const checklistComponent = checklistComponents[id];
      if (!checklistComponent) {
        return;
      }
      const dependentComponent =
        state.documentsState[storageType].checklistComponents[id];
      if (!dependentComponent) {
        return;
      }
      if (checklistComponent.component.componentType === 'ReminderComponent') {
        const date = getReminderDateDue(
          value,
          (checklistComponent.component as ReminderComponent).daysForward,
          (checklistComponent.component as ReminderComponent).dayType
        );
        (dependentComponent.component as ReminderComponent).dateDue = date
          ? moment(date).toDate()
          : undefined;
      } else if (!remindersOnly) {
        dependentComponent.component.value = value;
      }
    });
};

export const updateLinkedPendingReminders = (
  state: IAllDocumentsState,
  storageType: ChecklistStorageType
) => {
  const checklist = state.documentsState[storageType];
  if (!checklist.checklistComponents) {
    return;
  }

  if (checklist.remindersInitialized) {
    return;
  }

  const allComponents = Object.values(checklist.checklistComponents);

  const reminderComponents = allComponents
    .filter(
      (c) =>
        c.component.componentType === 'ReminderComponent' &&
        (c.component as ReminderComponent).isDateDuePending &&
        (c.component as ReminderComponent).daysForwardFrom
    )
    .map((c) => c.component as ReminderComponent);
  const set = new Set(reminderComponents.map((c) => c.daysForwardFrom));

  const components = allComponents
    .filter(
      (c) => c.component.userDefinedId && set.has(c.component.userDefinedId)
    )
    .map((c) => c.component);

  updateLinkedReminders(state, storageType, components);

  for (const item of reminderComponents) {
    item.isDateDuePending = false;
  }

  checklist.remindersInitialized = true;
};

export const updateLinkedReminders = (
  state: IAllDocumentsState,
  storageType: ChecklistStorageType,
  components: Array<BaseComponent>
) => {
  if (!components.length) {
    return;
  }

  for (const c of components) {
    if (c.value) {
      updateLinkedComponents(state, storageType, c, c.value, true);
    }
  }
};
