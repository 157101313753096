import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type OnSendFaxMutationVariables = Types.Exact<{
  attachmentId?: Types.InputMaybe<Types.Scalars['Int']>;
  checklistId: Types.Scalars['Int'];
  episodeId: Types.Scalars['Int'];
  isFaxRetry: Types.Scalars['Boolean'];
  isFromAttachment: Types.Scalars['Boolean'];
  letters?: Types.InputMaybe<
    | Array<Types.InputMaybe<Types.LetterDtoInput>>
    | Types.InputMaybe<Types.LetterDtoInput>
  >;
  note?: Types.InputMaybe<Types.Scalars['String']>;
  patientId: Types.Scalars['Int'];
  recipientFaxNumber: Types.Scalars['String'];
  recipientTo: Types.Scalars['String'];
  senderFaxNumber: Types.Scalars['String'];
  senderFrom: Types.Scalars['String'];
  senderRespondTo: Types.Scalars['Int'];
}>;

export type OnSendFaxMutation = {
  __typename?: 'Mutation';
  onSendFax: {
    __typename?: 'OnSendFaxResultModel';
    isFaxSent: boolean;
    attachment?: {
      __typename?: 'Attachment';
      id: number;
      name?: string | null;
      createdOn?: any | null;
      description?: string | null;
      fileExtension: string;
      location?: string | null;
      createdByUser?: { __typename?: 'User'; fullName?: string | null } | null;
    } | null;
  };
};

export const OnSendFaxDocument = `
    mutation onSendFax($attachmentId: Int, $checklistId: Int!, $episodeId: Int!, $isFaxRetry: Boolean!, $isFromAttachment: Boolean!, $letters: [LetterDtoInput], $note: String, $patientId: Int!, $recipientFaxNumber: String!, $recipientTo: String!, $senderFaxNumber: String!, $senderFrom: String!, $senderRespondTo: Int!) {
  onSendFax(
    model: {attachmentId: $attachmentId, checklistId: $checklistId, episodeId: $episodeId, isFaxRetry: $isFaxRetry, isFromAttachment: $isFromAttachment, letters: $letters, note: $note, patientId: $patientId, recipientFaxNumber: $recipientFaxNumber, recipientTo: $recipientTo, senderFaxNumber: $senderFaxNumber, senderFrom: $senderFrom, senderRespondTo: $senderRespondTo}
  ) {
    attachment {
      id
      name
      createdOn
      description
      createdByUser {
        fullName
      }
      fileExtension
      location
    }
    isFaxSent
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    onSendFax: build.mutation<OnSendFaxMutation, OnSendFaxMutationVariables>({
      query: (variables) => ({ document: OnSendFaxDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useOnSendFaxMutation } = injectedRtkApi;
