import { PatientCarePlanStatusFilterViewModel } from 'graphql/graphqlTypes';
import { Button } from '@mui/material';
import React from 'react';
import { FilterGrid } from 'components/gridFormatters';
import { ILookupValue } from 'backend/types/lookupValue';

export const CarePlanStatusAll: ILookupValue = {
  id: -1,
  name: PatientCarePlanStatusFilterViewModel.All,
};
export const CarePlanStatusActive: ILookupValue = {
  id: 0,
  name: PatientCarePlanStatusFilterViewModel.Active,
};
export const CarePlanStatusInActive: ILookupValue = {
  id: 1,
  name: PatientCarePlanStatusFilterViewModel.Inactive,
};
export const ProblemFilters: ILookupValue[] = [
  CarePlanStatusAll,
  CarePlanStatusActive,
  CarePlanStatusInActive,
];

export const CarePlanFilter = (props: {
  expandAll: () => void;
  collapseAll: () => void;
  setProblemFilter: (value: ILookupValue) => void;
  problemFilter: ILookupValue;
  disableActions: boolean;
}) => {
  const {
    expandAll,
    collapseAll,
    setProblemFilter,
    problemFilter,
    disableActions,
  } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        paddingBottom: '16px',
      }}
    >
      <div>
        <Button
          size="small"
          variant="outlined"
          style={{ marginRight: '20px' }}
          onClick={expandAll}
          disabled={disableActions}
        >
          Expand All
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={collapseAll}
          disabled={disableActions}
        >
          Collapse All
        </Button>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FilterGrid
          selectedItemId={problemFilter.id}
          onChange={setProblemFilter}
          items={ProblemFilters}
        />
      </div>
    </div>
  );
};
