import React, { useEffect, useState } from 'react';
import {
  ChangeSet,
  EditingState,
  IntegratedSorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableEditColumn,
  TableEditRow,
} from '@devexpress/dx-react-grid-material-ui';
import { BaseReminder } from 'graphql/graphqlTypes';
import { reminderEditingColumnExtensions } from './consts';
import { Box } from '@mui/material';
import {
  GridEditActions,
  StubCellComponent,
  SymphonyTable,
  SymphonyTableHeaderRow,
  TableEditCell,
  TableEditRows,
} from 'components/gridFormatters';
import { ReminderLabelCell } from './cells';
import { connectProps, Getter } from '@devexpress/dx-react-core';
import ReminderEditCell from './cells/EditFormaterCell';
import { useDispatch, useSelector } from 'react-redux';
import {
  commitReminders,
  updateDirtyTabs,
} from 'store/patientdetails/patientDetailsSlice';
import { IState } from 'store';
import { rearrangeColumnsEditPrelast } from 'components/home/attachmentsList/attachments.helpers';
import {
  getJustAddedReminderId,
  resetIsJustAdded,
} from './RemindersGrid.helpers';
import {
  useColumns,
  useReminderColumnsExtensions,
} from './RemindersGrid.hooks';

const getRowId = (row: BaseReminder) => row.id;

interface IRemindersGrid {
  reminders: BaseReminder[];
  showEpisodeTypeColumn?: boolean;
  readonly: boolean;
  isActionsDisabled?: boolean;
  episodeId?: number;
  showEpisodeIdColumn: boolean;
  editingRowIds?: Array<number>;
  setEditingRowIds?: (rows: Array<number>) => void;
  isDisabled?: boolean;
  removeEditingRowId?: (rowId: number) => void;
}

export const RemindersGrid = ({
  reminders,
  showEpisodeTypeColumn,
  readonly,
  isActionsDisabled,
  episodeId,
  showEpisodeIdColumn,
  editingRowIds,
  setEditingRowIds,
  isDisabled,
  removeEditingRowId,
}: IRemindersGrid) => {
  const [editEnabled, setEditEnabled] = useState(true);
  const dispatch = useDispatch();

  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  useEffect(() => {
    return () => {
      setEditingRowIds?.([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commitChanges = (changeSet: ChangeSet) => {
    resetIsJustAdded(changeSet, reminders);
    dispatch(updateDirtyTabs(episodeId ? 'Episodes' : 'Reminders'));
    dispatch(
      commitReminders({
        patientId: patientId,
        episodeId: episodeId,
        reminders: changeSet,
      })
    );
  };

  const columns = useColumns(
    showEpisodeTypeColumn ?? false,
    showEpisodeIdColumn
  );

  const columnExtensions = useReminderColumnsExtensions(
    showEpisodeTypeColumn ?? false,
    readonly,
    showEpisodeIdColumn
  );

  const onCancel = () => {
    const rowIdToBeRemoved = getJustAddedReminderId(editingRowIds, reminders);
    if (rowIdToBeRemoved) {
      dispatch(
        commitReminders({
          patientId: patientId,
          episodeId: episodeId,
          reminders: {
            deleted: [rowIdToBeRemoved],
          },
        })
      );

      if (editingRowIds?.includes(rowIdToBeRemoved)) {
        removeEditingRowId?.(rowIdToBeRemoved);
      }
    }
  };

  const gridEditActions = connectProps(GridEditActions, () => ({
    editEnabled: editEnabled && !isDisabled,
    onCancel: onCancel,
    itemName: 'reminder',
  }));

  const reminderLabelCell = connectProps(ReminderLabelCell, () => ({
    isActionsDisabled: isActionsDisabled,
    disabled: isDisabled,
  }));

  return (
    <Box>
      <Grid rows={reminders} columns={columns} getRowId={getRowId}>
        <SortingState columnExtensions={columnExtensions} />
        {!readonly && (
          <EditingState
            onCommitChanges={commitChanges}
            columnExtensions={reminderEditingColumnExtensions ?? []}
            editingRowIds={editingRowIds}
            onEditingRowIdsChange={(
              newEditingRowIds: Array<number | string>
            ) => {
              setEditingRowIds?.(newEditingRowIds as number[]);
              setEditEnabled(newEditingRowIds.length === 0);
            }}
          />
        )}
        <IntegratedSorting columnExtensions={columnExtensions} />
        <SymphonyTable
          cellComponent={reminderLabelCell}
          columnExtensions={columnExtensions}
        />
        {!readonly && (
          <TableEditRow
            cellComponent={ReminderEditCell}
            rowComponent={TableEditRows}
          />
        )}
        {!readonly && (
          <TableEditColumn
            showEditCommand
            showAddCommand
            cellComponent={TableEditCell}
            headerCellComponent={StubCellComponent}
            commandComponent={gridEditActions}
            width="80px"
          />
        )}

        <SymphonyTableHeaderRow showSortingControls />
        {!readonly && (
          <Getter name="tableColumns" computed={rearrangeColumnsEditPrelast} />
        )}
      </Grid>
    </Box>
  );
};
