import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROUTE } from 'consts/ROUTE';

export interface IChecklistState {
  open: boolean;
  checklistId: number;
  checklistExitModal: {
    exitModalOpen: boolean;
    action: 'Discard' | 'FinishLater' | null;
    route: ROUTE | string | null;
    shouldLogout?: boolean;
  };
}

export const initialChecklistState: IChecklistState = {
  open: false,
  checklistId: 0,
  checklistExitModal: {
    exitModalOpen: false,
    action: null,
    route: null,
    shouldLogout: false,
  },
};

const checklistSlice = createSlice({
  name: 'checklistModalState',
  initialState: initialChecklistState,
  reducers: {
    openChecklist: (
      state,
      {
        payload: { checklistId },
      }: PayloadAction<{
        checklistId: number;
      }>
    ) => {
      state.open = true;
      state.checklistId = checklistId;
    },
    close: (state) => {
      state.open = false;
      state.checklistId = 0;
    },
    setShowLeavingChecklistConfirmation(
      state,
      action: PayloadAction<{
        exitModalOpen: boolean;
        action: 'Discard' | 'FinishLater' | null;
        route: ROUTE | string | null;
        shouldLogout?: boolean;
      }>
    ) {
      state.checklistExitModal = action.payload;
    },
  },
});

export const { openChecklist, close, setShowLeavingChecklistConfirmation } =
  checklistSlice.actions;

export default checklistSlice.reducer;
