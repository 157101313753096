import React, { useState } from 'react';
import { EntityListTabDefinition } from 'graphql/graphqlTypes';
import { Menu, MenuItem, Typography } from '@mui/material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';

interface IDropdownTab {
  tabs: EntityListTabDefinition[];
  activeTab: number;
  handleTabChange: (tabIndex: number | string) => void;
}

const StyledTabsTrigger = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  border: 1px solid #d7d7d7;
  min-height: ${({ $isActive }) => ($isActive ? ` 36px` : `38px`)};
  max-width: 104px;
  width: 104px;
  justify-content: center;
  border-top-right-radius: 4px;
  padding: 0 12px;
  border-top: ${({ $isActive }) =>
    $isActive ? ` 3px solid ${COLORS.SYMPHONY_BLUE}` : `1px solid #d7d7d7`};
  &:hover {
    background-color: ${COLORS.BLUE10};
    cursor: pointer;
  }
`;

const StyledTypography = styled(Typography)<{ $isActive: boolean }>`
  font-weight: 500;
  color: ${({ $isActive }) =>
    $isActive ? COLORS.SYMPHONY_BLUE : COLORS.GREY100};
  padding-right: 8px;
  max-width: 90px;
`;

const DropdownTab = ({ tabs, activeTab, handleTabChange }: IDropdownTab) => {
  const [anchorElement, setAnchorElement] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const isActiveTab = tabs?.some((tab) => tab.index === activeTab);
  const tabLabel = isActiveTab
    ? tabs?.find((tab) => tab.index === activeTab)?.name
    : 'More';

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(!menuOpen ? event.currentTarget : null);
    setMenuOpen(!menuOpen);
  };

  const handleClickMenuItem = (value: number) => {
    setMenuOpen(false);
    setAnchorElement(null);
    handleTabChange(value);
  };

  return (
    <div
      onClick={handleToggle}
      data-testid="more-tabs-trigger"
      data-cy="more-tabs-trigger"
    >
      <StyledTabsTrigger $isActive={isActiveTab}>
        <CustomTooltip title={tabLabel ?? ''}>
          <StyledTypography
            variant="body2"
            noWrap
            $isActive={menuOpen || isActiveTab}
          >
            {tabLabel}
          </StyledTypography>
        </CustomTooltip>

        <Icon
          size={10}
          icon={menuOpen ? ICONS.DropdownUp : ICONS.DropdownDown}
          color={
            menuOpen || isActiveTab ? COLORS.SYMPHONY_BLUE : COLORS.GREY100
          }
        />
      </StyledTabsTrigger>
      <Menu
        anchorEl={anchorElement}
        open={menuOpen}
        onClose={handleToggle}
        data-cy="more-tabs-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            left: '0',
            marginTop: '-1px',
            borderRadius: '4px',
            border: '1px solid #CECECE',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
            borderTopRightRadius: '0',
            minWidth: '158px',
          },
        }}
      >
        {tabs?.map((item) => (
          <MenuItem
            key={item.index}
            selected={activeTab === item.index}
            onClick={() => handleClickMenuItem(item.index ?? 0)}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropdownTab;
