import { Divider, Grid, Typography } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Grid as TableGrid,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  CustomPaging,
  PagingState,
  Sorting,
  SortingState,
} from '@devexpress/dx-react-grid';
import PagingContainer from 'components/home/grid/pager';
import TimelineTypeProvider from 'components/home/grid/providers/TimelineTypeProvider';
import TimelineDescriptionProvider from 'components/home/grid/providers/TimelineDescriptionProvider';
import TimelineDetailsProvider from 'components/home/grid/providers/TimelineDetailsProvider';
import TimelineActionsProvider from 'components/home/grid/providers/TimelineActionsProvider';
import { ITimelineResponseBody, TTimelineRow } from './types';
import SearchBar from 'components/searchbar/SearchBar';
import FilterBar from './filterBar';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import { convertTimeLineStateToInt } from 'util/helpers/timelineHelper';
import {
  SymphonyTableHeaderRow,
  SymphonyVirtualTable,
  SymphonyTable,
} from 'components/gridFormatters';
import RefreshButton from 'components/refreshbutton';
import { TIMELINE_TAB_SELECTED_STATES } from 'components/constants';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import { TimelineRequestInput, TimelineState } from 'graphql/graphqlTypes';
import { useGetPatientTimelineDataQuery } from 'graphql/hooks/getPatientTimelineData';
import { useGetPatientTimelineModelQuery } from 'graphql/hooks/getPatientTimelineModel';
import Loader from 'components/loader';
import styled from 'styled-components';
import TimelineMoreInfoProvider from 'components/home/grid/providers/TimelineMoreInfoProvider';
import { CellComponentPadded } from 'components/gridFormatters/CellComponentPadded';
import { useSelector } from 'react-redux';
import { IState } from 'store';

export interface ITimelineTabProps {
  style?: CSSProperties;
  patientId: number;
  backgroundFetch?: boolean;
  useTable?: boolean;
}

const StyledDivider = styled(Divider)`
  margin: 17px 12px 20px 24px;
  height: 24px;
`;

const TimelineTab = (props: ITimelineTabProps) => {
  const { patientId, backgroundFetch } = props;
  const [gridData, updateGridData] = useState({
    count: 0,
    rows: [],
    localDateTime: '',
  } as ITimelineResponseBody);
  const [timelineModel, updateModel] = useState<TimelineRequestInput>({
    first: 1,
    pageSize: 20,
    patientId: 0,
    orderBy: 'date',
    orderDirection: 'Desc',
    searchTerm: '',
    states: [],
  });

  const [setUserPreference, { isLoading: isUpdatingUserPreference }] =
    useSetPreferenceMutation();

  const refreshTimelineGridData = useSelector(
    (state: IState) => state.patientDetails.refreshTimelineGridData
  );

  const {
    data: actualData,
    isSuccess,
    isFetching,
    isLoading,
    refetch,
  } = useGetPatientTimelineDataQuery(
    {
      request: timelineModel,
    },
    { skip: timelineModel.patientId <= 0 }
  );
  const getCellValue = (row: TTimelineRow) => ({
    id: row?.id ?? 0,
    patientId: patientId,
    onGridRefetch,
  });

  const columns = [
    { name: 'date', title: 'Date/Time' },
    { name: 'finishedOn', title: 'Finished On' },
    { name: 'type', title: 'Type' },
    { name: 'description', title: 'Description' },
    { name: 'individual', title: 'Individual' },
    { name: 'details', title: 'Details' },
    {
      name: 'id',
      title: ' ',
      getCellValue: getCellValue,
    },
  ];

  const columnsExtension = [
    {
      columnName: 'date',
      sortingEnabled: true,
      width: 140,
      wordWrapEnabled: true,
    },
    {
      columnName: 'finishedOn',
      sortingEnabled: true,
      width: 140,
      wordWrapEnabled: true,
    },
    {
      columnName: 'type',
      sortingEnabled: true,
      width: 44,
      wordWrapEnabled: true,
    },
    {
      columnName: 'description',
      sortingEnabled: true,
      wordWrapEnabled: true,
    },
    {
      columnName: 'individual',
      sortingEnabled: true,
      wordWrapEnabled: true,
      width: 200,
    },
    {
      columnName: 'details',
      sortingEnabled: true,
      wordWrapEnabled: true,
      width: 340,
    },
    {
      columnName: 'id',
      sortingEnabled: false,
      wordWrapEnabled: false,
      width: 260,
    },
  ];

  const showMoreInfoColumn =
    gridData?.count > 0 &&
    gridData.rows?.some((r) => r && 'externalReferenceId' in r);
  if (showMoreInfoColumn) {
    columns.push({
      name: 'moreInfo',
      title: ' ',
      getCellValue: getCellValue,
    });
    columnsExtension.push({
      columnName: 'moreInfo',
      sortingEnabled: false,
      wordWrapEnabled: false,
      width: 30,
    });
  }
  const { data: model, isFetching: isModelFetching } =
    useGetPatientTimelineModelQuery(undefined);

  useEffect(() => {
    updateModel((prevState) => ({
      ...prevState,
      patientId: patientId,
    }));
  }, [patientId]);

  useEffect(() => {
    if (model) {
      updateModel({
        first: Number(
          model.getPatientTimelineModel
            ? model.getPatientTimelineModel.first
            : 1
        ),
        pageSize: Number(
          model.getPatientTimelineModel
            ? model.getPatientTimelineModel.pageSize
            : 25
        ),
        patientId: Number(patientId),
        orderBy: model.getPatientTimelineModel
          ? (model.getPatientTimelineModel.orderBy as string)
          : 'date',
        orderDirection: model.getPatientTimelineModel
          ? (model.getPatientTimelineModel.orderDirection as string)
          : 'Desc',
        searchTerm: '',
        states: model?.getPatientTimelineModel?.states as Array<TimelineState>,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    if (actualData) {
      updateGridData({
        count: actualData.getPatientTimelineData?.count as number,
        rows: actualData.getPatientTimelineData?.rows as TTimelineRow[],
        localDateTime: actualData.getPatientTimelineData?.localDateTime,
      });
    }
  }, [actualData]);

  const onSetCurrentPage = (page: number) => {
    const updatedModel = {
      ...timelineModel,
      first: page * (timelineModel.pageSize ?? 0) + 1,
    } as TimelineRequestInput;
    updateModel(updatedModel);
  };

  const onSetSorting = (sorting: Sorting[]) => {
    const updatedModel = {
      ...timelineModel,
      first: 1,
      orderBy: sorting[0].columnName,
      orderDirection: sorting[0].direction.toString(),
    } as TimelineRequestInput;
    updateModel(updatedModel);
  };

  const handleSubmit = (searchTerm: string) => {
    const updatedModel = {
      ...timelineModel,
      first: 1,
      searchTerm: searchTerm,
    } as TimelineRequestInput;
    updateModel(updatedModel);
  };

  const onGridRefetch = () => {
    refetch();
  };

  useEffect(() => {
    if (isSuccess && !isFetching && refreshTimelineGridData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, refreshTimelineGridData]);

  const onFilterModelUpdate = (state: TimelineState) => {
    let newModel: TimelineRequestInput;
    if (timelineModel?.states?.includes(state)) {
      const newStates = timelineModel.states.filter((item) => item !== state);
      newModel = {
        ...timelineModel,
        states: newStates,
      };
    } else {
      newModel = {
        ...timelineModel,
        states: [...(timelineModel.states as Array<TimelineState>), state],
      };
    }
    updateModel(newModel);
    const stateString =
      newModel.states != undefined && newModel?.states?.length > 0
        ? (newModel?.states as Array<TimelineState>)
            .map((x) => x && convertTimeLineStateToInt(x)?.toString())
            .join(';')
        : 'empty';
    setUserPreference({
      name: TIMELINE_TAB_SELECTED_STATES,
      value: stateString,
    });
  };
  const first = timelineModel?.first ?? 1;
  const pageSize = timelineModel?.pageSize ?? 25;
  const currentPage = Number(Math.floor((first - 1) / pageSize) ?? 0);
  const table = props.useTable ? (
    <div data-testid="symphony-table">
      <SymphonyTable
        key="symphony-table"
        columnExtensions={columnsExtension}
        cellComponent={CellComponentPadded}
      />
    </div>
  ) : (
    <div data-testid="symphony-virtual-table">
      <SymphonyVirtualTable
        key="symphony-virtual-table"
        columnExtensions={columnsExtension}
        cellComponent={CellComponentPadded}
      />
    </div>
  );
  return (
    <div
      data-cy="timeline-tab"
      data-testid="timeline-tab"
      style={{ paddingTop: '24px' }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Timeline</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="row"
          >
            <SearchBar
              placeholder="Search Description, Individual, Details"
              onSubmit={handleSubmit}
              style={{ width: '363px' }}
            />
            <StyledDivider orientation="vertical" flexItem />
            <RefreshButton onClick={() => refetch()} />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {timelineModel ? (
            <FilterBar
              states={timelineModel.states as Array<TimelineState>}
              onUpdateModel={onFilterModelUpdate}
            />
          ) : null}
        </Grid>
      </Grid>

      <div style={{ marginTop: '5px' }} data-cy="timeline-grid">
        <TableGrid columns={columns} rows={isSuccess ? gridData.rows : []}>
          <TimelineTypeProvider for={['type']} />
          <SimpleTextProvider for={['date', 'individual, finishedOn']} />
          <TimelineDescriptionProvider
            for={[
              'description',
              'reminderDescription',
              'attachmentDescription',
              'workflowStepName',
            ]}
          />
          <TimelineDetailsProvider for={['details']} />
          <TimelineActionsProvider for={['id']} />
          <TimelineMoreInfoProvider for={['moreInfo']} />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={onSetCurrentPage}
            pageSize={Number(timelineModel.pageSize ?? 25)}
          />
          <CustomPaging totalCount={Number(gridData?.count ?? 0)} />
          <SortingState
            sorting={[
              {
                columnName: timelineModel?.orderBy ?? 'date',
                direction:
                  timelineModel?.orderDirection?.toLowerCase() == 'asc'
                    ? 'asc'
                    : 'desc',
              },
            ]}
            onSortingChange={onSetSorting}
            columnExtensions={columnsExtension}
          />
          {table}

          <SymphonyTableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </TableGrid>
      </div>

      {!backgroundFetch ? (
        <Loader
          active={
            isFetching ||
            isLoading ||
            isModelFetching ||
            isUpdatingUserPreference
          }
        />
      ) : null}
    </div>
  );
};

export default TimelineTab;
