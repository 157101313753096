import React, { useState } from 'react';
import { Divider, IconButton, styled } from '@mui/material';
import { COLORS } from 'consts/styles';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { Privilege } from 'store/roles/types';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { resetChecklist } from 'store/actions/checklistSlice';
import { ChecklistStorageType } from 'store/actions/types';
import {
  mozartResetChecklist,
  mozartStartPolling,
  mozartWorkflowPaused,
} from 'store/actions/mozartSlice';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import {
  MozartActionType,
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { useRevertMozartWorkflowToPreviousStepMutation } from 'graphql/hooks/revertMozartWorkflowToPreviousStep';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import Icon, { ICONS } from 'components/icon';
import Loader from 'components/loader';
import { isWorkflowCompleted } from 'features/mozart/MozartWorkflowLayout.helpers';

const StyledDivider = styled(Divider)`
  margin-left: 12px;
  margin-right: 6px;
`;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  '&:hover': {
    color: COLORS.SYMPHONY_BLUE_HOVER,
    cursor: 'pointer',
  },
});

export interface IMozartRevertToPriorStepIcon {
  message?: MozartWorkflowUiModel;
}

export const MozartRevertToPriorStepIcon = ({
  message,
}: IMozartRevertToPriorStepIcon) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const canRevertToPriorStepInMozart = hasUserPrivilege(
    Privilege.AllowRevertToPriorStepInMozart
  );
  const mozartMessages = useSelector(
    (state: IState) => state.mozart.mozartMessages
  );
  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );
  const workflowCompleted = mozartMessages.some(isWorkflowCompleted);

  const [revertMozartWorkflowToPreviousStep] =
    useRevertMozartWorkflowToPreviousStepMutation();

  const handleRevertToPriorStepAction = () => {
    if (message) {
      setIsLoading(true);
      revertMozartWorkflowToPreviousStep({
        workflowInstanceId: message.workflowInstanceId,
        stepId: message.stepInstanceId,
      }).then((result) => {
        setIsLoading(false);
        if (
          'data' in result &&
          result?.data?.revertMozartWorkflowToPreviousStep?.result
        ) {
          dispatch(resetChecklist(ChecklistStorageType.MOZART));
          dispatch(mozartResetChecklist());
          dispatch(mozartStartPolling());
          dispatch(mozartWorkflowPaused(false));
        } else {
          dispatch(
            showErrorPopup({
              message: 'Failed to go back to previous step.',
            })
          );
        }
      });
    }
  };

  if (
    !workflowCompleted &&
    !mozartViewOnly &&
    message &&
    message.actionType === MozartActionType.UserInput &&
    message.state === MozartProgressState.Completed &&
    canRevertToPriorStepInMozart
  ) {
    return (
      <>
        <StyledDivider orientation="vertical" flexItem />
        <CustomTooltip title="go back to this step">
          <StyledIconButton
            size="large"
            data-testid="revert-prior-step-icon"
            onClick={handleRevertToPriorStepAction}
          >
            <Icon icon={ICONS.Update} size={16} color={COLORS.SYMPHONY_BLUE} />
          </StyledIconButton>
        </CustomTooltip>
        <Loader active={isLoading} />
      </>
    );
  }
  return null;
};
