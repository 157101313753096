import { DropdownComponent, DropdownType } from 'graphql/graphqlTypes';
import { IEntityLookup, ILookup } from 'backend/types/lookup';
import { IDropdownItem } from '../types';
import { ILookupValue } from 'backend/types/lookupValue';
import { IMainModel } from 'store/home/types';

export const getBigTableInitialValue = (
  component: DropdownComponent,
  bigTableValues: IEntityLookup[]
): ILookupValue | undefined => {
  if (
    component.isBigTable &&
    component.dropDownType == DropdownType.Entity &&
    component.value
  ) {
    return bigTableValues
      ?.find((v) => v.name == component.entityName)
      ?.values.find((v) => v.id === Number(component.value));
  }
};

export const getDropdownOptions = (
  component: DropdownComponent,
  lookups: ILookup[],
  lookupId: number,
  entityLookups: IEntityLookup[],
  mainModel: IMainModel
): IDropdownItem[] => {
  if (component.isBigTable) {
    return [];
  }
  switch (component.dropDownType) {
    case DropdownType.Entity:
      return getOptionsForEntityDropdown(component, entityLookups, mainModel);
    case DropdownType.Lookup:
      return getOptionsForLookupDropdown(lookups, lookupId, component);
    default:
      return getOptionsForChoicesDropdown(component);
  }
};

const getOptionsForLookupDropdown = (
  lookups: ILookup[],
  lookupId: number,
  component: DropdownComponent
): IDropdownItem[] => {
  const lookup = lookups?.find((l: ILookup) => l.id === lookupId);
  const intValue = parseInt(component.value || '0');
  return (
    lookup?.values
      .filter(
        (lookupValue) => !lookupValue.isDeleted || lookupValue.id === intValue
      )
      .sort((lookupValue) => lookupValue.sortOrder || 0)
      .map((lookupValue) => mapToDropdownItem(lookupValue)) ?? []
  );
};

const getOptionsForEntityDropdown = (
  component: DropdownComponent,
  entityLookups: IEntityLookup[],
  mainModel: IMainModel
): IDropdownItem[] => {
  const entity = entityLookups?.find(
    (l: IEntityLookup) => l.name === component.entityName
  );
  if (
    entity?.name === 'User' &&
    (component.fieldName === 'Patient.Owner' ||
      component.fieldName === 'Patient.SecondaryOwner')
  ) {
    return (
      mainModel.users
        .filter((x) => x.canBeMemberOwner)
        .map((y) => mapToDropdownItem({ id: y.id, name: y.name ?? '' })) ?? []
    );
  }
  return entity?.values.map((lv: ILookupValue) => mapToDropdownItem(lv)) ?? [];
};

const getOptionsForChoicesDropdown = (
  component: DropdownComponent
): IDropdownItem[] => {
  if (!component.choices || component.choices.length === 0) {
    return [];
  }

  return component.choices
    .filter((item: string) => item.length > 0)
    .map(
      (item: string) =>
        ({
          value: item,
          name: item,
        } as IDropdownItem)
    );
};

const mapToDropdownItem = (lv: ILookupValue) => {
  return {
    value: lv.id.toString(),
    name: lv.name,
  } as IDropdownItem;
};
