import React, { useEffect, useState } from 'react';
import { getUserRoles, setUserRole } from 'store/roles/middlewares';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { IRole } from 'store/roles/types';
import { IState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import List from '@mui/material/List';
import { COLORS } from 'consts/styles';
import { Box, Typography } from '@mui/material';
import { isUndefined, sortBy, uniqBy } from 'lodash';
import { clearSearch } from 'store/ui/header';
import { setPrimary } from 'store/roles/action';
import Loader from 'components/loader';
import { clearHomeModel } from 'store/home/action';
import styled from 'styled-components';
import { CareSiteSelector } from 'components/roleslist/CareSiteSelector';
import { RoleListItem } from 'components/roleslist/RoleListItem';
import { StyledWrapper } from '.';
import { SuperAdminRoleId } from 'consts/roles';

const StyledList = styled(List)({
  paddingTop: 0,
  maxHeight: '45vh',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
  },
});

export interface IListRoleMethods {
  callback?: (changed: boolean) => void;
  padding?: string;
  selectedCareSiteId?: number;
}

const RolesList = (props: IListRoleMethods) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IState) => state.user.auth);
  const [selectedCareSiteId, setSelectedCareSiteId] = useState<number>(0);
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const authLogin = useSelector((state: IState) => state.user.auth.login);
  const currentUserLogin = useSelector(
    (state: IState) => state.user.currentUser.login
  );
  const isLoggedIn = useSelector((state: IState) => state.user.isLoggedIn);
  const roles = useSelector((state: IState) => state.roles.items);
  const superAdminRole = roles.find((r) => r.roleId === SuperAdminRoleId);
  const visibleRoles = roles.filter((r) => r.careSiteId);
  const [primaryRoleId, setPrimaryRole] = useState<number | null>(null);

  const lastLoggedCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const loading = useSelector((state: IState) => state.roles.loading);
  const sessionId = useSelector(
    (state: IState) => state.user.currentUser.sessionId
  );

  const careSites = uniqBy(
    visibleRoles.map((item) => ({
      name: item.careSiteName,
      id: item.careSiteId,
    })),
    'id'
  );

  const roleSelectTitle =
    careSites.length > 1 ? 'Select Care site and Role' : 'Select Role';

  useEffect(() => {
    roles.length === 0 && dispatch(getUserRoles(user, isLoggedIn));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLoggedIn]);

  const getSelectedCareSite = (defaultRole: IRole | undefined) => {
    if (
      lastLoggedCareSiteId &&
      lastLoggedCareSiteId > 0 &&
      careSites.some((x) => x.id === lastLoggedCareSiteId)
    ) {
      return lastLoggedCareSiteId;
    }
    const result =
      defaultRole?.careSiteId &&
      visibleRoles.some((r) => r.careSiteId === defaultRole?.careSiteId)
        ? defaultRole
        : visibleRoles[0];
    return result?.careSiteId;
  };

  useEffect(() => {
    props.selectedCareSiteId && setSelectedCareSiteId(props.selectedCareSiteId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedCareSiteId]);

  useEffect(() => {
    if (roles.length > 0 && isUndefined(props.selectedCareSiteId)) {
      const defaultRole = roles.find((role) => role.primary);
      const selectedCareSite = getSelectedCareSite(defaultRole);
      setSelectedCareSiteId(selectedCareSite);
      setPrimaryRole(defaultRole?.id ?? null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  const handleChangeUserRole = (
    selectedRoleId: number,
    roleId: number | null
  ) => {
    if (props.callback) {
      props.callback(true);
    }
    if (selectedRoleId !== currentRoleId) {
      dispatch(setWorkflowStep({ step: 0, name: '' }));
      dispatch(
        setUserRole(selectedRoleId, sessionId, user, roleId ?? 0, isLoggedIn)
      );
      dispatch(clearSearch());
      dispatch(clearHomeModel());
      dispatch(setPrimary(roleId));
    }
  };

  const selectedCareSiteRoles = sortBy<IRole>(
    visibleRoles.filter((role) => role.careSiteId === selectedCareSiteId),
    [
      (item) => (!item.primary ? 1 : 0),
      (item) => (item.displayName || '').toLowerCase(),
    ],
    ['asc']
  );

  return (
    <StyledWrapper
      style={{ padding: props.padding ? props.padding : '15vh 0 10vh 0' }}
    >
      <Box paddingBottom="50px">
        <Typography
          style={{
            color: COLORS.BLACK,
            fontWeight: 300,
            fontSize: '32px',
          }}
          variant="h2"
        >
          {roleSelectTitle}
        </Typography>
        <Typography style={{ color: COLORS.GREY100 }} variant="body1">
          Username: <strong>{currentUserLogin || authLogin}</strong>
        </Typography>
      </Box>
      <CareSiteSelector
        careSites={careSites}
        selectedCareSiteId={selectedCareSiteId}
        setSelectedCareSiteId={setSelectedCareSiteId}
        title="Care site"
      />
      <Box>
        <Typography style={{ color: COLORS.GREY100 }} variant="body1">
          Role
        </Typography>
      </Box>
      <StyledList aria-label="roles selection">
        {selectedCareSiteRoles.map((role) => (
          <RoleListItem
            key={role.id}
            role={role}
            handleChangeUserRole={handleChangeUserRole}
            primaryRoleId={primaryRoleId}
            setPrimaryRole={setPrimaryRole}
          />
        ))}
      </StyledList>
      {superAdminRole && (
        <>
          <Box
            sx={{
              mt: '38px',
              borderTop: `1px solid ${COLORS.GREY30}`,
              maxWidth: '330px',
            }}
          >
            <Typography
              style={{ color: COLORS.GREY100, marginTop: '30px' }}
              variant="body1"
            >
              New Composer
            </Typography>
          </Box>
          <StyledList aria-label="composer super admin">
            <RoleListItem
              role={superAdminRole}
              handleChangeUserRole={handleChangeUserRole}
              primaryRoleId={primaryRoleId}
              setPrimaryRole={setPrimaryRole}
            />
          </StyledList>
        </>
      )}
      <Loader active={loading} />
    </StyledWrapper>
  );
};

export default RolesList;
