import 'regenerator-runtime/runtime';
import React from 'react';
import { useSelector } from 'react-redux';
import { SymphonyTable } from 'components/gridFormatters';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { IState } from 'store';
import TooltipProvider from 'components/home/grid/providers/TooltipProvider';
import styled from 'styled-components';
import { useGetAuthTurnAroundTimeQuery } from 'graphql/hooks/getAuthTurnAroundTime';
import {
  dashBoardDataColumnsExtensionStyles,
  dashBoardGridColumns,
  dashBoardGridPriority,
} from './DashBoardGridHelper';
import Loader from 'components/loader';
import { AuthTurnAroundTimeModel } from 'graphql/graphqlTypes';
import DashBoardHeaderCell from './DashBoardHeaderCell';
import { IUserPreferences } from 'backend/types/userPreferences';
import DashValueProvider from './DashValueProvider';

const StyledGrid = styled(Grid)({
  marginTop: '20px',
  marginBottom: '80px',
});

const DashBoardGrid = ({
  priority,
  defaultAuthorization,
  priorityBy,
  userPreferences,
}: {
  isLoadingCompleted: boolean;
  priority: string;
  headerName: string;
  defaultAuthorization: string;
  priorityBy: string;
  userPreferences: IUserPreferences;
}) => {
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const userId = useSelector((state: IState) => state.user.currentUser.id);
  const standardData = useGetAuthTurnAroundTimeQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      priority: dashBoardGridPriority.standard,
      defaultAuthorization:
        priorityBy == dashBoardGridPriority.standard
          ? defaultAuthorization
          : userPreferences?.standardAuthorization,
    },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  const expeditedData = useGetAuthTurnAroundTimeQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      priority: dashBoardGridPriority.expedited,
      defaultAuthorization:
        priorityBy == dashBoardGridPriority.expedited
          ? defaultAuthorization
          : userPreferences.expeditedAuthorization,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  if (standardData.isFetching || expeditedData.isFetching) {
    return <Loader active={true} />;
  }
  return (
    <>
      <StyledGrid
        data-testid="dashboard-grid"
        rows={
          (priority == dashBoardGridPriority.standard
            ? standardData?.data?.getAuthTurnAroundTime
            : expeditedData?.data
                ?.getAuthTurnAroundTime) as AuthTurnAroundTimeModel[]
        }
        columns={dashBoardGridColumns}
        getRowId={(row) => row.careSiteId}
      >
        <TooltipProvider for={dashBoardGridColumns.map((i) => i.name)} />
        <DashValueProvider for={dashBoardGridColumns.map((i) => i.name)} />
        <SymphonyTable columnExtensions={dashBoardDataColumnsExtensionStyles} />
        <TableHeaderRow cellComponent={DashBoardHeaderCell} />
      </StyledGrid>
    </>
  );
};

export default DashBoardGrid;
