import React from 'react';
import { Button, styled } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const StyledButton = styled(Button)({
  marginRight: '16px',
  minWidth: '114px',
  marginTop: '8px',
});

const StyledLetterActionsButton = styled(Button)({
  marginRight: '16px',
  marginTop: '8px',
  minWidth: '114px',
});

interface IActionButtonProps {
  text: string;
  icon: ICONS;
  active: boolean;
  isFromLetters?: boolean;
  onClick?: () => void;
}

export const ActionButton = ({
  text,
  icon,
  active,
  isFromLetters,
  onClick,
}: IActionButtonProps) => {
  const iconColor = active ? COLORS.SYMPHONY_BLUE : COLORS.GREY60;

  return isFromLetters ? (
    <StyledLetterActionsButton
      size="small"
      variant="outlined"
      disabled={!active}
      onClick={onClick}
      startIcon={<Icon icon={icon} size={14} color={iconColor} />}
    >
      {text}
    </StyledLetterActionsButton>
  ) : (
    <StyledButton
      size="small"
      variant="outlined"
      disabled={!active}
      onClick={onClick}
      startIcon={<Icon icon={icon} size={14} color={iconColor} />}
    >
      {text}
    </StyledButton>
  );
};
