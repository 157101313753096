import React, { useEffect, useState } from 'react';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from '../../modal/Confirmation';
import { useLazyCheckNewEpisodeQuery } from 'graphql/hooks/checkNewEpisode';
import { CheckNewEpisodeStatus } from 'graphql/graphqlTypes';
import { IState } from 'store';
import { RoleType } from 'store/roles/types';
import { useLazyCheckOpenedEpisodeQuery } from 'graphql/hooks/checkOpenedEpisode';
import { useLazyCheckIsPatientActiveQuery } from 'graphql/hooks/checkIsPatientActive';
import { setWorkflowStep } from 'store/workflow/workflowSlice';
import { ICONS } from 'components/icon';
import { useNavigate } from 'react-router';

const useNewEpisodeCheck = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [
    checkNewEpisode,
    {
      data: checkNewEpisodeData,
      isFetching: checkNewEpisodeIsFetching,
      isSuccess: checkNewEpisodeIsSuccess,
    },
  ] = useLazyCheckNewEpisodeQuery();
  const [
    checkOpenedEpisode,
    {
      data: checkOpenedEpisodeData,
      isFetching: checkOpenedEpisodeIsFetching,
      isSuccess: checkOpenedEpisodeIsSuccess,
    },
  ] = useLazyCheckOpenedEpisodeQuery();
  const [
    checkIsPatientActive,
    {
      data: checkIsPatientActiveData,
      isFetching: checkIsPatientActiveIsFetching,
      isSuccess: checkIsPatientActiveIsSuccess,
    },
  ] = useLazyCheckIsPatientActiveQuery();

  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const [patientId, setPatientId] = useState(0);
  const [episodeId, setEpisodeId] = useState(0);
  const [showNewEpisodeWarning, setShowNewEpisodeWarning] = useState(false);
  const [newEpisodeWarningMessage, setNewEpisodeWarningMessage] = useState('');

  const [showNoOpenedCases, setShowNoOpenedCases] = useState(false);
  const [showInactivePatient, setShowInactivePatient] = useState(false);

  // we are using custom request id (instead of the one provided by useLazyQuery)
  // because it is possible that this hook is going to be called from multiple
  // components during single render (search member, +New, open member details
  // +New from the details)
  // By default the query is cached and updates for all consumers (which is
  // a good thing - normally we _DO_ want to update all components after
  // refreshing query results) which is not what we want in this particular case
  // Here we are going to display a dialog with a warning, so we only want
  // to show the dialog for the component that actually called "checkNewEpisode"
  // return method.
  const [activePatientRequestId, setActivePatientRequestId] = useState(0);
  const [newEpisodeRequestId, setNewEpisodeRequestId] = useState(0);
  const [openedEpisodeRequestId, setOpenedEpisodeRequestId] = useState(0);

  const beginNewEpisode = () => {
    navigate(`/Checklist/Problems?patientId=${patientId}&newEpisode=True`);
  };

  const beginClinicalChecklist = () => {
    navigate(
      `/Checklist/Problems?patientId=${patientId}&newEpisode=False&episodeId=0`
    );
  };

  const continueNewEpisode = () => {
    if (roleType === RoleType.CLINICAL) {
      setOpenedEpisodeRequestId(Math.random());
      checkOpenedEpisode({
        episodeId: episodeId,
        patientId: patientId,
      });
    } else if (episodeId > 0) {
      dispatch(setWorkflowStep({ step: 1, name: 'Scenario' }));
      navigate(
        `/Checklist/Problems?patientId=${patientId}&newEpisode=False&episodeId=${episodeId}`
      );
    } else {
      beginNewEpisode();
    }
  };

  useEffect(() => {
    if (!checkNewEpisodeIsFetching && checkNewEpisodeIsSuccess) {
      const result = checkNewEpisodeData?.checkNewEpisode;
      if (result?.status === CheckNewEpisodeStatus.Stop) {
        dispatch(
          showErrorPopup({
            title: 'Stop',
            icon: ICONS.Stop,
            message: result?.message ?? 'Cannot create a new episode.',
          })
        );
      } else if (result?.status === CheckNewEpisodeStatus.Warning) {
        setNewEpisodeWarningMessage(
          result?.message ?? 'Confirm that you want to create a new episode.'
        );
        setShowNewEpisodeWarning(true);
      } else {
        continueNewEpisode();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkNewEpisodeIsFetching,
    checkNewEpisodeIsSuccess,
    newEpisodeRequestId,
  ]);

  useEffect(() => {
    if (!checkIsPatientActiveIsFetching && checkIsPatientActiveIsSuccess) {
      if (checkIsPatientActiveData?.checkIsPatientActive) {
        setNewEpisodeRequestId(Math.random());
        checkNewEpisode({ patientId: patientId });
      } else {
        setShowInactivePatient(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkIsPatientActiveIsFetching,
    checkIsPatientActiveIsSuccess,
    activePatientRequestId,
  ]);

  useEffect(() => {
    if (!checkOpenedEpisodeIsFetching && checkOpenedEpisodeIsSuccess) {
      if (checkOpenedEpisodeData?.checkOpenedEpisode) {
        beginClinicalChecklist();
      } else {
        setShowNoOpenedCases(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkOpenedEpisodeIsFetching,
    checkOpenedEpisodeIsSuccess,
    openedEpisodeRequestId,
  ]);

  const confirmation = (
    <>
      <Confirmation
        open={showNoOpenedCases}
        title="Closed case"
        okButtonText="New Case"
        okEvent={() => {
          beginClinicalChecklist();
        }}
        cancelEvent={() => setShowNoOpenedCases(false)}
      >
        This member does not have an open Case.
        <br />
        To start a new case, click Start New Case below.
        <br />
        To reopen an existing case, click Cancel and go to the Cases tab.
      </Confirmation>
      <Confirmation
        open={showInactivePatient}
        title="Inactive member"
        okButtonText="Continue"
        okEvent={() => {
          checkNewEpisode({ patientId: patientId });
        }}
        cancelEvent={() => setShowInactivePatient(false)}
      >
        This member is inactive. Are you sure you want to continue?
      </Confirmation>
      <Confirmation
        title="Warning"
        icon={ICONS.Exclamation_Point}
        open={showNewEpisodeWarning}
        okEvent={() => {
          setShowNewEpisodeWarning(false);
          continueNewEpisode();
        }}
        cancelEvent={() => setShowNewEpisodeWarning(false)}
      >
        {newEpisodeWarningMessage}
      </Confirmation>
    </>
  );

  return {
    checkNewEpisode: (providedPatientId: number, providedEpisodeId: number) => {
      setPatientId(providedPatientId);
      setEpisodeId(providedEpisodeId);
      setActivePatientRequestId(Math.random());
      checkIsPatientActive({ patientId: providedPatientId });
    },
    checkNewEpisodeIsFetching:
      checkNewEpisodeIsFetching ||
      checkOpenedEpisodeIsFetching ||
      checkIsPatientActiveIsFetching,
    confirmation,
  };
};

export { useNewEpisodeCheck };
