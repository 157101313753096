import moment from 'moment';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import { Typography } from '@mui/material';
import React from 'react';
import { reminderColumn } from '../consts';
import { ActionsCell, DaysCell, ReasonCell, StatusCell } from './index';
import { CellComponentStyle, ILabelCellProps } from 'components/gridFormatters';
import { EpisodeTypeCell } from './EpisodeTypeCell';

export const ReminderLabelCell = (props: ILabelCellProps) => {
  const { value, column, children } = props;
  const cellRenderer = React.useCallback(() => {
    switch (column.name) {
      case reminderColumn.reason:
        return <ReasonCell {...props} />;
      case reminderColumn.days:
        return <DaysCell {...props} />;
      case reminderColumn.status:
        return <StatusCell {...props} />;
      case reminderColumn.owner:
        return (
          <Table.Cell {...props} style={CellComponentStyle}>
            <Typography noWrap title={value.fullName ?? ''}>
              {value.fullName}
            </Typography>
          </Table.Cell>
        );
      case reminderColumn.date:
        return (
          <Table.Cell {...props} onClick={undefined} style={CellComponentStyle}>
            <Typography>{moment(value).format('MM/DD/YYYY')}</Typography>
          </Table.Cell>
        );
      case reminderColumn.actions:
        return <ActionsCell {...props} />;
      case reminderColumn.episode:
        return <EpisodeTypeCell {...props} />;
      case reminderColumn.note:
        return (
          <Table.Cell {...props} style={CellComponentStyle}>
            <Typography title={value} noWrap>
              {value}
            </Typography>
          </Table.Cell>
        );

      default:
        return (
          <Table.Cell {...props} style={CellComponentStyle}>
            <Typography>{value}</Typography>
          </Table.Cell>
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [column.name, props]);

  return (
    <>
      {children}
      {cellRenderer()}
    </>
  );
};
