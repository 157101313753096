import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GeneratePdfQueryVariables = Types.Exact<{
  checklistId: Types.Scalars['Int'];
  schemaId: Types.Scalars['Int'];
}>;

export type GeneratePdfQuery = {
  __typename?: 'EntitiesQuery';
  generatePdf?: {
    __typename?: 'DownloadFileDto';
    mimeType?: string | null;
    content?: string | null;
    fileName?: string | null;
  } | null;
};

export const GeneratePdfDocument = `
    query generatePdf($checklistId: Int!, $schemaId: Int!) {
  generatePdf(model: {checklistId: $checklistId, schemaId: $schemaId}) {
    mimeType
    content
    fileName
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    generatePdf: build.query<GeneratePdfQuery, GeneratePdfQueryVariables>({
      query: (variables) => ({ document: GeneratePdfDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGeneratePdfQuery, useLazyGeneratePdfQuery } = injectedRtkApi;
