import React from 'react';
import { Component } from './Component';
import {
  AttributeType,
  BaseComponent,
  CheckboxRadioButtonComponent,
  ComponentsLine,
} from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';
import styled from 'styled-components';
import { IEntityLookup, ILookup } from 'backend/types/lookup';

const StyledLine = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 45px 14px 0;
  width: 100%;
`;

const StyledComponentContainer = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  &:empty {
    display: none;
  }
`;

export interface ILineProps {
  data: ComponentsLine;
  isItemSelected: boolean;
  index: number;
  bigTableValues: IEntityLookup[];
  storageType: ChecklistStorageType;
  checklistId?: string;
  entityLookups: IEntityLookup[];
  attributeTypes: AttributeType[];
  lookups: ILookup[];
}

const componentsToSkip = ['ActionButtonComponent'];

export const Line = (props: ILineProps) => {
  const { data, isItemSelected } = props;
  const componentsToRender = data.components.filter((c: BaseComponent) => {
    switch (c.componentType) {
      case 'ReminderComponent':
        return true;
      case 'CheckboxRadioButtonComponent':
        // Don't render checkboxradio if it is not selected
        // We need to do this here to prevent rendering the line altogether
        // if we only have unselected checkboxradios
        return (c as CheckboxRadioButtonComponent).selected;
      default:
        return !componentsToSkip.includes(c.componentType) && c.value;
    }
  });

  if (componentsToRender.length === 0) {
    return null;
  }

  return (
    <StyledLine>
      {componentsToRender.map(
        (component: BaseComponent, componentIndex: number) =>
          (!component.displayOnlyWhenSelected || isItemSelected) && (
            <StyledComponentContainer key={componentIndex}>
              <Component component={component} {...props} />
            </StyledComponentContainer>
          )
      )}
    </StyledLine>
  );
};
