import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type UpdatePatientProblemMutationVariables = Types.Exact<{
  problem: Types.PatientProblemDtoInput;
}>;

export type UpdatePatientProblemMutation = {
  __typename?: 'Mutation';
  updatePatientProblem?: boolean | null;
};

export const UpdatePatientProblemDocument = `
    mutation updatePatientProblem($problem: PatientProblemDtoInput!) {
  updatePatientProblem(problem: $problem)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updatePatientProblem: build.mutation<
      UpdatePatientProblemMutation,
      UpdatePatientProblemMutationVariables
    >({
      query: (variables) => ({
        document: UpdatePatientProblemDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdatePatientProblemMutation } = injectedRtkApi;
