import React from 'react';
import { IEditorProps } from 'backend/types/grid/IEditorProps';
import { Select } from 'components/select';
const BoolFilter = (props: IEditorProps) => {
  return (
    <Select
      placeholder={props.column?.title}
      value={props.value ?? ''}
      size="small"
      data-testid="boolean-filter"
      onChange={(event) => props.onChange(event.target.value)}
      items={[
        { id: '1', name: 'Yes' },
        { id: '0', name: 'No' },
      ]}
    />
  );
};

export default BoolFilter;
