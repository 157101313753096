import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from 'consts/styles';
import { Box, Menu, MenuItem, Typography } from '@mui/material';

import styled from 'styled-components';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import { ChecklistStorageType, IWorkflowViewModel } from 'store/actions/types';
import { useGetMozartOutOfTurnStepsQuery } from 'graphql/hooks/getMozartOutOfTurnSteps';
import { useInsertMozartWorkflowOotStepMutation } from 'graphql/hooks/insertMozartWorkflowOotStep';
import { resetChecklist } from 'store/actions/checklistSlice';
import { showErrorPopup } from 'store/errorPopup/errorPopupSlice';
import {
  mozartStartPolling,
  mozartWorkflowPaused,
} from 'store/actions/mozartSlice';
import { calculateOutOfTurnStepsWidth } from 'util/helpers/styleHelpers';
import theme from 'theme';
import { setActiveEpisode } from 'store/patientdetails/patientDetailsSlice';

const styleMenuTriggerSidePadding = 24;
const StyledMenuTrigger = styled.div<{
  $menuOpen: boolean;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  padding-right: 18px;
  ${({ $menuOpen }) =>
    $menuOpen &&
    `background-color: ${COLORS.BLUE10};
     border-radius: 4px;
     color: ${COLORS.SYMPHONY_BLUE};
    `}
  &:hover {
    ${({ $disabled }) => `
      cursor: ${$disabled ? 'default' : 'pointer'};
      background-color: ${$disabled ? null : COLORS.BLUE10};
    `}
    color: ${COLORS.WHITE};
    border-radius: 4px;

    & span {
      color: ${COLORS.WHITE} !important;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)({
  paddingRight: '20px',
  paddingTop: '8px',
  paddingBottom: '8px',
  paddingLeft: '20px',
  '&:hover': {
    backgroundColor: COLORS.GREY10,
  },
  '& .Mui-focusVisible': {
    backgroundColor: 'transparent',
  },
});

const styledTypographyFontSize = '14px';
const styledTypographyFontWeight = 500;
const StyledTypography = styled(Typography)<{
  $textColor: string;
}>(({ $textColor }) => ({
  color: $textColor,
  padding: `12px 4px 12px ${styleMenuTriggerSidePadding}px`,
  fontSize: styledTypographyFontSize,
  fontWeight: styledTypographyFontWeight,
}));

const StyledArrowDropUp = styled(ArrowDropUp)<{ $disabled: boolean }>(
  ({ $disabled }) => ({
    color: $disabled ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE,
  })
);

const StyledArrowDropDown = styled(ArrowDropDown)<{ $disabled: boolean }>(
  ({ $disabled }) => ({
    color: $disabled ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE,
  })
);

const getLastOotStep = (workflow: IWorkflowViewModel | null) => {
  const actions = workflow?.actions;
  if (!actions || !actions.length) {
    return undefined;
  }

  return actions[actions.length - 1];
};

export const MozartOutOfTurnSteps = ({
  isWorkflowFailed,
}: {
  isWorkflowFailed?: boolean;
}) => {
  const dispatch = useDispatch();

  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const mozartWorkflow = useSelector(
    (state: IState) => state.mozart.mozartWorkflow
  );

  const mozartWorkflowInstanceId = useSelector(
    (state: IState) => state.mozart.mozartInstanceId
  );

  const mozartViewOnly = useSelector(
    (state: IState) => state.mozart.mozartViewOnly
  );

  const isWorkflowPaused = useSelector(
    (state: IState) => state.mozart.isWorkflowPaused
  );

  const hasFailedRequests = useSelector(
    (state: IState) => (state.failedRequest.requests ?? []).length > 0
  );

  const { data, refetch, isUninitialized } = useGetMozartOutOfTurnStepsQuery(
    { id: mozartWorkflowInstanceId },
    { skip: !mozartWorkflowInstanceId }
  );

  const lastStep = getLastOotStep(mozartWorkflow);
  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastStep?.id]);

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    open: boolean
  ) => {
    if (hasFailedRequests) {
      setMenuOpen(false);
      return;
    }

    setAnchorElement(open ? event.currentTarget : null);
    setMenuOpen(open);
  };

  const handleUnpause = () => {
    if (isWorkflowPaused) {
      dispatch(mozartWorkflowPaused(false));
    }
  };

  const [insertMozartWorkflowOotStep] =
    useInsertMozartWorkflowOotStepMutation();
  const handleInsertOotStep = (id: string, workflowStepId: string) => {
    dispatch(setActiveEpisode(0));

    setMenuOpen(false);
    insertMozartWorkflowOotStep({
      id,
      workflowInstanceOotStepId: workflowStepId,
    }).then(
      (result) => {
        dispatch(resetChecklist(ChecklistStorageType.MOZART));
        if ('error' in result) {
          dispatch(
            showErrorPopup({
              message: result.error?.message ?? 'Error executing other action.',
            })
          );
        } else if ('data' in result) {
          if (result.data?.insertMozartWorkflowOOTStep?.result) {
            handleUnpause();
            dispatch(mozartStartPolling());
          } else {
            dispatch(
              showErrorPopup({
                message:
                  result.data?.insertMozartWorkflowOOTStep?.message ??
                  'Error executing other action.',
              })
            );
          }
        }
      },
      (error) => {
        const errorMessage = typeof error !== 'string' ? error.message : error;
        showErrorPopup({ message: errorMessage });
      }
    );
  };

  const width = useMemo(
    () =>
      calculateOutOfTurnStepsWidth(
        data?.getMozartOutOfTurnSteps
          ?.filter(
            (item) =>
              item?.workflowStepName !== undefined &&
              item?.workflowStepName !== null
          )
          .map((item) => item.workflowStepName as string) ?? [],
        `${styledTypographyFontWeight} ${styledTypographyFontSize} ${theme.typography.fontFamily}`
      ),
    [data?.getMozartOutOfTurnSteps]
  );

  if (!data?.getMozartOutOfTurnSteps?.length) {
    return null;
  }

  return (
    <Box>
      <StyledMenuTrigger
        onClick={(e) => handleToggle(e, true)}
        $menuOpen={menuOpen}
        $disabled={hasFailedRequests}
      >
        <StyledTypography
          variant="h6"
          $textColor={hasFailedRequests ? COLORS.GREY60 : COLORS.SYMPHONY_BLUE}
        >
          Other Actions
        </StyledTypography>
        {menuOpen ? (
          <StyledArrowDropUp $disabled={hasFailedRequests} />
        ) : (
          <StyledArrowDropDown $disabled={hasFailedRequests} />
        )}
      </StyledMenuTrigger>
      <Menu
        anchorEl={anchorElement}
        autoFocus={false}
        keepMounted={true}
        open={menuOpen}
        onClose={(e) => handleToggle(e as React.MouseEvent<HTMLElement>, false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            left: '0',
            width: `${width + styleMenuTriggerSidePadding * 2}px`,
            marginTop: '5px',
            borderRadius: '4px',
            border: '1px solid #CECECE',
            boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
          },
        }}
      >
        {data?.getMozartOutOfTurnSteps?.map((item) => (
          <StyledMenuItem
            key={item?.workflowInstanceAvailableOOTStepsId}
            value={item?.workflowStepId}
            disabled={mozartViewOnly || isWorkflowFailed}
            onClick={() =>
              handleInsertOotStep(item.workflowInstance, item.workflowStepId)
            }
          >
            {item?.workflowStepName}
          </StyledMenuItem>
        ))}
      </Menu>
    </Box>
  );
};
