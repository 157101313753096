import React, { useEffect, useMemo, useState } from 'react';
import {
  COMPONENT_STYLE_CUSTOM,
  COMPONENT_STYLE_MEDIUM,
  COMPONENT_STYLE_SMALL,
  COMPONENT_STYLE_WIDE,
} from 'components/constants';
import { IconButton, OutlinedInputProps, SxProps } from '@mui/material';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import { IValidationResult, validateTextField } from 'util/validationUtils';
import { IComponentProps } from '../types';
import {
  Maybe,
  TextAttributeType,
  UserFreeTextComponent,
} from 'graphql/graphqlTypes';
import { setChecklistComponentValidation } from 'store/actions/checklistSlice';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from 'store';
import LinkFieldsIcon from 'components/linkFieldsIcon/LinkFieldsIcon';
import { useChecklistValidation } from '../../Items/Orderable';
import styled from 'styled-components';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { usePrevious } from 'hooks';
import ChecklistTextInput from './checklistTextInput';

export interface IUserFreeTextProps
  extends IComponentProps<UserFreeTextComponent> {
  autoSave: boolean;
  lastComponentInRow: boolean;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
  textAttribute?: TextAttributeType | null;
}

interface IStyledTextWrapperProps {
  width: string;
}

const StyledWrapper = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
`;

const StyledTextWrapper = styled.div<IStyledTextWrapperProps>`
  width: ${(props) => props.width};
`;

const getComponentWidth = (
  style: Maybe<string> | undefined,
  width: number,
  maxLength: number,
  lastComponentInRow: boolean
) => {
  if (style === COMPONENT_STYLE_CUSTOM) {
    return `${width}px`;
  }
  if (style === COMPONENT_STYLE_SMALL) {
    return '100px';
  }
  if (style === COMPONENT_STYLE_MEDIUM && !lastComponentInRow) {
    return '170px';
  }
  if (style === COMPONENT_STYLE_WIDE) {
    return '35px';
  }
  if (maxLength > 0) {
    return `${maxLength}em`;
  }
  return '100%';
};

const renderAsIncon = (
  isDisplayAsIcon: boolean,
  value: Maybe<string> | undefined
) => isDisplayAsIcon && (value || '') === '';

const getErrorMessage = (message: string | null | undefined) =>
  message ?? 'Fill in required field';

const hasError = (
  hasError: boolean,
  isActionSelected: boolean,
  isReadOnly: boolean,
  isUpdateActionValueRequestFailed: boolean
) =>
  (hasError && isActionSelected && !isReadOnly) ||
  isUpdateActionValueRequestFailed;

const UserFreeText = (props: IUserFreeTextProps) => {
  const {
    component,
    handleChecklistInputChange,
    categoryId,
    isActionSelected,
    storageType,
    autoSave,
    lastComponentInRow,
    isReadOnly,
    isUpdateActionValueRequestFailed,
    textAttribute,
  } = props;

  const [isDisplayAsIcon, changeIconToTextField] = useState<boolean>(
    component.displayAsIcon
  );

  const [finishedRendering, setFinishedRendering] = useState(false);

  const validateInputValue = (valueToValidate: string): IValidationResult =>
    validateTextField(valueToValidate, {
      type: component.type || '',
      maxLen: component.maxLen,
      minLen: component.minLen,
      required: component.required,
      maxVal: component.maxVal,
      minVal: component.minVal,
      textAttribute: textAttribute,
    });

  const reduxValue = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );
  const getInitialValue = () => {
    if (reduxValue) {
      return reduxValue;
    }
    if (component.value) {
      return component.value;
    }
    return '';
  };

  useEffect(() => {
    setFinishedRendering(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [inputValue, setInputValue] = useState<string>(getInitialValue());
  const previousInputValue = usePrevious(inputValue);
  useEffect(() => {
    if (!finishedRendering) {
      return;
    }
    if (previousInputValue === reduxValue) {
      return;
    }
    if (!previousInputValue && !reduxValue) {
      return;
    }

    if (reduxValue !== undefined) {
      handleValidation(reduxValue!);
      setInputValue(reduxValue!);
    }

    if (reduxValue !== undefined) {
      handleChecklistInputChange(reduxValue ?? '', component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxValue]);

  useEffect(() => {
    handleValidation(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  const [error, setError] = useState<IValidationResult>(
    validateInputValue(inputValue)
  );

  const width = getComponentWidth(
    component.style,
    component.width,
    component.maxLen,
    lastComponentInRow
  );
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const inputProps: Partial<OutlinedInputProps['inputProps']> = {};
  if (component.readonly) {
    inputProps.readOnly = true;
  }
  if (component.maxLen > 0) {
    inputProps.maxLength = component.maxLen;
  }
  if (component.minLen > 0) {
    inputProps.minLength = component.minLen;
  }

  useChecklistValidation(
    error.hasError && isActionSelected,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID,
    categoryId,
    getErrorMessage(error.message),
    component.userDefinedId || component.componentType,
    storageType
  );
  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setInputValue(event.target.value);
    setError(validateInputValue(event.target.value));
  };

  const getError = hasError(
    error.hasError,
    isActionSelected,
    isReadOnly,
    isUpdateActionValueRequestFailed
  );
  const handleValidation = (valueToValidate: string) => {
    const result = validateInputValue(valueToValidate);
    setError(result);
    dispatch(
      setChecklistComponentValidation({
        storageType,
        error: {
          uuid: component.uniqueID,
          error: result.message ?? '',
          fieldName: component.userDefinedId ?? component.componentType,
          categoryId: categoryId,
          isValid: !(result.message && isActionSelected),
        },
      })
    );
  };

  const onBlur = () => {
    handleValidation(inputValue);
    if (reduxValue !== inputValue) {
      handleChecklistInputChange(inputValue, component);
    }
  };

  const inputSx: SxProps | undefined = useMemo(() => {
    return component.color ? { color: component.color } : undefined;
  }, [component.color]);

  const multiline =
    component.multiline || component.style === COMPONENT_STYLE_MEDIUM || false;
  const disabled = component.readonly || isReadOnly;
  return (
    <StyledWrapper>
      {renderAsIncon(isDisplayAsIcon, component.value) ? (
        <CustomTooltip title="Add Comment">
          <IconButton
            onClick={() => changeIconToTextField(false)}
            data-testid={`IconButton_${component.uniqueID}`}
            style={{ padding: '5px' }}
            size="large"
          >
            <Icon
              icon={ICONS.AddComment}
              size={22}
              color={COLORS.SYMPHONY_BLUE}
            />
          </IconButton>
        </CustomTooltip>
      ) : (
        <StyledTextWrapper width={width}>
          <ChecklistTextInput
            disabled={disabled}
            userDefinedId={component.userDefinedId}
            dataTestid={`TextField_${component.uniqueID}`}
            value={inputValue}
            multiline={multiline}
            onChange={onInputChange}
            onBlur={onBlur}
            error={getError}
            sx={inputSx}
            inputProps={inputProps}
            key={component.uniqueID}
            useAutocomplete={component.useAutocomplete}
          />
        </StyledTextWrapper>
      )}
      {(component.mirrorGroup || component.oneWaySrc) && (
        <LinkFieldsIcon linkMessage={component.linkMessage as string} />
      )}
    </StyledWrapper>
  );
};

export default UserFreeText;
