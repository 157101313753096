import * as Types from '../graphqlTypes.js';

import { api } from 'graphql/baseApi';
export type GetOpenedClinicalEpisodeQueryVariables = Types.Exact<{
  patientId: Types.Scalars['Int'];
}>;

export type GetOpenedClinicalEpisodeQuery = {
  __typename?: 'EntitiesQuery';
  getOpenedClinicalEpisode?: {
    __typename?: 'Episode';
    id: number;
    name?: string | null;
    episodeNumber?: string | null;
    createdOn?: any | null;
    updatedOn?: any | null;
    referralDate?: any | null;
    status?: { __typename?: 'LookupValue'; name?: string | null } | null;
    program?: { __typename?: 'LookupValue'; name?: string | null } | null;
    entityAttributes?: Array<{
      __typename?: 'EntityAttribute';
      name?: string | null;
      value?: string | null;
    } | null> | null;
  } | null;
  getPatientRisk?: { __typename?: 'PatientRisk'; acuityLevel: string } | null;
};

export const GetOpenedClinicalEpisodeDocument = `
    query getOpenedClinicalEpisode($patientId: Int!) {
  getOpenedClinicalEpisode(patientId: $patientId) {
    id
    name
    episodeNumber
    createdOn
    updatedOn
    status {
      name
    }
    program {
      name
    }
    referralDate
    entityAttributes {
      name
      value
    }
  }
  getPatientRisk(patientId: $patientId) {
    acuityLevel
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOpenedClinicalEpisode: build.query<
      GetOpenedClinicalEpisodeQuery,
      GetOpenedClinicalEpisodeQueryVariables
    >({
      query: (variables) => ({
        document: GetOpenedClinicalEpisodeDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetOpenedClinicalEpisodeQuery,
  useLazyGetOpenedClinicalEpisodeQuery,
} = injectedRtkApi;
