import 'regenerator-runtime/runtime';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { Grid } from '@devexpress/dx-react-grid-material-ui';
import { IState } from 'store';
import TooltipProvider from 'components/home/grid/providers/TooltipProvider';
import styled from 'styled-components';
import { useGetEpisodeWorkflowMdStepCountQuery } from 'graphql/hooks/getEpisodeWorkflowMDStepCount';
import {
  dashBoardDataColumnsExtensionStyles,
  dashBoardGridColumns,
  mdDashBoardGridColumns,
} from './DashBoardGridHelper';
import Loader from 'components/loader';
import { EpisodeWorkflowMdStepCountViewModel } from 'graphql/graphqlTypes';
import { IUserPreferences } from 'backend/types/userPreferences';
import DashValueProvider from './DashValueProvider';
import TotalValueProvider from './TotalValueProvider';

const StyledGrid = styled(Grid)({
  marginTop: '20px',
  marginBottom: '80px',
});

const MdDashBoardGrid = ({
  userPreferences,
}: {
  userPreferences: IUserPreferences;
}) => {
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const userId = useSelector((state: IState) => state.user.currentUser.id);
  const mdData = useGetEpisodeWorkflowMdStepCountQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      defaultAuthorization: userPreferences.standardAuthorization,
    },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  if (mdData.isFetching) {
    return <Loader active={true} />;
  }
  return (
    <>
      <StyledGrid
        data-testid="md-dashboard-grid"
        rows={
          mdData?.data
            ?.getEpisodeWorkflowMdStepCount as EpisodeWorkflowMdStepCountViewModel[]
        }
        columns={mdDashBoardGridColumns}
        getRowId={(row) => row.careSiteId}
      >
        <TooltipProvider for={mdDashBoardGridColumns.map((i) => i.name)} />
        <DashValueProvider for={dashBoardGridColumns.map((i) => i.name)} />
        <SymphonyTable columnExtensions={dashBoardDataColumnsExtensionStyles} />
        <SymphonyTableHeaderRow />
        <TotalValueProvider for={mdDashBoardGridColumns.map((i) => i.name)} />
      </StyledGrid>
    </>
  );
};

export default MdDashBoardGrid;
