import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import { IState } from 'store';
import { Box } from '@mui/material';
import { Select } from 'components/select';
import RefreshButton from 'components/refreshbutton';
import styled from 'styled-components';
import { createSelector } from '@reduxjs/toolkit';
import { useGetUserPreferencesQuery } from 'graphql/hooks/getUserPreferences';
import { IUserPreferences } from 'backend/types/userPreferences';
import { useUpdateUserPreferencesMutation } from 'graphql/hooks/updateUserPreferences';
import { dashBoardType } from './DashBoardGridHelper';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

const StyledRefreshWrapper = styled.div`
  margin-left: -10px;
`;

export const homeModelSelector = createSelector(
  (state: IState) => state.home.homeModel,
  (model) => model
);

export const homeGridData = createSelector(
  (state: IState) => state.home.data,
  (model) => model
);

const DashboardGridHeaderPanel = ({
  setSelectedDashBoardType,
}: {
  setSelectedDashBoardType: (selectedDashBoardType: string) => void;
}) => {
  const { data } = useGetUserPreferencesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(
    data?.getUserPreferences as IUserPreferences
  );
  const [currentValue, setCurrentValue] = useState('');
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();

  useEffect(() => {
    if (data) {
      setUserPreferences(data?.getUserPreferences as IUserPreferences);
      setCurrentValue(userPreferences?.defaultDashboard ?? '');
      setSelectedDashBoardType(userPreferences?.defaultDashboard ?? '');
    }
  }, [data, setSelectedDashBoardType, userPreferences?.defaultDashboard]);
  const handleSelectChange = (
    event: React.ChangeEvent<{ name: string | undefined; value: string }>
  ) => {
    const currentValue = event.target.value;
    setCurrentValue(currentValue);
    setSelectedDashBoardType(currentValue);
    const currentPreference = {
      firstName: userPreferences?.firstName,
      lastName: userPreferences?.lastName,
      prefix: userPreferences?.prefix,
      suffix: userPreferences?.suffix,
      credentials: userPreferences?.credentials,
      phone: userPreferences?.phone,
      email: userPreferences?.email,
      standardAuthorization: userPreferences?.standardAuthorization,
      expeditedAuthorization: userPreferences?.expeditedAuthorization,
      defaultDashboard: currentValue,
    };

    updateUserPreferences({
      data: currentPreference,
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap="16px"
        mb="16px"
        data-testid="dashboard-bar"
        data-cy="dashboard-bar"
      >
        <StyledRefreshWrapper>Dashboard: </StyledRefreshWrapper>
        <Box display="flex">
          <Select
            data-testid="dashboard-filter-select"
            value={currentValue}
            onChange={handleSelectChange}
            items={[
              {
                id: dashBoardType.umRnTatDashboard,
                name: dashBoardType.umRnTatDashboard,
              },
              {
                id: dashBoardType.uMMdDashboard,
                name: dashBoardType.uMMdDashboard,
              },
            ]}
            size="small"
            fullWidth
            required
            id="dashboard-filter-select"
          />
        </Box>

        <StyledDivider orientation="vertical" />
        <StyledRefreshWrapper>
          <RefreshButton
            onClick={() => {
              location.reload();
            }}
          />
        </StyledRefreshWrapper>
      </Box>
    </>
  );
};

export default DashboardGridHeaderPanel;
