import React from 'react';
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import { COLORS } from 'consts/styles';
import { FormControl, Typography } from '@mui/material';
import { Select } from 'components/select';
import styled from 'styled-components';

interface IDivProps {
  value: string;
}

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;

const Bubble = styled.div<IDivProps>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 8px;
  ${({ value }) =>
    `background-color: ${value === 'true' ? COLORS.GREEN100 : COLORS.GREY60}`}
`;

const SelectInput = ({ value }: { value: string }) => {
  return (
    <FlexBox>
      <Bubble value={value} />
      <Typography variant="body1">
        {value === 'true' ? 'Active' : 'Inactive'}
      </Typography>
    </FlexBox>
  );
};

const IsActiveFormatter = (props: DataTypeProvider.ValueFormatterProps) => {
  return (
    <FormControl disabled={props.value.isReadOnly}>
      <Select
        value={props.value.isActive ? 'true' : 'false'}
        disabled={props.value.isReadonly}
        required
        size="small"
        onChange={(e) =>
          props.value.changeInActive(props.value.id, e?.target.value === 'true')
        }
        items={[
          { id: 'true', name: 'Active' },
          { id: 'false', name: 'Inactive' },
        ]}
        renderValue={(value) => <SelectInput value={value} />}
      />
    </FormControl>
  );
};

const IsActiveProvider = (props: DataTypeProviderProps) => (
  <DataTypeProvider formatterComponent={IsActiveFormatter} {...props} />
);

export default IsActiveProvider;
