export const FAVORITE_SCENARIOS_ON_PROBLEMS_VIEW =
  'favorite_scenarios_on_problems_view';
export const PATIENT_LIST_EPISODE_STATE = 'patient_list_episode_state';
export const PATIENT_LIST_SHOW_ACTIVE = 'patient_list_show_active';
export const PATIENT_LIST_SIZE = 'patient_list_size';

export const COMPONENT_STYLE_SMALL = 'order-sentence-small';
export const COMPONENT_STYLE_MEDIUM = 'order-sentence-medium';
export const COMPONENT_STYLE_WIDE = 'order-sentence-wide';
export const COMPONENT_STYLE_MAXIMUM = 'order-sentence-maximum';
export const COMPONENT_STYLE_CUSTOM = 'order-sentence-custom';
export const TIMELINE_TAB_SELECTED_STATES = 'selected_states_on_timeline_tab';

export const EPISODES_TAB_SELECTED_SUBTAB = 'selected_sub_tab_on_episodes_tab';
export const ORG_DET_TAB_SELECTED_SUBTAB = 'selected_sub_tab_on_org_det_tab';
export const CASES_TAB_SELECTED_SUBTAB = 'selected_sub_tab_on_cases_tab';

export const EPISODES_TAB_STATE_FILTER =
  'episode_list_state_filter_on_episodes_tab';
export const ORG_DET_TAB_STATE_FILTER =
  'episode_list_state_filter_on_org_det_tab';
export const CASES_TAB_STATE_FILTER = 'episode_list_state_filter_on_cases_tab';

export const PROVIDER_SEARCH_FAVORITE_PROVIDERS =
  'provider_search_favorite_providers';
export const PROVIDER_SEARCH_RECENT_SELECTED_PROVIDERS =
  'provider_search_recent_selected_providers';
export const PATIENT_CARE_PLAN_FILTER_STATE = 'patient_care_plan_filter_state';

export const MOMENT_DATE_FORMAT = 'MM/DD/YYYY';
export const MOMENT_ISO_FORMAT = 'YYYY-MM-DD';
export const MOMENT_DATETIME_ISO_FORMAT = 'YYYY-MM-DD HH:mm';
export const MOMENT_DATETIME_FORMAT = 'MM/DD/YYYY HH:mm';
export const MOZART_MOMENT_DATETIME_FORMAT = 'MM/dd/yy HH:mm';
export const MOMENT_DATETIME_SECONDS_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DATETIME_FORMAT = 'MM/dd/yyyy HH:mm';
export const DATETIME_SECONDS_FORMAT = 'MM/dd/yyyy HH:mm:ss';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const MOMENT_TIME_FORMAT = 'HH:mm';
export const MOMENT_TIME_SECONDS_FORMAT = 'HH:mm:ss';
export const DATE_ISO_FORMAT = 'yyyy-MM-dd';

export enum TimelineType {
  Checklist = 'Checklist',
  Reminder = 'Reminder',
}

export const ADDRESS_COMPONENT_CODE = 'address';
export const INDIVIDUAL_COMPONENT_CODE = 'i';
export const OPEN_CLOSE_STATUS = 'Open/Closed';
export const HEALTH_SERVICE = 'Health Service';
export const DUPLICATE_CHECK = 'Duplicate Check';
export const PRIORAUTH_CHECK = 'Prior Auth Check';

export const MAXIMUM_FILTER_CHIP_LENGTH = 4;
