import React, { useMemo } from 'react';
import { capitalCase } from 'capital-case';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { Box, Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import { IActionButtonProps } from './ActionButton';
import { ActionButtonComponent } from 'graphql/graphqlTypes';
import { uniq } from 'lodash';
import { isSameAsSourceActionButton } from './actionButton.helpers';

export interface ISameAsSourceActionButtonsContainerProps
  extends IActionButtonProps {
  onProviderSelect: (
    selectedProviderId: number,
    selectedProviderAddressId: number
  ) => void;
  isReadOnly: boolean;
}

export const SameAsSourceActionButtonsContainer = ({
  component: { uniqueID },
  storageType,
  item,
  onProviderSelect,
  isReadOnly,
}: ISameAsSourceActionButtonsContainerProps) => {
  const providersSet = useSelector(
    (state: IState) => state.checklist.documentsState[storageType].providers
  );

  const onSourceSelect = (attributeForSource: string) => {
    if (
      providersSet[attributeForSource] &&
      Number(providersSet[attributeForSource].id) > 0
    ) {
      onProviderSelect(
        Number(providersSet[attributeForSource].id),
        Number(providersSet[attributeForSource].providerAddressId ?? 0)
      );
    }
  };
  const sameAsButtonSources = useMemo(
    () =>
      uniq(
        item.lines
          .flatMap((x) => x.components)
          .filter(
            (x) =>
              x.componentType === 'ActionButtonComponent' &&
              isSameAsSourceActionButton(x as ActionButtonComponent)
          )
          .map(
            (x) => (x as ActionButtonComponent).attributeForSource
          ) as string[]
      ),
    [item.lines]
  );
  return sameAsButtonSources.length ? (
    <Box display="flex" marginTop="10px">
      <Box marginTop="6px">
        <Typography variant="body2" style={{ color: COLORS.GREY100 }}>
          Same as:
        </Typography>
      </Box>
      {sameAsButtonSources.map((s) => (
        <Button
          key={`${uniqueID}-same-as-${s}`}
          data-testid={`SameAs${s}`}
          color="primary"
          variant="text"
          onClick={() => onSourceSelect(s)}
          disabled={isReadOnly}
        >
          {capitalCase(s)}
        </Button>
      ))}
    </Box>
  ) : null;
};
