import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { CellComponentStyle } from 'components/gridFormatters';

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const MainColumns = ['main', 'cell', 'home'];
export const EditComponent = (props: TableEditColumn.CellProps) => {
  if (MainColumns.includes(props.row.type.toLowerCase())) {
    const subChildren = [] as ReactElement[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    React.Children.forEach(props.children, (child: any) => {
      if (child && child.props && child.props.id !== 'delete') {
        subChildren.push(child);
      }
    });

    return (
      <TableEditColumn.Cell {...props} style={CellComponentStyle}>
        <StyledDiv>{subChildren}</StyledDiv>
      </TableEditColumn.Cell>
    );
  }
  return (
    <TableEditColumn.Cell {...props} style={CellComponentStyle}>
      <StyledDiv>{props.children}</StyledDiv>
    </TableEditColumn.Cell>
  );
};
