import React from 'react';

import { FixedTextComponent } from 'graphql/graphqlTypes';
import { Typography } from '@mui/material';

export interface IFixedTextProps {
  component: FixedTextComponent;
}

const FixedText = ({ component }: IFixedTextProps) => {
  return <Typography variant="body1">{component.value}</Typography>;
};

export default FixedText;
