import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';
import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import {
  LocalizationProvider,
  DatePicker,
  DateValidationError,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { CustomTooltip } from 'components/tooltip/CustomTooltip';
import { TextFieldProps } from '@mui/material';

export const StyledKeyboardDatePicker = styled(DatePicker)`
  font-size: 6px;
  border-color: ${COLORS.ORANGE100};
  color: ${COLORS.ORANGE100};
  border-radius: 10px;
  width: 130px;
  .MuiOutlinedInput-adornedEnd {
    padding-right: 8px;
  }
`;

interface IDatePickerProps {
  open?: boolean;
  value: Date | null;
  error?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  testId?: string;
  id?: string;
  name?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  required?: boolean;
  onBlur?: () => void;
  onChange: (date: Date | null, inputValue?: string) => void;
  onShortcutSelected?: (shortcutName?: string) => void;
  color?: string;
}

const pickerIcon = () => {
  return (
    <CustomTooltip title="Calendar">
      <div>
        <Icon
          icon={ICONS.Date_Pick}
          size={14}
          color={COLORS.GREY100}
          style={{ marginTop: '-3px' }}
        />
      </div>
    </CustomTooltip>
  );
};
const shortcutsItems: PickersShortcutsItem<Dayjs | null>[] = [
  {
    label: 'Last Month',
    getValue: () => {
      return dayjs().add(-1, 'month');
    },
  },
  {
    label: 'Yesterday',
    getValue: () => {
      return dayjs().add(-1, 'day');
    },
  },
  {
    label: 'Today',
    getValue: () => {
      return dayjs();
    },
  },
  {
    label: 'Tomorrow',
    getValue: () => {
      return dayjs().add(1, 'day');
    },
  },
  {
    label: 'Next Week',
    getValue: () => {
      return dayjs().add(1, 'week');
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      return dayjs().add(1, 'month');
    },
  },
  {
    label: 'Next Year',
    getValue: () => {
      return dayjs().add(1, 'year');
    },
  },
];
const DateComponent = forwardRef<HTMLInputElement | null, IDatePickerProps>(
  (props, ref) => {
    const textfieldProps: TextFieldProps & { 'data-testid'?: string } = {
      id: props.id,
      required: props.required,
      error: props.error,
      onBlur: props.onBlur,
      inputProps: {
        placeholder: props.placeholder,
        sx: props.color ? { color: props.color } : undefined,
      },
      value: props.value ? dayjs(props.value) : null,
      style: { backgroundColor: COLORS.WHITE },
      ref: ref,
      'data-testid': props.testId,
    };
    const [isOpen, setIsOpen] = useState(props.open);
    const handleChange = (
      newValue: unknown,
      ctx: PickerChangeHandlerContext<DateValidationError>
    ) => {
      if (props.onShortcutSelected) {
        props.onShortcutSelected(ctx.shortcut?.label);
      }

      const dateValue = newValue ? dayjs(newValue as string).toDate() : null;
      props.onChange(dateValue, newValue as string);
    };
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StyledKeyboardDatePicker
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          value={props.value ? dayjs(props.value) : null}
          onChange={handleChange}
          minDate={props.minDate ? dayjs(props.minDate) : null}
          maxDate={props.maxDate ? dayjs(props.maxDate) : null}
          disabled={props.readOnly}
          slotProps={{
            textField: textfieldProps,
            shortcuts: {
              items: shortcutsItems,
              sx: {
                [`.MuiChip-label`]: {
                  px: 1.5,
                },
              },
              dense: true,
            },
          }}
          slots={{
            openPickerIcon: pickerIcon,
          }}
        />
      </LocalizationProvider>
    );
  }
);
DateComponent.defaultProps = { open: false };
DateComponent.displayName = 'DatePicker';

export default DateComponent;
