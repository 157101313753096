import React from 'react';
import { Box, styled } from '@mui/material';
import {
  AssociatedEpisodeActionValue,
  ChecklistItemAssociatedEpisodes,
} from 'graphql/graphqlTypes';
import AssociatedEpisodeTable from 'components/actions/sections/SectionBody/Items/AssociatedEpisode/AssociatedEpisodeTable';
import { associatedEpisodeAttributeHelper } from 'components/actions/sections/SectionBody/Items/AssociatedEpisode/AssociatedEpisode.helper';
import { IState } from 'store';
import { useSelector } from 'react-redux';
import { ChecklistStorageType } from 'store/actions/types';

const StyledBox = styled(Box)({
  padding: '14px 64px 0px 64px',
});

export interface IAssociatedEpisodeReviewProps {
  item: ChecklistItemAssociatedEpisodes;
}

const AssociatedEpisodeReview = ({ item }: IAssociatedEpisodeReviewProps) => {
  const associatedEpisodeAttributes = item.options.associatedEpisodeAttributes;
  const patientId = Number(
    useSelector(
      (state: IState) =>
        state.checklist.documentsState[ChecklistStorageType.CHECKLIST].patientId
    ) ?? 0
  );
  const actionValue = item?.actionValue ?? [];

  return actionValue.length > 0 ? (
    <StyledBox id={`uuid-${item.uid}`}>
      <AssociatedEpisodeTable
        episodeAttributes={
          associatedEpisodeAttributes.length == 0
            ? associatedEpisodeAttributeHelper
            : associatedEpisodeAttributes
        }
        actionValues={actionValue as AssociatedEpisodeActionValue[]}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        handleChange={() => {}}
        episodes={[]}
        patientId={patientId}
        isReadOnly={true}
      />
    </StyledBox>
  ) : null;
};
export default AssociatedEpisodeReview;
