import { claimColumns } from './claimColumns';
import { PatientClinicHospital } from 'graphql/graphqlTypes';

export const claimGridColumns = [
  {
    name: claimColumns.claimId,
    title: 'Claim ID',
  },
  {
    name: claimColumns.claimDate,
    title: 'Claim Date',
  },
  {
    name: claimColumns.diagnosis,
    title: 'Diagnosis',
    getCellValue: (row: PatientClinicHospital) =>
      row?.diagnosis?.shortDescription,
  },
  {
    name: claimColumns.performingProvider,
    title: 'Performing Provider',
  },
  {
    name: claimColumns.procedure,
    title: 'Procedure',
    getCellValue: (row: PatientClinicHospital) =>
      row?.medProcedure?.shortDescription,
  },
];
