import React, { useCallback, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {
  IconButton,
  InputAdornment,
  PopperProps,
  TextField,
} from '@mui/material';
import { COLORS } from 'consts/styles';
import { Episode } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import Icon, { ICONS } from 'components/icon';
import { EpisodeOption } from './EpisodeOption';
import { EpisodePopper } from './EpisodePopper';

const StyledAutocomplete = styled(Autocomplete)({
  width: '440px',
  height: '32px',
  marginRight: '20px',
  '& .MuiAutocomplete-inputRoot': {
    padding: '3.5px 12px!important',
  },
  backgroundColor: COLORS.WHITE,
});

const StyledIconButton = styled(IconButton)`
  padding: 4px;
`;

export interface IEpisodeSearchProps {
  episodes: Episode[];
  searchTerm: string;
  isReadOnly: boolean;
  isLoading: boolean;
  onSelect: (value: Episode) => void;
  onSearch: (term: string) => void;
  onViewAllClick: () => void;
  onCantFindClick: () => void;
}

const EpisodeSearch = ({
  episodes,
  searchTerm,
  isReadOnly,
  isLoading,
  onSelect,
  onSearch,
  onViewAllClick,
  onCantFindClick,
}: IEpisodeSearchProps) => {
  const [options, setOptions] = useState<Episode[]>([] as Episode[]);
  const [opened, setOpened] = useState<boolean | undefined>();
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);

  useEffect(() => {
    if (isLoading) {
      setOptions([]);
    }
    if (episodes && !isLoading) {
      setOptions(episodes);
    }
  }, [isLoading, episodes]);

  const onSelectionChange = (value: Episode) => {
    if (value) {
      setSelectedEpisode(null);
      onSelect(value);
    }
    setOpened(false);
  };

  const getOptionLabel = (option: Episode): string => {
    return option.episodeNumber ?? '';
  };

  const AutoCompletePopup = useCallback(
    (props: PopperProps) => {
      return (
        <EpisodePopper
          {...props}
          totalCount={options.length}
          onViewAllClick={onViewAllClick}
          onCantFindClick={onCantFindClick}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options.length]
  );

  return (
    <>
      <StyledAutocomplete
        data-testid="episode-search-autocomplete"
        getOptionLabel={(option) => getOptionLabel(option as Episode)}
        onChange={(_, value) => {
          onSelectionChange(value as Episode);
        }}
        options={options}
        autoComplete
        openOnFocus={opened}
        size="small"
        loading={isLoading}
        includeInputInList
        value={selectedEpisode}
        inputValue={searchTerm}
        disabled={isReadOnly}
        onInputChange={(event, value: string) => {
          if (
            event != null &&
            (event.type === 'change' || event.type === 'click')
          ) {
            onSearch(value);
          }
          setOpened(true);
        }}
        renderOption={(params, option) => (
          <li {...params} style={{ padding: 0 }}>
            <EpisodeOption value={option as Episode} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            type="text"
            {...params}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {searchTerm.length === 0 ? null : (
                    <InputAdornment
                      style={{ position: 'absolute', right: '31px' }}
                      position="end"
                    >
                      <StyledIconButton onClick={() => onSearch('')}>
                        <Icon size={19} icon={ICONS.Remove} />
                      </StyledIconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
        PopperComponent={AutoCompletePopup}
      />
    </>
  );
};

export default EpisodeSearch;
