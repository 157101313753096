import {
  IProvider,
  IRecentProvider,
} from 'components/providerSearchDialog/types';
import { setComponentValuesByUserDefinedIds } from 'store/actions/checklistSlice';
import { GetProviderSearchModelQuery } from 'graphql/hooks/getProviderSearchModel';
import { SearchProvidersQuery } from 'graphql/hooks/searchProviders';
import {
  BaseComponent,
  CheckboxRadioButtonComponent,
  ChecklistItemOrderable,
  KeyValuePairOfStringAndString,
  ProviderSearchResultRow,
} from 'graphql/graphqlTypes';
import { Dispatch } from 'redux';
import { ChecklistStorageType } from 'store/actions/types';

export const providerType = (authRole: string) => {
  return authRole === 'facility' ? 'facility' : 'provider';
};

export const getProviderRows = (
  useDefaultProviders: boolean,
  modelQuery: GetProviderSearchModelQuery | undefined,
  searchQuery: SearchProvidersQuery | undefined,
  authRole: string
) => {
  const rows = (
    useDefaultProviders
      ? modelQuery?.getProviderSearchDialogModel?.rows ?? []
      : searchQuery?.searchProvider?.rows ?? []
  ) as ProviderSearchResultRow[];
  const typeName = providerType(authRole);
  return rows.filter(
    (row: ProviderSearchResultRow) =>
      row.providerType === undefined ||
      (row.providerType?.name?.length ?? 0) === 0 ||
      row?.providerType?.name?.toLowerCase() === typeName
  );
};

export const mapProviders = (
  rows: ProviderSearchResultRow[],
  favorites: string[],
  recent: IRecentProvider[]
) => {
  return rows.map((row: ProviderSearchResultRow) => {
    const providerId = Number(row.id ?? 0);
    return {
      ...row,
      id: Number(row.id ?? 0),
      isFavorite: favorites.includes(row.providerAddressUiId ?? ''),
      isRecent: recent.some(
        ({ id }: IRecentProvider) => Number(id) === providerId
      ),
    } as IProvider;
  });
};

export const updateLinkedComponents = (
  provAttributes: KeyValuePairOfStringAndString[],
  dispatch: Dispatch,
  storageType: ChecklistStorageType,
  actionId: string | null | undefined
) => {
  const values = (provAttributes.map((attrib) => ({
    userDefinedId: attrib.key,
    value: attrib.value,
  })) ?? []) as { userDefinedId: string; value: string | null }[];

  dispatch(
    setComponentValuesByUserDefinedIds({
      storageType,
      values,
      actionId,
    })
  );
};

export const updateLinkedRadioButtons = (
  provAttributes: KeyValuePairOfStringAndString[],
  item: ChecklistItemOrderable,
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent
  ) => void
) => {
  const parValue = provAttributes.find((x) => x.key === '_PAR');
  if (parValue) {
    const parComp = item.lines
      .flatMap((x) => x.components)
      .find((x) =>
        x.userDefinedId?.includes('_PAR')
      ) as CheckboxRadioButtonComponent;
    if (parComp) {
      handleChecklistInputChange(true, parComp);
    }
  } else {
    const nonParValue = provAttributes.find((x) => x.key === '_NonPAR');
    if (nonParValue) {
      const nonParComp = item.lines
        .flatMap((x) => x.components)
        .find((x) =>
          x.userDefinedId?.includes('_NonPAR')
        ) as CheckboxRadioButtonComponent;
      if (nonParComp) {
        handleChecklistInputChange(true, nonParComp);
      }
    }
  }
};

export const updateProviderAuthRoleHiddenCheckbox = (
  provAttributes: KeyValuePairOfStringAndString[],
  item: ChecklistItemOrderable,
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string
  ) => void
) => {
  const isDummyId = 'Provider.IsDummy';
  const isDummy =
    provAttributes.find((attrib) => attrib?.key === isDummyId)?.value ===
    'true';
  const checkboxRadioButtonComponents = item.lines
    .flatMap((x) => x.components)
    .filter(
      (x) => x.componentType === 'CheckboxRadioButtonComponent'
    ) as CheckboxRadioButtonComponent[];
  const hiddenCheckboxAuthRoleComponent = checkboxRadioButtonComponents.find(
    (x) => x.hidden && x.userDefinedId === isDummyId
  );
  if (hiddenCheckboxAuthRoleComponent) {
    handleChecklistInputChange(
      isDummy,
      hiddenCheckboxAuthRoleComponent,
      `${hiddenCheckboxAuthRoleComponent.id}-sel`
    );
  }
};
