import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { LetterActions, LetterFilter, LetterGroup, LetterTable } from '.';
import { ILetter, ILetterGroup, IMessageState } from '../types';
import { FaxParameters, Maybe } from 'graphql/graphqlTypes';
import Icon, { ICONS } from 'components/icon';
import { COLORS } from 'consts/styles';

const ListBoxContent = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  maxHeight: '79vh',
});

const FlexBox = styled('div')`
  display: flex;
  align-items: center;
`;

const StyledWrapper = styled('div')`
  padding-left: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 21px;
  background-color: ${COLORS.YELLOW20};
  border-radius: 10px;
`;

const IconWrapper = styled(Icon)`
  margin-top: -3px;
`;

const StyledTypography = styled(Typography)`
  margin-left: 4px;
`;

const text = "Please note that member's language is NOT English.";

type LetterListProps = {
  setLetters: (letters: ILetter[]) => void;
  setLoading: (loading: boolean) => void;
  setMessage: (message: IMessageState) => void;
  setView: (view: 'letters' | 'fax') => void;
  setPreview: (preview: string | null) => void;
  setIsSelectedView: (isSelectedView: boolean) => void;
  letters: ILetter[];
  letterGroups: ILetterGroup[];
  patientId: number;
  episodeId: number;
  isPrintShopActive: boolean;
  printIframe: () => void;
  fetchPreview: () => void;
  isSelectedView: boolean;
  faxParameter: Maybe<FaxParameters> | undefined;
  patientLanguage: Maybe<string> | undefined;
};

export const LetterList = (props: LetterListProps) => {
  const [letterGroupId, setLetterGroupId] = useState('');
  const [letterGroupValue, setLetterGroupValue] = useState('');
  const [letterSearch, setLetterSearch] = useState('');
  const isPatientLanguageNotEnglish =
    props.patientLanguage !== 'English' && props.patientLanguage !== undefined;

  return (
    <ListBoxContent>
      {isPatientLanguageNotEnglish && (
        <StyledWrapper>
          <FlexBox>
            <IconWrapper icon={ICONS.Exclamation_Point} size={15} />
            <StyledTypography variant="h4">{text}</StyledTypography>
          </FlexBox>
        </StyledWrapper>
      )}
      <LetterFilter
        {...props}
        setLetterSearch={setLetterSearch}
        letterGroupId={letterGroupId}
        letterGroupValue={letterGroupValue}
      />
      <LetterGroup
        {...props}
        setLetterGroupId={setLetterGroupId}
        setLetterGroupValue={setLetterGroupValue}
        letterGroupId={letterGroupId}
        letterGroupValue={letterGroupValue}
        letterSearch={letterSearch}
      />
      <LetterTable
        {...props}
        letterGroupId={letterGroupId}
        letterGroupValue={letterGroupValue}
        letterSearch={letterSearch}
      />
      <LetterActions {...props} />
    </ListBoxContent>
  );
};
