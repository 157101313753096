import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
  Grid as TableGrid,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import { IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import { SearchClaimResult } from 'graphql/graphqlTypes';
import { COLORS } from 'consts/styles';
import Loader from 'components/loader';
import SearchBar from 'components/searchbar/SearchBar';
import {
  SelectedRow,
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import {
  formatPaidAmount,
  getMedicationCodeWithDescription,
  getProviderDetails,
} from 'components/actions/sections/SectionBody/Items/AssociatedClaim/AssociatedClaim.helper';
import SimpleTextProvider from 'components/home/grid/providers/SimpleTextProvider';
import EpisodesDateProvider from 'components/home/grid/providers/EpisodesDateProvider';

export interface IClaimSearchDialogModel {
  isFetching: boolean;
  claims: SearchClaimResult[];
  onSelect: (id: number) => void;
  setSearchTerm: (term: string) => void;
}

const ClaimSearchDialogBody = (props: IClaimSearchDialogModel) => {
  const { claims, isFetching, onSelect, setSearchTerm } = props;
  const [selectedId, setSelectedId] = useState<number>(0);
  const columns = [
    { name: 'claimId', title: 'Claim ID' },
    { name: 'claimDate', title: 'Claim Date' },
    { name: 'serviceFrom', title: 'Service From' },
    { name: 'serviceTo', title: 'Service To' },
    {
      name: 'diagnosis',
      title: 'Diagnosis',
      getCellValue: (row: SearchClaimResult) =>
        getMedicationCodeWithDescription(row?.diagnosisCode),
    },
    {
      name: 'medProcedure',
      title: 'Procedure',
      getCellValue: (row: SearchClaimResult) =>
        getMedicationCodeWithDescription(row?.procedureCode),
    },
    {
      name: 'provider',
      title: 'Provider Name',
      getCellValue: (row: SearchClaimResult) =>
        getProviderDetails(row?.provider),
    },
    {
      name: 'paidAmount',
      title: 'Paid Amount',
      getCellValue: (row: SearchClaimResult) =>
        formatPaidAmount(row?.paidAmount),
    },
  ];

  const columnsExtension = [
    { columnName: 'claimId' },
    { columnName: 'claimDate' },
    { columnName: 'serviceFrom' },
    { columnName: 'serviceTo' },
    { columnName: 'diagnosis' },
    { columnName: 'medProcedure' },
    { columnName: 'provider' },
    { columnName: 'paidAmount' },
  ];

  const onSelectionChange = (values: Array<number | string>) => {
    if (values.length > 1) {
      const selectedClaimId = Number(values[1]);
      setSelectedId(selectedClaimId);
      onSelect(selectedClaimId);
    }
  };

  return (
    <div data-cy="claim-search-dialog-body">
      <Box style={{ backgroundColor: COLORS.PALE_GREY, height: '100%' }}>
        <div
          style={{
            height: '100%',
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            justifyContent: 'space-between',
          }}
        >
          <Paper
            elevation={0}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={5}>
                <SearchBar
                  placeholder="Search by Claim ID (at least 3 chars)"
                  onSubmit={setSearchTerm}
                  style={{ width: '300px' }}
                  containerStyle={{ marginBottom: '20px' }}
                />
              </Grid>
            </Grid>
            <Box>
              <TableGrid
                rows={claims}
                columns={columns}
                getRowId={(row: SearchClaimResult) => row.id}
              >
                <SelectionState
                  selection={[selectedId]}
                  defaultSelection={[]}
                  onSelectionChange={onSelectionChange}
                />
                <IntegratedSelection />
                <EpisodesDateProvider
                  for={['claimDate', 'serviceFrom', 'serviceTo']}
                />
                <SimpleTextProvider
                  for={['claimId', 'diagnosis', 'medProcedure', 'provider']}
                />
                <SymphonyTable columnExtensions={columnsExtension} />
                <SymphonyTableHeaderRow />
                <TableSelection
                  selectByRowClick
                  highlightRow
                  rowComponent={SelectedRow}
                  showSelectionColumn={false}
                />
              </TableGrid>
            </Box>
          </Paper>
        </div>
      </Box>
      <Loader active={isFetching} />
    </div>
  );
};

export default ClaimSearchDialogBody;
