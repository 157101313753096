import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  addressColumnExtension,
  addressColumns,
  EditAddress,
  LabelAddress,
} from './address';
import ContactsGrid from './contactsGrid';
import { CommunicationNeeds } from './communicationNeeds';
import { useCommitContactsChanges } from './contacts.hooks';
import Loader from 'components/loader';
import {
  EditEmail,
  emailColumnExtension,
  emailColumns,
  LabelEmail,
} from './emails';
import {
  EditPhone,
  LabelPhone,
  phoneColumnExtension,
  phoneColumns,
} from './phone';
import { useDispatch } from 'react-redux';
import { setIsInEditMode } from 'store/patientdetails/patientDetailsSlice';

interface IContactTabProps {
  disabled: boolean;
}

const ContactTab = ({ disabled }: IContactTabProps) => {
  const dispatch = useDispatch();
  const {
    contactData,
    addressCommitChanges,
    emailCommitChanges,
    phoneCommitChanges,
    languageCommitChanges,
  } = useCommitContactsChanges();

  const [editEnabled, setEditEnabled] = useState(true);

  useEffect(() => {
    dispatch(setIsInEditMode({ isInEditMode: !editEnabled }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEnabled]);

  if (!contactData) {
    return <Loader active={true} />;
  }

  return (
    <Grid container>
      <Grid item>
        <ContactsGrid
          title="Addresses"
          itemName="address"
          rows={contactData.addresses}
          columns={addressColumns}
          commitChanges={addressCommitChanges}
          editCell={EditAddress}
          labelCell={(p) =>
            LabelAddress({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={addressColumnExtension}
        />
      </Grid>
      <Grid item>
        <ContactsGrid
          title="Email"
          itemName="email"
          rows={contactData.emails}
          columns={emailColumns}
          commitChanges={emailCommitChanges}
          editCell={EditEmail}
          labelCell={(p) =>
            LabelEmail({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={emailColumnExtension}
        />
      </Grid>
      <Grid item md={7}>
        <ContactsGrid
          title="Phone Numbers"
          itemName="phone number"
          rows={contactData.phones}
          columns={phoneColumns}
          commitChanges={phoneCommitChanges}
          editCell={EditPhone}
          labelCell={(p) =>
            LabelPhone({ ...p, disabled: disabled || !editEnabled })
          }
          getRowId={(row) => row.type}
          editEnabled={!disabled && editEnabled}
          setEditEnabled={setEditEnabled}
          columnExtensions={phoneColumnExtension}
        />
      </Grid>
      <Grid item md={5}>
        <CommunicationNeeds
          disabled={disabled}
          language={contactData.language}
          languageBarriers={contactData.languageBarriers}
          languageCommitChanges={languageCommitChanges}
        />
      </Grid>
    </Grid>
  );
};

export default ContactTab;
