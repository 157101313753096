import React from 'react';
import { Typography } from '@mui/material';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';

interface IStyledEpisodeNumberWrapperProps {
  episodeLabelHasBorder: boolean;
}

const StyledEpisodeNumberWrapper = styled.div<IStyledEpisodeNumberWrapperProps>`
  padding: ${(props) =>
    !props.episodeLabelHasBorder ? '16px 28px' : '16px 0 0 0'};
  margin: ${(props) => (props.episodeLabelHasBorder ? '0px 28px' : 0)};
  border-top: ${(props) =>
    props.episodeLabelHasBorder ? `1px dashed ${COLORS.GREY25}` : 0};
`;

export interface IPatientCardProps {
  episodeNumber?: string | null;
  episodeLabelHasBorder?: boolean;
}

const StyledTypography = styled(Typography)`
  display: inline;
  margin-right: 4px;
`;

const EpisodeId = (props: IPatientCardProps) => {
  const { episodeLabelHasBorder, episodeNumber } = props;

  return (
    <>
      {episodeNumber && (
        <StyledEpisodeNumberWrapper
          episodeLabelHasBorder={episodeLabelHasBorder ?? false}
        >
          <StyledTypography variant="body2">Episode ID:</StyledTypography>
          <Typography display="inline" variant="body1">
            {episodeNumber}
          </Typography>
        </StyledEpisodeNumberWrapper>
      )}
    </>
  );
};

export default EpisodeId;
