import React from 'react';
import { ChecklistItemPriorAuthCodes } from 'graphql/graphqlTypes';
import { Box, Grid } from '@mui/material';
import {
  Grid as TableGrid,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import TableComponent from 'components/actions/sections/SectionBody/Items/MedicationCodes/TableComponent';
import { SymphonyTable } from 'components/gridFormatters';
import PriorAuthRequestedDatesReview from './PriorAuthRequestedDatesReview';
import PriorAuthPolicyProvider from 'components/home/grid/providers/PriorAuthPolicyProvider';
import SimpleTextProvider from 'components/providerSearchDialog/components/SimpleTextProvider';
import styled from 'styled-components';
import PriorAuthMessageProvider from 'components/home/grid/providers/PriorAuthMessageProvider';
import {
  getCodeLabel,
  getColumns,
  getDescriptionLabel,
} from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/PriorAuthcodes.helpers';
import PriorAuthReqIconProvider from 'components/home/grid/providers/PriorAuthReqIconProvider';
import PriorAuthHeaderCellComponent from 'components/actions/sections/SectionBody/Items/PriorAuthCodes/priorAuthHeaderCellComponent';
import { CellComponentPadded } from 'components/gridFormatters/CellComponentPadded';

export interface IChecklistItemPriorAuthCodesProps {
  item: ChecklistItemPriorAuthCodes;
}

const PAReviewBox = styled(Box)({
  marginTop: '8px',
  padding: '0 0 0 40px',
});

const PADateBox = styled(Box)({
  padding: '8px 20px 16px 0px',
});

const PriorAuthCodesReview = (props: IChecklistItemPriorAuthCodesProps) => {
  const { item } = props;
  const codeLabel = getCodeLabel(item.options.codeLabel);
  const descriptionLabel = getDescriptionLabel(item.options.descriptionLabel);

  const columns = getColumns(
    codeLabel,
    descriptionLabel,
    item.options.pAShowCheckPolicy
  );

  const columnsExtension = [
    { columnName: 'isPARequired', width: 40 },
    { columnName: 'code', width: 120 },
    { columnName: 'description' },
    { columnName: 'priorAuthRequirement', width: 180 },
    { columnName: 'message' },
  ];

  return (
    <PAReviewBox id={`uuid-${item.uid}`}>
      <PADateBox>
        <PriorAuthRequestedDatesReview state={item?.pAValue} readOnly={false} />
      </PADateBox>
      <Box>
        <Grid container>
          <TableGrid
            columns={columns}
            rows={item.pAValue?.priorAuthCodesActionValues ?? []}
          >
            <PriorAuthReqIconProvider for={['isPARequired']} />
            <SimpleTextProvider for={['code', 'description']} />
            <PriorAuthPolicyProvider for={['priorAuthRequirement']} />
            <PriorAuthMessageProvider for={['primaryMessage']} />
            <SymphonyTable
              columnExtensions={columnsExtension}
              containerComponent={TableComponent}
              cellComponent={CellComponentPadded}
            />
            <TableHeaderRow cellComponent={PriorAuthHeaderCellComponent} />
          </TableGrid>
        </Grid>
      </Box>
    </PAReviewBox>
  );
};

export default PriorAuthCodesReview;
