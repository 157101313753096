import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import styled from 'styled-components';
import { COLORS } from 'consts/styles';
import {
  MozartProgressState,
  MozartWorkflowUiModel,
} from 'graphql/graphqlTypes';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';

const StyledGreyTypography = styled(Typography)`
  color: ${COLORS.GREY100};
  display: flex;
  align-items: center;
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 16px;
  margin-top: -16px !important;

  :before {
    display: none;
  }

  .MuiAccordion-root.Mui-expanded:last-child {
    margin-bottom: 4px;
  }

  .MuiAccordion-root.Mui-expanded:first-child {
    margin-top: -16px;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
  }
`;
const StyledSpan = styled.span`
  padding-left: 12px;
`;
const StyledWrapper = styled.div`
  padding-top: 8px;
`;

interface IWorkflowProps {
  messages: MozartWorkflowUiModel[];
}

export const WorkflowRouting = ({ messages }: IWorkflowProps) => {
  const [panelExpanded, setPanelExpanded] = React.useState(false);

  // We need to have limited client side filtering
  // to handle OOT steps:
  // Inserting OOT step makes current action "skipped"
  // thus it becomes routing action.
  // We cannot filter this message from backend because
  // otherwise we would not hide/complete the action/checklist
  // This means that we must send "skipped" action from backend
  // (from getMozartActionWorkflowData at least) and do
  // the privilege based hiding here.
  // This is not a vulnerability because the user was
  // already filling in the checklist thus he knows what
  // was the previous step that we are skipping.
  if (!hasUserPrivilege(Privilege.ShowMozartRoutingSteps)) {
    return null;
  }

  const filteredMessages = messages.filter(
    (x) => x.state !== MozartProgressState.Started
  );
  if (filteredMessages.length === 0) {
    return null;
  }

  return (
    <StyledWrapper>
      <StyledAccordion
        expanded={panelExpanded}
        onChange={() => setPanelExpanded(!panelExpanded)}
      >
        <StyledAccordionSummary aria-controls="mozart routing">
          <StyledGreyTypography variant="body2">
            {panelExpanded ? (
              <ArrowDropUp style={{ color: COLORS.GREY100 }} />
            ) : (
              <ArrowDropDown style={{ color: COLORS.GREY100 }} />
            )}
            <StyledSpan>Mozart Workflow Routing</StyledSpan>
          </StyledGreyTypography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" pl="36px">
            {filteredMessages.map((item, index) => (
              <StyledGreyTypography
                key={index}
                data-testid={`workflow_routing_${item.id}`}
              >
                {item.leftHead} : {item.executionNote}
              </StyledGreyTypography>
            ))}
          </Box>
        </AccordionDetails>
      </StyledAccordion>
    </StyledWrapper>
  );
};
