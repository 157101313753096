import React from 'react';
import Paper from '@mui/material/Paper';
import TabPanel from '@mui/lab/TabPanel';
import BackgroundTab from './tabscontent/backgroundTab/index';
import { IPatientDetailTabModel } from './types';
import TimelineTab from './tabscontent/timelineTab';
import { EpisodesTab } from './tabscontent/episodesTab';
import AttachmentsTab from './tabscontent/attachmentsTab/attachmentTab';
import { COLORS } from 'consts/styles';
import RemindersTab from './tabscontent/remindersTab';
import ConditionsTab from './tabscontent/conditionsTab';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { MedicationsTab } from './tabscontent/medicationsTab';
import CareTeamTab from './tabscontent/careTeamTab/CareTeamTab';
import ClaimsTab from 'components/home/patientdetails/tabscontent/claimsTab/claimsTab';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background-color: ${COLORS.PALE_GREY};
  display: flex;
  flex: 1;
  width: 100%;
`;

const StyledPaper = styled(Paper)({
  border: `1px solid ${COLORS.GREY25}`,
  marginTop: '-1px',
  width: '100%',
  display: 'flex',
});

const StyledPanel = styled(TabPanel)({
  padding: '0 24px',
  width: '100%',
});

const PatientDetailsTabsBody = (props: IPatientDetailTabModel) => {
  const {
    tabs,
    handleUpdatePatientTabValid,
    editingChecklistId,
    patientDetails,
  } = props;
  const backgroundTab = tabs && tabs.find((item) => item.name === 'Background');
  const activeTabName = useSelector(
    (state: IState) => state.patientDetails.activeTabName
  );
  const activeSubTabName = useSelector(
    (state: IState) => state.patientDetails.activeSubTabName
  );

  const isSelectedEpisode = (tabName: string) =>
    props.episodeId && activeTabName === tabName;

  return (
    <StyledWrapper>
      <StyledPaper
        square={true}
        elevation={0}
        data-cy="patient-details-tab-content"
      >
        <StyledPanel
          data-cy="background-tab-content"
          value="Background"
          style={{ padding: 0, paddingTop: '12px', overflow: 'none' }}
        >
          <BackgroundTab
            isExternalDataReadOnly={backgroundTab?.readOnly ?? false}
            selectedSubTab={activeSubTabName}
            handleUpdatePatientTabValid={handleUpdatePatientTabValid}
            patientDetails={patientDetails}
          />
        </StyledPanel>
        <StyledPanel value="Care Plan" data-cy="careplan-tab-content">
          <span>Care Plan Content</span>
        </StyledPanel>
        <StyledPanel value="Care Team" data-cy="careteam-tab-content">
          <CareTeamTab
            patientId={props.patientId}
            handleUpdatePatientTabValid={handleUpdatePatientTabValid}
          />
        </StyledPanel>
        <StyledPanel value="Conditions" data-cy="conditions-tab-content">
          <ConditionsTab
            patientId={props.patientId}
            handleUpdatePatientTabValid={handleUpdatePatientTabValid}
          />
        </StyledPanel>
        <StyledPanel value="Cases" data-cy="cases-tab-content">
          <EpisodesTab
            patientId={props.patientId}
            showAllTypes={false}
            isDirectOpen={!!props.episodeId}
            selectEpisode={
              isSelectedEpisode('Cases') ? props.episodeId : undefined
            }
            editingChecklistId={editingChecklistId}
            showTatColumn={true}
            showCounterColumn={true}
            selectedSubTab={activeSubTabName}
            episodeType="cases"
            style={{ display: 'flex', flex: '1', height: '100%' }}
          />
        </StyledPanel>
        <StyledPanel value="Reminders" data-cy="reminders-tab-content">
          <RemindersTab />
        </StyledPanel>
        <StyledPanel
          data-cy="meds-tab-content"
          value="Meds"
          style={{
            display: activeTabName === 'Meds' ? 'flex' : 'none',
          }}
        >
          <MedicationsTab />
        </StyledPanel>
        <StyledPanel value="Attachments" data-cy="attachments-tab-content">
          <AttachmentsTab />
        </StyledPanel>
        <StyledPanel
          data-cy="episodes-tab-content"
          value="Episodes"
          style={{
            display: activeTabName === 'Episodes' ? 'flex' : 'none',
          }}
        >
          <EpisodesTab
            patientId={props.patientId}
            showAllTypes={false}
            isDirectOpen={!!props.episodeId}
            selectEpisode={
              isSelectedEpisode('Episodes') ? props.episodeId : undefined
            }
            editingChecklistId={editingChecklistId}
            showTatColumn={true}
            showCounterColumn={true}
            episodeType="episodes"
            selectedSubTab={activeSubTabName}
            style={{ display: 'flex', flex: '1', height: '100%' }}
          />
        </StyledPanel>
        <StyledPanel value="Claims" data-cy="claims-tab-content">
          <ClaimsTab />
        </StyledPanel>

        {activeTabName === 'A&G' && (
          <StyledPanel value="A&G" data-cy="appeal-tab-content">
            <EpisodesTab
              patientId={props.patientId}
              showAllTypes={false}
              isDirectOpen={!!props.episodeId}
              selectEpisode={
                isSelectedEpisode('Episodes') ? props.episodeId : undefined
              }
              editingChecklistId={editingChecklistId}
              showTatColumn={true}
              showCounterColumn={true}
              episodeType="appealgriev"
              selectedSubTab={activeSubTabName}
              style={{ display: 'flex', flex: '1', height: '100%' }}
            />
          </StyledPanel>
        )}

        <StyledPanel
          data-cy="orgdet-tab-content"
          value="Org Det"
          style={{
            display: activeTabName === 'Org Det' ? 'flex' : 'none',
          }}
        >
          <EpisodesTab
            patientId={props.patientId}
            showAllTypes={false}
            isDirectOpen={!!props.episodeId}
            selectEpisode={
              isSelectedEpisode('Org Det') ? props.episodeId : undefined
            }
            editingChecklistId={editingChecklistId}
            style={{ display: 'flex', flex: '1', height: '100%' }}
            showTatColumn={true}
            showCounterColumn={true}
            selectedSubTab={activeSubTabName}
            episodeType="orgDet"
          />
        </StyledPanel>
        <StyledPanel value="Timeline" data-cy="timeline-tab-content">
          <TimelineTab patientId={props.patientId} />
        </StyledPanel>
      </StyledPaper>
    </StyledWrapper>
  );
};

PatientDetailsTabsBody.displayName = 'PatientDetailsTabsBody';

export default PatientDetailsTabsBody;
