import { ILetter } from '../types';
import { FaxFormData, PreviewLabel } from './FaxForm';

export const buildFaxRequest = (
  form: FaxFormData | undefined,
  checklistId: number,
  episodeId: number,
  patientId: number,
  letter?: ILetter,
  attachmentId?: number
) => {
  const attachment = letter?.attachment;
  const currentAttachmentId = attachment?.id ?? attachmentId;
  const attachmentIdForReq = currentAttachmentId
    ? Number(currentAttachmentId)
    : undefined;
  const letters = letter
    ? [
        {
          id: letter.id,
          type: letter.type,
          attachmentPathTemp: letter.attachment?.path,
        },
      ]
    : undefined;
  return {
    attachmentId: attachmentIdForReq,
    episodeId: Number(episodeId),
    patientId: Number(patientId),
    checklistId: checklistId,
    isFaxRetry: false,
    isFromAttachment: !!attachmentId,
    letters: letters,
    ...form,
    note: form?.note?.toString(),
    senderFaxNumber: '',
    recipientFaxNumber: form?.recipientFaxNumber ?? '',
    recipientTo: form?.recipientTo ?? '',
    senderFrom: form?.senderFrom ?? '',
    senderRespondTo: form?.senderRespondTo ?? 0,
    Notes: form?.note,
  };
};

export const previewDisplay = (selectedView: boolean) => {
  return selectedView ? PreviewLabel.RefreshPreview : PreviewLabel.Preview;
};
