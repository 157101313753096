import React from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  ChecklistAmendLink,
  ChecklistContinueLink,
  ChecklistDeleteLink,
  ChecklistFaxLink,
  ChecklistPrintLink,
  ChecklistUpdateLink,
  ChecklistViewLink,
  QuickNoteUpdateLink,
} from 'components/actionlink';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { closePatientDetailsDialog } from 'store/patientdetails/patientDetails.helpers';
import { Dispatch } from 'redux';
import { ChecklistOperation } from 'graphql/graphqlTypes';
import { updateShowSuccessMessage } from 'store/patientdetails/patientDetailsSlice';

interface IDocumentActionArgs {
  props: DataTypeProvider.ValueFormatterProps;
  checklistId: number;
  dispatch: Dispatch;
}

const DocumentActionLinks = {
  [ChecklistOperation.View]: ({ checklistId }: IDocumentActionArgs) => (
    <ChecklistViewLink key="ChecklistView" checklistId={checklistId} />
  ),
  [ChecklistOperation.Print]: ({ checklistId }: IDocumentActionArgs) => (
    <ChecklistPrintLink key="ChecklistPrint" checklistId={checklistId} />
  ),
  [ChecklistOperation.Fax]: ({ checklistId }: IDocumentActionArgs) => (
    <ChecklistFaxLink key="ChecklistFax" checklistId={checklistId} />
  ),
  [ChecklistOperation.Update]: ({
    checklistId,
    dispatch,
  }: IDocumentActionArgs) => (
    <ChecklistUpdateLink
      key="ChecklistUpdate"
      checklistId={checklistId}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  [ChecklistOperation.Continue]: ({
    checklistId,
    dispatch,
  }: IDocumentActionArgs) => (
    <ChecklistContinueLink
      key="ChecklistContinue"
      checklistId={checklistId}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  [ChecklistOperation.Delete]: ({
    checklistId,
    props,
    dispatch,
  }: IDocumentActionArgs) => (
    <ChecklistDeleteLink
      key="ChecklistDelete"
      checklistId={checklistId}
      callback={() => {
        props.value.refetch();
      }}
      onSuccess={() => {
        dispatch(updateShowSuccessMessage(true));
      }}
    />
  ),
  [ChecklistOperation.Amend]: ({
    checklistId,
    dispatch,
  }: IDocumentActionArgs) => (
    <ChecklistAmendLink
      key="ChecklistAmend"
      checklistId={checklistId}
      callback={() => {
        closePatientDetailsDialog(dispatch);
      }}
    />
  ),
  [ChecklistOperation.QuickNoteUpdate]: ({
    checklistId,
    props,
  }: IDocumentActionArgs) => (
    <QuickNoteUpdateLink
      key="QuickNoteUpdate"
      quickNoteId={checklistId}
      callback={(shouldRefetchNotes) => {
        if (shouldRefetchNotes) {
          props.value.refetch();
        }
      }}
    />
  ),
};

const DocumentActionsProvider = (
  props: DataTypeProvider.ValueFormatterProps
) => {
  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      style={{ marginLeft: '-8px' }}
      justifyContent="flex-end"
    >
      {props.row.operations.map((op: ChecklistOperation) => (
        <div key={op}>
          {DocumentActionLinks[op]?.call(null, {
            props: props,
            checklistId: Number(props.row?.checklist?.id ?? 0),
            dispatch: dispatch,
          })}
        </div>
      ))}
    </Box>
  );
};

export default DocumentActionsProvider;
