export enum problemsColumnNames {
  name = 'name',
  inCarePlan = 'inCarePlan',
  isActive = 'isActive',
  id = 'id',
}

export const problemsColumnsExtensionStyles = [
  {
    columnName: problemsColumnNames.name,
  },
  {
    columnName: problemsColumnNames.inCarePlan,
    width: '15%',
  },
  {
    columnName: problemsColumnNames.isActive,
    width: '15%',
  },
  {
    columnName: problemsColumnNames.id,
    width: '10%',
  },
];
