import React from 'react';
import EpisodeDetails from './subTabs/EpisodeDetails';
import Documents from './subTabs/Documents';
import Typography from '@mui/material/Typography';
import { TabContext, TabPanel } from '@mui/lab';
import {
  CASES_TAB_SELECTED_SUBTAB,
  EPISODES_TAB_SELECTED_SUBTAB,
  ORG_DET_TAB_SELECTED_SUBTAB,
} from 'components/constants';
import { Box, Divider, IconButton, Tab, Tabs } from '@mui/material';
import { COLORS } from 'consts/styles';
import Icon, { ICONS } from 'components/icon';
import ClockTab from './subTabs/ClockTab';
import RemindersSubTab from './subTabs/RemindersSubTab';
import EpisodeAttachments from 'components/home/attachmentsList/episodeAttachments';
import CarePlan from './subTabs/casesTab/CarePlan';
import { useSetPreferenceMutation } from 'graphql/hooks/setPreference';
import { PatientDetailsSubTabDefinition } from 'graphql/graphqlTypes';
import styled from 'styled-components';
import MozartNextStepButton from 'components/mozartNextStepButton';
import MozartOutOfTurnStepsList from 'components/mozartOutOfTurnStepsList';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { ClockCounterType, EpisodeTypes } from './consts';

interface IMozartWorkflowDetails {
  workflowInstanceId?: string;
  nextStep?: string | null;
  mozartWorkflowCompleted?: boolean;
  mozartActionEnabled?: boolean;
}

export interface ISubTabsProps {
  patientId: number;
  episodeId: number;
  editingChecklistId?: string;
  selectedSubTab: string;
  onChangeSubTab: (activeTab: string) => void;
  closeEpisodeDetails: () => void;
  setRefetch: (refetch: boolean) => void;
  hasTatTab: boolean;
  hasCounterTab: boolean;
  episodeType: EpisodeTypes;
  readonly: boolean;
  oneCaseCanBeOpened: boolean;
  setEpisodeStateChanged: (changed: boolean) => void;
  lastCaseCanBeOpened: boolean;
  subTabDefinitions?: PatientDetailsSubTabDefinition[];
  mozartWorkflowDetails?: IMozartWorkflowDetails;
}

const getTabLabel = (item: string): string => {
  if (item === 'CarePlan') {
    return 'Care Plan';
  }
  return item;
};

const removeInvisibleTabs = (
  tabs: { [index: string]: React.ReactNode },
  subTabDefinitions?: PatientDetailsSubTabDefinition[]
) => {
  if (!subTabDefinitions) {
    return;
  }
  for (const key in tabs) {
    if (
      Object.hasOwn(tabs, key) &&
      !subTabDefinitions.some((x) => x.name === key && x.isVisible)
    ) {
      delete tabs[key];
    }
  }
};

const StyledHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px 24px;
`;

const StyledMozartDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 30px;
`;

const StyledDivider = styled(Divider)`
  margin: 0 16px;
  height: 28px;
`;

const SubTabs = (props: ISubTabsProps) => {
  const {
    selectedSubTab,
    episodeId,
    onChangeSubTab,
    closeEpisodeDetails,
    patientId,
    hasTatTab,
    hasCounterTab,
    episodeType,
    readonly,
    mozartWorkflowDetails,
  } = props;
  const carePlanTab = episodeType === 'cases' && {
    'Care Plan': <CarePlan {...props} />,
  };

  const tatTab = hasTatTab && {
    TAT: <ClockTab {...props} counterType={ClockCounterType.TAT} />,
  };
  const counterTab = hasCounterTab && {
    Counter: <ClockTab {...props} counterType={ClockCounterType.Counter} />,
  };

  const tabs: { [index: string]: React.ReactNode } = {
    Details: <EpisodeDetails {...props} />,
    Documents: <Documents {...props} />,
    Attachments: (
      <EpisodeAttachments
        patientId={patientId}
        episodeId={episodeId}
        readonly={readonly}
      />
    ),
    ...carePlanTab,
    Reminders: <RemindersSubTab episodeId={episodeId} readonly={readonly} />,
    ...tatTab,
    ...counterTab,
  };
  removeInvisibleTabs(tabs, props.subTabDefinitions);

  const subTabs = Object.keys(tabs);
  const [setUserPreference] = useSetPreferenceMutation();
  const title = (episodeType === 'cases' ? 'Case' : 'Episode') + ' details';

  const getTabNameSelectedSubTab = () => {
    switch (episodeType) {
      case 'cases':
        return CASES_TAB_SELECTED_SUBTAB;
      case 'episodes':
        return EPISODES_TAB_SELECTED_SUBTAB;
      case 'orgDet':
        return ORG_DET_TAB_SELECTED_SUBTAB;
      default:
        return EPISODES_TAB_SELECTED_SUBTAB;
    }
  };
  //this is one of the implementation details leaking to FE, if mozart workflow is broken instead of guid we return this 000 value
  const isMozartInstanceIdZeroed =
    mozartWorkflowDetails?.workflowInstanceId !==
    '00000000000000000000000000000000';

  const isInEditMode = useSelector(
    (state: IState) => state.patientDetails.isInEditMode
  );

  return (
    <TabContext value={selectedSubTab}>
      <Box display="flex" flexDirection="column" width="100%" height="100%">
        <StyledHeadingWrapper>
          <Typography variant="subtitle2">{title}</Typography>
          <IconButton
            aria-label="close"
            onClick={closeEpisodeDetails}
            data-cy="close"
            style={{ padding: '4px' }}
            size="large"
          >
            <Icon icon={ICONS.Close} size={24} color={COLORS.GREY100} />
          </IconButton>
        </StyledHeadingWrapper>
        {mozartWorkflowDetails?.workflowInstanceId &&
        isMozartInstanceIdZeroed ? (
          <StyledMozartDetailsContainer>
            <MozartNextStepButton
              buttonText={mozartWorkflowDetails?.nextStep}
              workflowInstanceId={mozartWorkflowDetails.workflowInstanceId}
              mozartActionEnabled={mozartWorkflowDetails.mozartActionEnabled}
              mozartWorkflowCompleted={
                mozartWorkflowDetails.mozartWorkflowCompleted
              }
              normalPadding
              isDisabled={isInEditMode}
            />
            <StyledDivider orientation="vertical" />
            <MozartOutOfTurnStepsList
              workflowInstanceId={mozartWorkflowDetails.workflowInstanceId}
              nextStepName={mozartWorkflowDetails?.nextStep ?? ''}
              displayType="dropdown"
              mozartWorkflowCompleted={
                mozartWorkflowDetails.mozartWorkflowCompleted
              }
              isDisabled={isInEditMode}
            />
          </StyledMozartDetailsContainer>
        ) : null}
        <Tabs
          indicatorColor="primary"
          value={selectedSubTab}
          style={{
            borderBottom: `1px solid ${COLORS.GREY25}`,
            padding: '0 14px',
          }}
          onChange={(_e, value) => {
            onChangeSubTab(value);
            setUserPreference({
              name: getTabNameSelectedSubTab(),
              value: value,
            });
          }}
          selectionFollowsFocus
          aria-label="main grid"
        >
          {subTabs.map((item, index) => (
            <Tab
              key={item}
              tabIndex={index}
              label={getTabLabel(item)}
              value={item}
              disabled={isInEditMode && item !== selectedSubTab}
            />
          ))}
        </Tabs>
        {subTabs.map((item) => (
          <TabPanel
            key={subTabs.indexOf(item)}
            value={item}
            style={{ height: '100%', paddingRight: 0 }}
          >
            {tabs[item]}
          </TabPanel>
        ))}
      </Box>
    </TabContext>
  );
};
export default SubTabs;
