import { BaseComponent, Maybe } from 'graphql/graphqlTypes';
import { ChecklistStorageType } from 'store/actions/types';

export interface IStylesParams {
  width?: string | null;
  color?: string | null;
}

export interface IDropdownItem {
  value: string;
  name: string;
}

export interface IComponentProps<T> {
  isActionSelected: boolean;
  categoryId: string;
  storageType: ChecklistStorageType;
  component: T;
  handleChecklistInputChange: (
    value: string | boolean,
    field: BaseComponent,
    componentId?: string,
    fieldName?: Maybe<string> | undefined
  ) => void;
  sectionItemId?: string;
}

export enum ActionButtonComponentTypes {
  PatientDialog = 'patientDialog',
  EntityLookup = 'entityLookup',
  LaunchInterQual = 'launchInterQual',
  LaunchMcg = 'launchMcg',
  LetterDialog = 'letterDialog',
  EpisodeAttachments = 'episodeAttachment',
  UploadFilesDialog = 'uploadFilesDialog',
}

export enum ActionButtonComponentEntity {
  Provider = 'provider',
}

export enum ActionButtonComponentSource {
  Search = 'Search',
  SameAsFacility = 'facility',
  SameAsRequesting = 'requesting',
  SameAsServicing = 'attending',
  SameAsAdmitting = 'admitting',
}
